import { AnyAction } from 'redux'

export interface IDailyReview {
	[key: string]: number
}
export interface IMonthlyReview {
	totalInMonth: number
	totalByDay: IDailyReview
}
export interface IReviewTargetStats {
	monthlyTarget: number
	approvedThisMonth?: IMonthlyReview
	approvedLastMonth?: IMonthlyReview
	rejectedCountThisMonth?: number
}
export interface IDashboardState {
	reviewTarget: IReviewTargetStats
	loaded: boolean
}

export const SET_REVIEW_TARGET_DASHBOARD_DATA =
	'dashboard/set-review-target-dashboard-data'
export const SET_DASHBOARD_DATA_LOADED = 'dashboard/set-dashboard-data-loaded'

const initialState: IDashboardState = {
	reviewTarget: {
		monthlyTarget: 0,
		approvedThisMonth: {
			totalInMonth: 0,
			totalByDay: {},
		},
		approvedLastMonth: {
			totalInMonth: 0,
			totalByDay: {},
		},
		rejectedCountThisMonth: 0,
	},
	loaded: false,
}

export default function dashboardReducer(
	state = initialState,
	action: AnyAction,
): IDashboardState {
	switch (action.type) {
		case SET_REVIEW_TARGET_DASHBOARD_DATA:
			return {
				...state,
				reviewTarget: {
					monthlyTarget: action.reviewTarget.monthlyTarget || 0,
					approvedThisMonth: action.reviewTarget.approvedThisMonth || {},
					approvedLastMonth: action.reviewTarget.approvedLastMonth || {},
					rejectedCountThisMonth: action.reviewTarget.rejectedCountThisMonth || 0,
				},
			}
		case SET_DASHBOARD_DATA_LOADED:
			return {
				...state,
				loaded: action.loaded,
			}

		default:
			return state
	}
}
