import React, { ReactElement, ReactNode } from 'react'
import useGetProfileEffect from '../hooks/useGetProfileEffect'

interface IProps {
	children: ReactNode
}

export default function RootWrapper(props: IProps): ReactElement {
	useGetProfileEffect()

	return <>{props.children}</>
}
