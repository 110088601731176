import {
	Typography,
	Row,
	Col,
	ImageComponent,
	PrimaryButton,
	Icons,
	ModalSection,
} from '@copyrightagent/basic-components'
import { Button } from '@material-ui/core'
import { dark50 } from 'lib/Colors'
import { buildCollectionImageURL } from 'lib/http/ApiProvider'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyle } from '../style'

const CompareImages = (props: any): ReactElement => {
	const {
		handleClose,
		newFile,
		storedFile,
		openRenameStep,
		chooseExistingFile,
		isPending,
	} = props
	const { t } = useTranslation()
	const classes = useStyle()
	const [imageSrc, setImageSrc] = useState<string>('')
	const [newImageSrc, setNewImageSrc] = useState<any>('')

	useEffect(() => {
		if (storedFile.id) {
			const src = buildCollectionImageURL(storedFile.id, 200)
			setImageSrc(src)
		}
	}, [storedFile])

	useEffect(() => {
		if (newFile) {
			const reader = new FileReader()
			reader.onload = () => {
				setNewImageSrc(reader.result)
			}
			reader.readAsDataURL(newFile)
		}
	}, [newFile])

	const cleanup = () => {
		setNewImageSrc('')
		setImageSrc('')
	}

	const handleCancel = () => {
		cleanup()
		handleClose()
	}

	return (
		<>
			<ModalSection>
				<Row justify="space-around">
					<Col>
						<Typography fontColor={dark50}>
							{t('createCase/newImage')}
						</Typography>
						{newImageSrc ? (
							<img
								src={newImageSrc}
								alt="New image"
								className={classes.imageCompare}
							/>
						) : (
							<Row className={classes.loading}>
								<Icons.SpinnerIcon />
							</Row>
						)}
					</Col>
					<Col>
						<Typography fontColor={dark50}>
							{t('createCase/originalImage')}
						</Typography>
						{imageSrc ? (
							<ImageComponent
								src={imageSrc}
								alt="Original image"
								className={classes.imageCompare}
							/>
						) : (
							<Row className={classes.loading}>
								<Icons.SpinnerIcon />
							</Row>
						)}
					</Col>
				</Row>
			</ModalSection>
			<ModalSection>
				<Row justify="flex-end">
					<Button className={classes.invisibleButton} onClick={handleCancel}>
						<Typography>{t('cancel')}</Typography>
					</Button>
					<Button
						variant="contained"
						className={classes.cancelButton}
						disableRipple
						onClick={() => {
							openRenameStep()
							cleanup()
						}}
					>
						{t('no')}
					</Button>
					<PrimaryButton
						type="button"
						loading={isPending}
						loadingText={t('images/uploading')}
						text={t('yes')}
						onClick={() => {
							chooseExistingFile(storedFile.id)
							cleanup()
						}}
					/>
				</Row>
			</ModalSection>
		</>
	)
}
export default CompareImages
