import { makeStyles } from '@material-ui/core'
import { dark } from 'lib/Colors'

export default makeStyles(
	{
		titleRow: {
			width: '100%',
		},
		title: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 12,
			color: dark,
		},
	},
	{ index: 1 },
)
