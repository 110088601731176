import { makeStyles } from '@material-ui/styles'
import { dark10, dark5 } from 'lib/Colors'

export default makeStyles(
	() => ({
		page: {
			display: 'flex',
			minHeight: '100vh',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 80,
		},
		title: {
			width: 708,
			marginBottom: 36,
		},
		titleText: {
			fontWeight: 600,
		},
		subtitleText: {
			fontSize: 16,
			fontWeight: 600,
		},
		infoBubble: {
			position: 'absolute',
			top: 53,
			right: 60,
		},
		select: {
			width: '100%',
		},
		logo: {
			position: 'absolute',
			left: 24,
			top: 24,
		},
		formTitle: {
			paddingBottom: 10,
		},
		newsLetter: {
			width: 708,
			marginTop: 24,
			display: 'flex',
			alignItems: 'center',
		},
		userInfoSection: {
			maxWidth: 708,
			padding: 24,
			borderRadius: 6,
			border: `1px solid ${dark10}`,
		},
		unionSection: {
			marginTop: 36,
		},
		unionSelect: {
			'& .MuiSelect-select .MuiSelect-selectMenu': {
				display: 'none',
			},
		},
		phone: {
			'& > div': {
				'& > input': {
					width: '100% !important',
					height: '40px !important',
					backgroundColor: `${dark5} !important`,
					border: `1px solid ${dark10} !important`,
				},
			},
		},
	}),
	{ index: 1 },
)
