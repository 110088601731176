import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { DialogProps, RadioGroup } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	InvisibleButton,
	DangerButton,
	Row,
	Col,
	Modal,
	ModalSection,
} from '@copyrightagent/basic-components'
import Comment from 'routes/Private/InReview/CaseActions/Comment'
import { NonViolatingReason, nonViolatingReasons } from 'lib/entities/Case'
import { trackCaseReviewEvent } from 'hooks/useAnalytics'
import Handout from './Reasons/Handout'
import Other from './Reasons/Other'
import SingleSale from './Reasons/SingleSale'
import AddToWhiteListModal from './Reasons/AddToWhiteList'
import useStyle from './style'

interface RejectCaseModalProps extends DialogProps {
	onReject: (nonViolatingReason: NonViolatingReason, comment: string) => void
	onSoftCase: () => void
}

export default function RejectCaseModal({
	open,
	onReject,
	onClose,
}: RejectCaseModalProps): ReactElement {
	const classes = useStyle()
	const { t } = useTranslation()
	const [tooltipText, setTooltipText] = useState('')
	const [comment, setComment] = useState('')
	const [nonViolatingReason, setNonViolatingReason] = useState<
		NonViolatingReason | undefined
	>(undefined)

	const closeModal = () => onClose && onClose({}, 'escapeKeyDown')

	useEffect(() => {
		if (open === true) return
		setNonViolatingReason(undefined)
		setComment('')
	}, [open])

	useMemo(() => {
		if (!nonViolatingReason)
			return setTooltipText(t('caseActions/pleaseChoose'))
		if (nonViolatingReason === nonViolatingReasons.other && !comment)
			return setTooltipText(t('caseActions/pleaseComment'))
		setTooltipText('')
	}, [nonViolatingReason])

	const onSubmit = async () => {
		if (nonViolatingReason) onReject(nonViolatingReason, comment)
		closeModal()
	}

	const onSetNonViolatingReason = (reason: NonViolatingReason) => {
		trackCaseReviewEvent(`Case marked as ${reason}`)
		setNonViolatingReason(reason)
	}

	return (
		<Modal
			title={t('caseActions/reasonForRejecting')}
			titleText={t('caseActions/reasonForRejectingDetails')}
			size="large"
			open={open}
			onClose={closeModal}
			disableScrollLock
		>
			<ModalSection>
				<RadioGroup value={nonViolatingReason}>
					<Row justify="space-between">
						<Col align="stretch" className={classes.col}>
							<Handout onUpdateNonViolationReason={onSetNonViolatingReason} />
							<AddToWhiteListModal
								onUpdateNonViolationReason={onSetNonViolatingReason}
								onOpenModal={() => trackCaseReviewEvent('Clicked on whitelist')}
							/>
						</Col>
						<Col align="stretch" className={classes.col}>
							<SingleSale
								onUpdateNonViolationReason={onSetNonViolatingReason}
							/>
							<Other onUpdateNonViolationReason={onSetNonViolatingReason} />
						</Col>
					</Row>
				</RadioGroup>
			</ModalSection>
			<ModalSection>
				<Row justify="space-between">
					<Comment
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						placeholder={t('caseActions/commentButtonTextShort')}
					/>
					<InvisibleButton
						className={classes.invisibleButton}
						text={t('caseActions/reasonForRejectingCancelButton')}
						onClick={() => closeModal()}
					/>
					<DangerButton
						disabled={
							!nonViolatingReason ||
							(nonViolatingReason === nonViolatingReasons.other && !comment)
						}
						onClick={onSubmit}
						text={t('caseActions/reasonForRejectingConfirmButton')}
						tooltipText={tooltipText}
					/>
				</Row>
			</ModalSection>
		</Modal>
	)
}
