import React, { ReactElement } from 'react'
import {
	Col,
	PageContainer,
	Typography,
	PrimaryButton,
} from '@copyrightagent/basic-components'
import Image from 'material-ui-image'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'routes/CustomBrowserRouter'
import Navigation from 'routes/Private/HomeLayout/Navigation'
import useStyle, { notFoundImageProps } from './style'
import { RouteUrls } from '../../RouteUrls'

const NotFoundPage = (): ReactElement => {
	const { t } = useTranslation()
	const classes = useStyle()
	const router = useRouter()
	const toFrontpage = () => router.history.push(RouteUrls.dashboard)

	return (
		<>
			<Navigation />
			<PageContainer className={classes.page}>
				<Col align="center" style={{ flex: 1 }}>
					<Image {...notFoundImageProps} />
					<Typography
						variant="subtitle1"
						className={classes.title}
						fontWeight={600}
					>
						{t('pageNotFound/title')}
					</Typography>
					<Typography className={classes.pageNotExistsText}>
						{t('pageNotFound/subTitle')}
					</Typography>
					<PrimaryButton
						text={t('pageNotFound/backButtonText')}
						onClick={toFrontpage}
					/>
				</Col>
			</PageContainer>
		</>
	)
}

export default NotFoundPage
