import { makeStyles } from '@material-ui/core'
import { blue, dark, dark10, dark5, dark75, purple } from 'lib/Colors'

export const useStyles = makeStyles(
	() => ({
		missingDomainContainer: {
			height: 216,
			overflow: 'auto',
			'& > :not(:first-child)': {
				marginTop: 8,
			},
		},
		missingCaseDomain: {
			height: 48,
			padding: '7px 8px 7px 16px',
			border: `1px solid ${dark10}`,
			borderRadius: 6,
			fontSize: 14,
			fontWeight: 600,
			lineHeight: '20px',
			display: 'flex',
		},
		numOfCasesForDomain: {
			width: 32,
			height: 32,
			background: dark5,
			marginRight: 4,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 6,
		},
		toggleAccordionButton: {
			width: 32,
			height: 32,
		},
		toggleAccordionIcon: {
			width: 20,
			height: 20,
		},
		caseRowContainer: {
			'& > *': {
				marginTop: 4,
			},
		},
		caseRow: {
			borderRadius: 6,
			height: 48,
			padding: 8,
			background: dark5,
			display: 'flex',
			alignItems: 'center',
			fontSize: 14,
			fontWeight: 600,
			lineHeight: '20px',
			cursor: 'pointer',
			'& > :not(:first-child)': {
				marginLeft: 8,
			},
		},
		caseRowImage: {
			width: 32,
			height: 32,
			borderRadius: 6,
			objectFit: 'cover',
		},
		caseRowText: {
			fontWeight: 600,
			fontSize: 14,
			lineHeight: '20px',
		},
		caseRowCaseType: {
			width: 20,
			height: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 6,
		},
		flex: {
			display: 'flex',
		},
		colorDark75: {
			color: dark75,
		},
		colorDark: {
			color: dark,
		},
		colorWhite: {
			color: 'white',
		},
		bgBlue: {
			backgroundColor: blue,
		},
		bgPurple: {
			backgroundColor: purple,
		},
		doNotValidateBtn: {
			marginRight: 8,
		},
		checkbox: {
			flexGrow: 1,
			display: 'flex',
			justifyContent: 'flex-end',
		},
	}),
	{ index: 1 },
)
