import React, { useMemo, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Row, PrimaryButton } from '@copyrightagent/basic-components'
import { getAuth, signOut } from 'firebase/auth'

import useSetPageTitle from 'hooks/useSetPageTitle'
import { useRouter } from 'routes/CustomBrowserRouter'
import { LOG_OUT } from 'store/auth'
import trackEvent from 'hooks/useAnalytics'
import { RouteUrls } from 'routes/RouteUrls'
import useStyle from './style'
import UserDetails from './UserDetails'
import PageWithNavigation from '../HomeLayout/PageWithNavigation'

function Settings(): ReactElement {
	const { t } = useTranslation()
	const classes = useStyle()
	const dispatch = useDispatch()
	const router = useRouter()
	const auth = getAuth()

	useSetPageTitle(t('settings/pageTitle'))

	const renderLogout = useMemo(() => {
		function handleLogout() {
			trackEvent('Log out', {
				timeOfLogout: new Date(),
			})
			dispatch({ type: LOG_OUT })
			signOut(auth)
			router.history.push(RouteUrls.login)
		}
		return (
			<Row className={classes.paper} align="flex-start" justify="space-between">
				<PrimaryButton
					type="button"
					text={t('settings/logOut')}
					onClick={handleLogout}
					className={classes.logOut}
				/>
			</Row>
		)
	}, [classes, router, t, dispatch])

	return (
		<PageWithNavigation title={t('settings')}>
			<UserDetails />
			{renderLogout}
		</PageWithNavigation>
	)
}

export default Settings
