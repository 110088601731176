import React, { ReactElement, useState } from 'react'
import { FormControlLabel, Radio, Typography } from '@material-ui/core'
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import clsx from 'clsx'
import { useRadioButtonStyle } from './style'
import { dark50 } from 'lib/Colors'
import { InfoIconWithTooltip } from '@copyrightagent/basic-components'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

type IProps = Omit<FormControlLabelProps, 'control'>

export interface DefaultRadiobutton extends IProps {
	toolTipText?: string
}

export default function RadioButton(props: DefaultRadiobutton): ReactElement {
	const { id, value, label, toolTipText, ...other } = props
	const [selected, setSelected] = useState<boolean>(false)
	const classes = useRadioButtonStyle()
	const radioRef = (radio: HTMLInputElement) => {
		if (radio === null) return
		setSelected(radio.checked)
	}
	return (
		<FormControlLabel
			value={value}
			{...other}
			classes={{
				root: clsx(classes.labelRoot, selected ? classes.labelSelected : null),
			}}
			control={
				<Radio
					disableRipple
					color="primary"
					classes={{
						root: classes.radioRoot,
					}}
					inputRef={radioRef}
					icon={<label htmlFor={id} />}
					checkedIcon={<label htmlFor={id} />}
				/>
			}
			label={
				<div className={classes.labelContainer}>
					<Typography className={classes.label} variant="body2">
						{label}
					</Typography>
					{toolTipText && (
						<div className={classes.labelFloat}>
							<InfoIconWithTooltip iconColor={dark50} content={toolTipText} />
						</div>
					)}
				</div>
			}
		/>
	)
}
