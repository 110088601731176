import { makeStyles } from '@material-ui/core'

export default makeStyles(
	{
		col: {
			width: 276,
			alignSelf: 'flex-start',
		},
		invisibleButton: {
			margin: '0 8px',
			minWidth: 69,
		},
	},
	{ index: 1 },
)
