export interface IClient {
	client_id: string
	name: string
}

export function createClient(props: Partial<IClient> = {}): IClient {
	return {
		client_id: props.client_id || '',
		name: props.name || '',
	}
}
