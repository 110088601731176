import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@material-ui/core'
import {
	Row,
	Typography,
	PrimaryButton,
	InvisibleButton,
	Modal,
	ModalSection,
} from '@copyrightagent/basic-components'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getListUrlBasedOnListType } from 'routes/RouteUrls'
import { EListType } from 'lib/entities/Case'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import useStyle from './style'

interface PutOnHoldProps {
	showOnHoldModal: boolean
	onCloseAndGoToNextCase: () => void
	onCloseAndGoToOnHoldCases: () => void
	onCloseAndDoNothing: () => void
	setShowOnHoldModal: (value: boolean) => void
}

export default function PutOnHoldModal({
	showOnHoldModal: holdDialogOpened,
	onCloseAndGoToNextCase,
	onCloseAndGoToOnHoldCases,
	onCloseAndDoNothing,
	setShowOnHoldModal,
}: PutOnHoldProps): ReactElement {
	const { onHold } = useCaseStatistics()

	const classes = useStyle()
	const { t } = useTranslation()

	const router = useRouter()

	const handleCloseModalAndDoNothing = () => {
		onCloseAndDoNothing()
		setShowOnHoldModal(false)
	}

	const handleGoToNextCase = () => {
		onCloseAndGoToNextCase()
		setShowOnHoldModal(false)
	}

	const handleGoToHoldCases = () => {
		onCloseAndGoToOnHoldCases()
		setShowOnHoldModal(false)
		router.history.push(
			getListUrlBasedOnListType({
				listType: EListType.onHold,
				resetFilter: true,
			}),
		)
	}
	return (
		<>
			<Modal
				open={holdDialogOpened}
				onClose={handleCloseModalAndDoNothing}
				title={t('putOnHold/dialogTitle')}
				titleText={t('putOnHold/dialogContent')}
				size="small"
			>
				<ModalSection>
					<Row className={classes.dialogActionRow} justify={'space-between'}>
						<Link onClick={handleGoToHoldCases} className={classes.link}>
							<Typography className={classes.goToOnHoldButton}>
								{`${t('putOnHold/goToHold')} (${onHold.count})`}
							</Typography>
						</Link>
						<div>
							<InvisibleButton
								text={t('putOnHold/close')}
								onClick={handleCloseModalAndDoNothing}
							/>
							<PrimaryButton
								type="button"
								loading={false}
								text={t('putOnHold/next')}
								loadingText={t('putOnHold/next')}
								onClick={handleGoToNextCase}
								className={classes.buttonNextReview}
							/>
						</div>
					</Row>
				</ModalSection>
			</Modal>
		</>
	)
}
