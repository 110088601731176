import { makeStyles } from '@material-ui/styles'
import { dark, dark10 } from 'lib/Colors'

export const useStyle = makeStyles(
	() => ({
		footer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			position: 'fixed',
			left: 0,
			bottom: 0,
			width: '100%',
			height: 80,
			backgroundColor: 'white',
			borderTop: `1px solid ${dark10}`,
		},
		button: {
			marginRight: 24,
		},
		secondaryButton: {
			marginRight: 24,
			boxShadow: 'none !important',
		},
	}),
	{ index: 1 },
)

export const useToolTipStyle = makeStyles(
	() => ({
		arrow: {
			color: 'white',
		},
		tooltip: {
			fontSize: 14,
			fontWeight: 600,
			borderRadius: 6,
			border: `1px solid ${dark10}`,
			boxShadow: '5px 5px 10px rgba(41, 50, 65, 0.25)',
			color: dark,
			backgroundColor: 'white',
			paddingTop: 28,
			paddingBottom: 28,
			paddingLeft: 20,
			paddingRight: 20,
		},
	}),
	{ index: 1 },
)
