import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { dark50 } from 'lib/Colors'

export default makeStyles(
	(theme: Theme) => ({
		infiniteScrollWrap: {
			overflow: 'visible !important',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			flexBasis: '100%',
			flex: 1,
		},
		loadMoreWrap: {
			display: 'flex',
			justifyContent: 'center',
		},
		tableHeadRow: {
			display: 'flex',
			flexDirection: 'row',
			padding: theme.spacing(2.5, 0),
			flex: 1,
		},
		tableHeadCellFirst: {
			flex: 1,
			minWidth: 350,
			paddingLeft: theme.spacing(2.5),
		},
		tableHeadText: {
			color: '#878787',
		},
		noCasesUploaded: {
			width: '100%',
			marginTop: '30vh',
			'& h5': {
				fontWeight: 600,
			},
			'& p': {
				color: dark50,
				margin: '20px auto',
			},
		},
		spinner: {
			margin: 'auto',
			marginTop: '30vh',
		},
	}),
	{ index: 1 },
)
