import { makeStyles } from '@material-ui/styles'
import { dark10, dark75 } from 'lib/Colors'

export default makeStyles(
	{
		loadMoreWrap: {
			position: 'relative',
			top: 'calc(50% - 20px)',
			left: 'calc(50% - 20px)',
		},
		tableHeadRow: {
			display: 'flex',
			flexDirection: 'row',
			paddingTop: 24,
			paddingBottom: 16,
			flex: 1,
		},
		tableHeadCellFirst: {
			flex: 1,
			minWidth: 350,
		},
		tableHeadText: {
			color: '#878787',
		},
		tableHeadTitle: {
			fontSize: 20,
			fontWeight: 600,
		},
		backButton: {
			backgroundColor: dark10,
			width: 25,
			height: 25,
			marginRight: 50,
		},
		title: {
			fontWeight: 600,
			paddingTop: 0,
		},
		bulkReviewButton: {
			background: '#fff',
			padding: '10px 8px',
			display: 'flex',
			marginRight: 8,
			borderRadius: 6,
			height: 40,
		},
		bulkReviewText: {
			color: dark75,
		},
		onboardingImage: {
			height: 192,
		},
		dots: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			display: 'flex',
			alignItems: 'center',
			'& > :not(:first-child)': {
				marginLeft: 6,
			},
		},
		dot: {
			width: 12,
			height: 12,
			backgroundColor: dark10,
			borderRadius: '50%',
		},
		dotActive: {
			backgroundColor: dark75,
		},
		modalSection: {
			marginTop: 24,
			position: 'relative',
		},
		marginLeft: {
			marginLeft: 'auto',
		},
		modalSectionInner: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		leaveButton: {
			marginLeft: 8,
		},
	},
	{ index: 1 },
)
