import React, { ReactElement, useEffect, Fragment } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	Icons,
	Typography,
	PageContainer,
} from '@copyrightagent/basic-components'

import { useRouter } from 'routes/CustomBrowserRouter'
import InfoBubble from 'components/InfoBubble'
import Footer from 'components/Footer'
import { RouteUrls } from 'routes/RouteUrls'
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import trackEvent from 'hooks/useAnalytics'
import useStyle from './style'

const NotAvailablePage = (): ReactElement => {
	const router = useRouter()
	const classes = useStyle()
	const { t } = useTranslation()

	useEffect(() => {
		const container = document.getElementById('typeform-container')
		if (container) createWidget('MPvdahVy', { container, hideFooter: true })
	}, [])

	const onClickNext = () => {
		trackEvent('Go to homepage', { date: new Date() })
		window.location.href = 'https://www.copyrightagent.com/'
	}

	const onClickBack = () => {
		trackEvent('Back', {
			date: new Date(),
			page: router.history.location.pathname,
		})
		router.history.push(RouteUrls.countrySelect)
	}

	return (
		<Fragment>
			<Icons.Logo className={classes.logo} />
			<PageContainer className={classes.page}>
				<InfoBubble
					text={t('loginPage/earlyAccess')}
					className={classes.infoBubble}
				/>
				<Grid className={classes.title}>
					<Typography className={classes.titleText} variant="h5">
						{t('notAvailable/title')}
					</Typography>
					<Typography className={classes.titleSubText} variant="subtitle2">
						{t('notAvailable/subTitle')}
					</Typography>
				</Grid>
				<Grid className={classes.countrySelect}>
					<Grid item xs={12}>
						<Typography className={classes.subtitleText} variant="h6">
							{t('notAvailable/sectionTitle')}
						</Typography>
					</Grid>
					<Grid>
						<div id="typeform-container" className={classes.typeform} />
					</Grid>
				</Grid>
				<Footer
					nextLabel={t('notAvailable/continue')}
					handleNext={onClickNext}
					previousLabel={t('notAvailable/back')}
					handlePrevious={onClickBack}
				></Footer>
			</PageContainer>
		</Fragment>
	)
}

export default NotAvailablePage
