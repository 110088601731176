import { makeStyles } from '@material-ui/core'
import { dark5, dark75 } from 'lib/Colors'

export const useStyle = makeStyles(
	() => ({
		switchModeWrapper: {
			display: 'flex',
			padding: 5,
			backgroundColor: dark5,
			borderRadius: 6,
			marginBottom: 24,
		},
		switchModeButton: {
			flexGrow: 1,
			height: 40,
			backgroundColor: dark5,
		},
		switchModeButtonSeleted: {
			backgroundColor: dark75,
			color: 'white',
			pointerEvents: 'none',
			cursor: 'pointer',
		},
	}),
	{ index: 1 },
)
