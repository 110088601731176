import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import { IRootState } from 'store'
import { CLOSE_SNACK } from 'store/snackbar'
import useStyles from './style'

export interface Props {
	className?: string
	message?: string
	onClose?: () => void
	variant: 'success' | 'warning' | 'error' | 'info'
}

function MySnackbarContentWrapper(props: Props) {
	const classes = useStyles()
	const { className, message, variant, ...other } = props

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span
					id="client-snackbar"
					className={variant === 'error' ? classes.messageError : ''}
				>
					{message}
				</span>
			}
			{...other}
		/>
	)
}

export default function CustomSnackBar(): ReactElement {
	const SnackState = useSelector((state: IRootState) => state.SnackState)
	const dispatch = useDispatch()

	function handleClose() {
		dispatch({ type: CLOSE_SNACK })
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={SnackState.opened}
			style={SnackState.style}
		>
			<MySnackbarContentWrapper
				variant={SnackState.variant}
				message={SnackState.message}
				onClose={handleClose}
			/>
		</Snackbar>
	)
}
