import React, {
	useEffect,
	ReactElement,
	useState,
	useMemo,
	useCallback,
} from 'react'
import {
	IOptionValue,
	FilterDetail,
	IFlattenedAdornment,
	SearchBar,
	ISearchBarLocales,
} from '@copyrightagent/basic-components'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCurrentListTypeInUrl } from 'lib/utils'
import { EListType } from 'lib/entities/Case'
import { Filters, getDefaultFilters } from 'components/CaseListView'
import trackEvent from 'hooks/useAnalytics'
import getCountryByCode from 'lib/getCountryByCode'

interface IProps {
	initialFilters: Filters
}

export default function CaseSearchAndFilter(props: IProps): ReactElement {
	const { initialFilters } = props
	const { t } = useTranslation()
	const listType = getCurrentListTypeInUrl()
	const history = useHistory()
	const location = useLocation()
	const [caseStatusOptions, setCaseStatusOptions] = useState<IOptionValue[]>([])
	const [countryOptions, setCountryOptions] = useState<IOptionValue[]>([])
	const [caseTypeOptions, setSetCaseTypeOptions] = useState<IOptionValue[]>([])

	useEffect(() => {
		const countryValueAndLabels = initialFilters.countries
			.filter((country) => country !== 'unknown' && country !== '-')
			.map((country) => ({
				value: country,
				label: getCountryByCode(country),
			}))
		setCountryOptions(countryValueAndLabels)
	}, [initialFilters.countries, t])

	useEffect(() => {
		const caseTypeValueAndLabels = initialFilters.caseTypes.map((caseType) => ({
			value: caseType,
			label: t(`filter/caseTypes/${caseType}`),
		}))
		setSetCaseTypeOptions(caseTypeValueAndLabels)
	}, [initialFilters.caseTypes, t])

	useEffect(() => {
		const caseStatusValueAndLabels = initialFilters.caseStatus.map(
			(status) => ({ value: status, label: status }),
		)
		setCaseStatusOptions(caseStatusValueAndLabels)
	}, [initialFilters.caseStatus])

	const getFilterDetails = useCallback(
		(currentFilters: Filters): FilterDetail<Filters>[] => [
			{
				shouldRender: listType === EListType.all,
				filterKey: 'caseStatus',
				locales: {
					label: t('filterCases/caseStatusLabel'),
					noOptionsAvailable: t('filterCases/noCaseStatusAvailable'),
					placeholder: t('filterCases/caseStatus'),
				},
				filterOptions: caseStatusOptions,
				selectedValue: currentFilters.caseStatus,
			},
			{
				filterKey: 'caseTypes',
				locales: {
					label: t('filterCases/caseTypeLabel'),
					noOptionsAvailable: t('filterCases/noCaseTypesAvailable'),
					placeholder: t('filterCases/caseTypes'),
				},
				filterOptions: caseTypeOptions,
				selectedValue: currentFilters.caseTypes,
			},
			{
				filterKey: 'countries',
				locales: {
					label: t('filterCases/caseCountryLabel'),
					noOptionsAvailable: t('filterCases/noCountriesAvailable'),
					placeholder: t('filterCases/caseCountries'),
				},
				filterOptions: countryOptions,
				selectedValue: currentFilters.countries,
			},
		],
		[t, caseStatusOptions, listType, caseTypeOptions, countryOptions],
	)

	const setLabelByKey = useCallback(
		(
			key: keyof Filters,
			adornment: IFlattenedAdornment<Filters>,
		): IFlattenedAdornment<Filters> => {
			if (key === 'countries')
				adornment.label = getCountryByCode(adornment.value)
			if (key === 'caseTypes')
				adornment.label = t(`filter/caseTypes/${adornment.value}`)

			return adornment
		},
		[t],
	)

	const onSearchSubmit = useCallback((searchTerm: string): void => {
		trackEvent('Searched a case', {
			query: searchTerm,
		})
	}, [])

	const locales: ISearchBarLocales = useMemo(
		() => ({
			filterSearchLocales: {
				submitText: t('searchCases/submitText'),
				placeholder: t('searchCases/textFieldPlaceholder'),
			},
			filterPopoverLocales: {
				reset: t('filterCases/reset'),
				apply: t('filterCases/apply'),
			},
		}),
		[t],
	)

	return (
		<SearchBar<Filters>
			getDefaultFilters={getDefaultFilters}
			onSearchSubmit={onSearchSubmit}
			setLabelByKey={setLabelByKey}
			getFilterDetails={getFilterDetails}
			locales={locales}
			location={location}
			history={history}
		/>
	)
}
