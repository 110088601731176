import React, { Fragment, useState, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Row,
	Typography,
	ToggleButton,
	PrimaryButton,
	InfoIconWithTooltip,
	Modal,
	ModalSection,
} from '@copyrightagent/basic-components'
import { dark50, dark25 } from 'lib/Colors'
import { useCase, setCaseSoftClaim } from 'hooks/useCase'
import { trackCaseReviewEvent } from 'hooks/useAnalytics'
import useStyle from './style'

export default function SoftClaim(): ReactElement {
	const { t } = useTranslation()
	const { theCase, loaded } = useCase()
	const [dialogOpened, setDialogOpened] = useState<boolean>(false)
	const classes = useStyle()

	function handleDialogOpen() {
		setDialogOpened(true)
	}

	function closeDialog() {
		localStorage.dismissSoftClaimDialog = true
		setDialogOpened(false)
	}

	function handleChangeSoftClaim(
		_: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) {
		if (checked && !localStorage.dismissSoftClaimDialog) handleDialogOpen()
		const markType = checked ? 'Mark' : 'Unmark'
		trackCaseReviewEvent(`${markType} as soft claim module`, theCase)
		setCaseSoftClaim(theCase, checked)
	}

	const resolveDisabled = useMemo(() => {
		if (!loaded) return true
		if (!theCase.violation_type) return true
		return false
	}, [theCase, loaded])

	return (
		<Fragment>
			<Row justify="space-between" className={classes.softClaim}>
				<Row>
					<Typography fontColor={resolveDisabled ? dark25 : dark50}>
						{t('infringement/softClaim')}&nbsp;
					</Typography>
					<InfoIconWithTooltip
						iconColor={resolveDisabled ? dark25 : dark50}
						content={t('infringement/softClaim/infoBox')}
					/>
				</Row>
				<ToggleButton
					checked={theCase.soft_claim}
					onChange={handleChangeSoftClaim}
					name="seen"
					disabled={resolveDisabled}
				/>
			</Row>
			<Modal
				title={t('infringement/softClaim/dialogTitle')}
				titleText={t('infringement/softClaim/dialogBody')}
				open={dialogOpened}
				onClose={setDialogOpened.bind(null, false)}
				size="large"
			>
				<ModalSection>
					<Row justify={'flex-end'}>
						<PrimaryButton
							type="button"
							onClick={closeDialog}
							text={t('infringement/softClaim/dialogAction')}
						/>
					</Row>
				</ModalSection>
			</Modal>
		</Fragment>
	)
}
