import { makeStyles } from '@material-ui/styles'
import Color from 'color'
import { dark5, dark50, red } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		uploader: {
			width: 500,
			height: 76,
			position: 'fixed',
			bottom: 20,
			left: 'calc(50% - 250px)',
			padding: 12,
		},
		title: {
			fontWeight: 600,
		},
		progress: {
			fontSize: 10,
			color: dark50,
		},
		barWrapper: {
			marginTop: 6,
			height: 6,
			padding: 2,
			backgroundColor: dark5,
			width: '100%',
		},
		bar: {
			width: '100%',
			height: 2,
		},
		closeButton: {
			backgroundColor: Color(red).alpha(0.1).toString(),
			width: 34,
			height: 34,
			padding: 10,
			borderRadius: 6,
			'& svg': {
				width: 14,
				height: 14,
			},
		},
	},
	{ index: 1 },
)
