import { Filters } from 'components/CaseListView'
import { Options } from 'components/DownloadCsv'
import { setAwaitingReviewCount, setOnHoldCount } from 'hooks/useCaseStatistics'
import { EListType, ICase } from 'lib/entities/Case'
import { makeInternalAPIRequest } from 'lib/http/ApiProvider'
import Response from 'lib/http/Response'
import { getCurrentListTypeInUrl, getFiltersFromURL } from 'lib/utils'
import { getCountCases } from '../InReview/api'

interface ListCallOptions {
	filters?: Partial<Filters>
	listType?: EListType
}

interface GetCasesForListResponse extends Response {
	isSuccessful: boolean
	data: {
		onHoldCasesCount: number
		awaitingReviewCasesCount: number
		cases: ICase[]
		count: number
		countries: string[]
		caseTypes: string[]
		caseStatus: string[]
	}
}

export const getCasesForList = async (
	offset = 0,
	limit = 10,
	localOptions?: ListCallOptions,
): Promise<GetCasesForListResponse> => {
	const listType = localOptions?.listType
		? EListType[localOptions.listType]
		: EListType[getCurrentListTypeInUrl()]
	const response: GetCasesForListResponse = await makeInternalAPIRequest(
		'get',
		'/cases/cases',
		{
			limit,
			offset,
			listType,
			filters: localOptions?.filters,
		},
	)

	if (response.isSuccessful) {
		setOnHoldCount({ count: response.data.onHoldCasesCount, loaded: true })
		setAwaitingReviewCount({
			count: response.data.awaitingReviewCasesCount,
			loaded: true,
		})
	}

	return response
}

export const getCaseCountForList = async (
	localFilters?: Partial<Filters>,
): Promise<number> => {
	const filters = getFiltersFromURL()
	const listType = getCurrentListTypeInUrl()
	const response = await getCountCases({
		filters: { ...filters, ...localFilters },
		listType,
	})
	return parseInt(response.data.count, 10)
}

export const getCase = (case_id: string): Promise<Response> =>
	makeInternalAPIRequest('get', `/cases/${case_id}`)

export const updateComment = (
	caseId: string,
	comment: string,
): Promise<Response> =>
	makeInternalAPIRequest('patch', `/violations/${caseId}/comment`, {
		comment,
	})

export const markCaseSoftClaim = (case_id: string): Promise<Response> =>
	makeInternalAPIRequest('post', '/cases/mark-soft-claim', {
		case_id,
	})

export const unmarkCaseSoftClaim = (case_id: string): Promise<Response> =>
	makeInternalAPIRequest('post', '/cases/unmark-soft-claim', {
		case_id,
	})

export const markCaseCommercial = (case_id: string): Promise<Response> =>
	makeInternalAPIRequest('post', '/cases/mark-commercial', {
		case_id,
	})

export const markCaseEditorial = (case_id: string): Promise<Response> =>
	makeInternalAPIRequest('post', '/cases/mark-editorial', {
		case_id,
	})

export const getImageByName = (name: string): Promise<Response> =>
	makeInternalAPIRequest('get', `/image-upload/byName/${name}`)
export const getImageById = (imageId: string): Promise<Response> =>
	makeInternalAPIRequest('get', `/image-upload/byId/${imageId}`)
export const getSubmittedCases = (
	page: number,
	perPage: number,
): Promise<Response> =>
	makeInternalAPIRequest('get', '/submitted-cases', {
		limit: perPage,
		offset: Math.abs(page * perPage),
	})
export const getSubmittedCasesCount = (): Promise<Response> =>
	makeInternalAPIRequest('get', '/submitted-cases/count')
export const submitCase = (
	url: string,
	imageUploadedId: string,
): Promise<Response> =>
	makeInternalAPIRequest('post', '/submitted-cases', {
		url,
		imageUploadedId,
	})

export const getCsvOfCases = (
	options: Options,
	filters: Filters,
): Promise<Response> => {
	const listType = EListType[getCurrentListTypeInUrl()]
	return makeInternalAPIRequest(
		'get',
		'/cases/export-csv',
		{
			options,
			listType,
			filters,
		},
		{},
		'blob',
	)
}
