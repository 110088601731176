import { Report } from 'lib/entities/Report'

export interface ReportsResponse {
	[year: string]: {
		[month: string]: string[]
	}
}

export default function flattifyReports(
	reports: ReportsResponse = {},
): Report[] {
	let reportsArr: Report[] = []

	Object.keys(reports).forEach((year) => {
		Object.keys(reports[year]).forEach((month) => {
			reportsArr = reportsArr.concat(
				reports[year][month].map((name) => ({ year, month, name } as Report)),
			)
		})
	})

	return reportsArr
}
