import { makeStyles } from '@material-ui/core'
import { dark10, dark50 } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		folders: {
			width: '100%',
			flexGrow: 1,
			minHeight: 'calc(100vh - 320px)',
		},
		paper: {
			width: '100%',
			padding: 24,
			borderRadius: 6,
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		paperThin: {
			width: '100%',
			padding: 0,
			paddingBottom: 24,
			display: 'flex',
			justifyContent: 'space-between',
		},
		noImagesUploaded: {
			width: '100%',
			height: '100%',
			'& h5': {
				fontWeight: 600,
			},
			'& p': {
				color: dark50,
				margin: '20px auto',
			},
		},
		spinner: {
			margin: 'auto',
			marginTop: '30vh',
		},
		infiniteScrollWrap: {
			overflow: 'visible !important',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			flexBasis: '100%',
			flex: 1,
		},
		loadMoreWrap: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			paddingTop: 20,
		},
		head: {
			borderTop: `1px solid ${dark10}`,
			width: '100%',
			padding: 10,
			'& p': {
				color: dark50,
			},
		},
		nameRow: {
			width: '45%',
			'& p': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '80%',
			},
			height: 42,
		},
		statusRow: {
			width: '30%',
			height: 42,
		},
		menuRow: {
			marginLeft: 'auto',
		},
	},
	{ index: 1 },
)
