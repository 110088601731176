import { ICase } from 'lib/entities/Case'
import i18n from 'i18next'

export default function resolveSnackMessage(theCase: ICase): string {
	let translationKey = 'inReview/snackMessages/'
	translationKey += theCase.is_violating ? 'violating/' : 'nonViolating/'
	translationKey += theCase.is_violating
		? theCase.violation_type
		: theCase.non_violating_reason

	return i18n.t(translationKey)
}
