import React, { useEffect, Fragment, ReactElement, useMemo } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { dark75 } from 'lib/Colors'
import {
	List,
	ListItem,
	ListItemIcon,
	useMediaQuery,
	Tooltip,
	useTheme,
	Collapse,
} from '@material-ui/core'
import { Icons, Row, Typography } from '@copyrightagent/basic-components'
import Image from 'material-ui-image'
import { useTranslation } from 'react-i18next'
import { SystemImages } from 'assets'
import { useRouter } from 'routes/CustomBrowserRouter'
import { hasUserClaim, getCurrentUser } from 'store/utils'
import trackEvent, { UserIdenfitication } from 'hooks/useAnalytics'
import { getListUrlBasedOnListType, RouteUrls } from 'routes/RouteUrls'
import { IRootState } from 'store'
import { EListType } from 'lib/entities/Case'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import { useStyle, useStyleTooltip } from './style'
import { navBottomRoutes, navRoutes } from './routes'

interface Analytics {
	track(eventName: string, body: Record<string, unknown>): void
	identify(userId: string, traits: UserIdenfitication): void
}

declare global {
	interface Window {
		analytics: Analytics
	}
}

export const drawerWidth = 240

function ListItemLink(props: any) {
	const router = useRouter()

	const redirectTo = (route: string) => {
		// crutch for inadequate manual review behavior
		if (
			router.location.pathname.includes(route) &&
			route === RouteUrls.inReview
		)
			return
		router.history.push(route)
	}

	return (
		<ListItem button {...props} onClick={redirectTo.bind(null, props.href)}>
			{props.children}
		</ListItem>
	)
}

export default function Navigation(): ReactElement {
	const theme = useTheme()
	const classes = useStyle()
	const toolTipClasses = useStyleTooltip()
	const wide = useMediaQuery(theme.breakpoints.up('lg'))
	const {
		onHold: { count: onHoldCount },
	} = useCaseStatistics()
	const router = useRouter()
	const { t } = useTranslation()

	const claims = useSelector((state: IRootState) => state.AuthState.claims)
	const isCopyrightAgentUser = getCurrentUser().email.includes('@copyrightagent.com')
	let imageUpload = hasUserClaim('image_upload') || isCopyrightAgentUser
	let submitCase = hasUserClaim('submit_case')

	useEffect(() => {
		imageUpload = hasUserClaim('image_upload') || isCopyrightAgentUser
		submitCase = hasUserClaim('submit_case')
	}, [claims])

	const logoProps = {
		disableSpinner: true,
		disableTransition: true,
		style: {
			padding: 0,
			margin: '35px auto',
			height: 30,
			width: wide ? 130 : 30,
		},
		imageStyle: { objectFit: 'contain' },
		src: SystemImages.logoWithText,
	}

	const toKebabCase = (str: string): string =>
		(
			str.match(
				/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
			) ?? []
		)
			.map((x: string) => x.toLowerCase())
			.join('-')

	const renderOnHoldOption = useMemo(() => {
		const selected = router.location.pathname.match('on-hold') !== null

		return (
			<Collapse in={!!onHoldCount}>
				<ListItemLink
					classes={{
						root: clsx(classes.listItem, classes.onHoldOption),
						selected: classes.selectedListItem,
					}}
					disableTouchRipple
					selected={selected}
					href={getListUrlBasedOnListType({
						listType: EListType.onHold,
						resetFilter: true,
					})}
					onClick={() =>
						trackEvent('Open cases on hold', {
							onHoldCases: onHoldCount,
						})
					}
				>
					<Tooltip
						title={t('putOnHold/casesOnHold') as string}
						classes={toolTipClasses}
						placement="right"
						arrow
					>
						<ListItemIcon classes={{ root: classes.listItemIcon }}>
							<Icons.PauseIcon color={dark75} />
						</ListItemIcon>
					</Tooltip>
					<Row justify="space-between" className={classes.onHoldOptionTextWrap}>
						<Typography className={classes.linkNonActive}>
							{t('putOnHold/casesOnHold')}
						</Typography>
						<Typography fontWeight="bold">{onHoldCount}</Typography>
					</Row>
				</ListItemLink>
			</Collapse>
		)
	}, [classes, onHoldCount, router.location.pathname, t, toolTipClasses])

	return (
		<div className={classes.root}>
			<Image {...logoProps} />
			<List className={classes.list}>
				{navRoutes.filter((item) =>
					!(
						(item.route === RouteUrls.submitCase && !submitCase) ||
						(item.route === RouteUrls.images && !imageUpload)
					)
				).map((item, index) => {
					const label = t(item.labelString)
					const selected = router.location.pathname.match(item.regex) !== null

					return (
						<Fragment key={index}>
							<ListItemLink
								classes={{
									root: classes.listItem,
									selected: classes.selectedListItem,
								}}
								disableTouchRipple
								selected={selected}
								href={item.route}
								onClick={() => trackEvent(`Open ${toKebabCase(label)}`)}
							>
								<Tooltip
									title={label}
									classes={toolTipClasses}
									placement="right"
									arrow
								>
									<ListItemIcon classes={{ root: classes.listItemIcon }}>
										<item.icon color={dark75} />
									</ListItemIcon>
								</Tooltip>
								<Typography
									className={clsx({
										[classes.linkActive]: selected,
										[classes.linkNonActive]: !selected,
									})}
								>
									{label}
								</Typography>
							</ListItemLink>
							{item.route.indexOf(RouteUrls.inReviewList) >= 0 &&
								renderOnHoldOption}
						</Fragment>
					)
				})}
			</List>
			<List className={classes.bottomList}>
				{navBottomRoutes.map((item, index) => {
					const label = t(item.labelString)
					const selected = router.location.pathname.match(item.regex) !== null
					return (
						<ListItemLink
							onClick={() => trackEvent(`Open ${toKebabCase(label)}`)}
							key={index}
							classes={{
								root: classes.listItem,
								selected: classes.selectedListItem,
							}}
							disableTouchRipple
							selected={selected}
							href={item.route}
						>
							<Tooltip
								title={label}
								classes={toolTipClasses}
								placement="right"
								arrow
							>
								<ListItemIcon classes={{ root: classes.listItemIconProfile }}>
									<item.icon color={dark75} />
								</ListItemIcon>
							</Tooltip>
							<Typography
								className={clsx({
									[classes.linkActive]: selected,
									[classes.linkNonActive]: !selected,
								})}
							>
								{label}
							</Typography>
						</ListItemLink>
					)
				})}
			</List>
		</div>
	)
}
