export interface IClaims {
	submit_case?: boolean
	image_upload?: boolean
	soft_claim?: {
		[key: string]: boolean
	}
}

export const createClaims = (props: IClaims = {}): IClaims => ({
	submit_case: props.submit_case || false,
	image_upload: props.image_upload || false,
	soft_claim: props.soft_claim ?? {},
})
