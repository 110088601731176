import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import LoginPage from './Public/Login'
import LoggingInPage from './Public/LoggingIn'
import TermsPage from './Public/Terms'
import NotFoundPage from './Public/NotFoundPage'
import CustomBrowserRouter from './CustomBrowserRouter'
import CustomSnackBar from '../components/CustomSnackBar'
import RootWrapper from './RootWrapper'
import ClientSelect from './Public/ClientSelect'
import CreateAccount from './Public/CreateAccount'
import CountrySelect from './Public/CountrySelect'
import NotAvailable from './Public/NotAvailable'
import { RouteUrls } from './RouteUrls'
import FrontPage from './Public/FrontPage'
import Dashboard from './Private/Dashboard'
import GetStarted from './Private/GetStarted'
import Case from './Private/Case'
import InReview from './Private/InReview'
import InReviewList from './Private/Cases/InReviewList'
import SearchCases from './Private/SearchCases'
import PreapprovedCases from './Private/Cases/Preapproved'
import Reports from './Private/Reports'
import Settings from './Private/Settings'
import OnHoldCases from './Private/Cases/OnHold'
import CreateCase from './Private/Cases/CreateCase'
import Images from './Private/Images'
import ImageDetails from './Private/Images/ImageDetails'

const useStyle = makeStyles(
	{
		root: {
			display: 'flex',
		},
	},
	{ index: 1 },
)

const mainRoutes = [
	<Route exact path="/" component={FrontPage} />,
	<Route exact path={RouteUrls.login} component={LoginPage} />,
	<Route exact path={RouteUrls.loggingIn} component={LoggingInPage} />,
	<Route exact path={RouteUrls.terms} component={TermsPage} />,
	<Route exact path={RouteUrls.countrySelect} component={CountrySelect} />,
	<Route exact path={RouteUrls.notAvailable} component={NotAvailable} />,
	<Route exact path={RouteUrls.createAccount} component={CreateAccount} />,
	<Route exact path={RouteUrls.selectClient} component={ClientSelect} />,
	<Route exact path={RouteUrls.getStarted} component={GetStarted} />,
	<Route exact path={RouteUrls.dashboard} component={Dashboard} />,
	<Route exact path={`${RouteUrls.case}/:caseId`} component={Case} />,
	<Route path={`${RouteUrls.inReviewList}/:caseId`} component={InReview} />,
	<Route path={RouteUrls.inReviewList} component={InReviewList} />,
	<Route path={`${RouteUrls.findCaseList}/:caseId`} component={InReview} />,
	<Route path={RouteUrls.findCaseList} component={SearchCases} />,
	<Route path={`${RouteUrls.preApprovedList}/:caseId`} component={InReview} />,
	<Route path={RouteUrls.preApprovedList} component={PreapprovedCases} />,
	<Route path={`${RouteUrls.onHoldList}/:caseId`} component={InReview} />,
	<Route path={RouteUrls.onHoldList} component={OnHoldCases} />,
	<Route path={RouteUrls.reports} component={Reports} />,
	<Route path={RouteUrls.settings} component={Settings} />,
];

const submitClaimsRoutes =
	[
		<Route path={RouteUrls.submitCase} component={CreateCase} />,
	];

const uploadImagesRoutes =
	[
		<Route exact path={RouteUrls.images} component={Images} />,
		<Route exact path={`${RouteUrls.imageDetails}/:imageName`} component={ImageDetails} />,
	];

const redirectionRoutes = [
	<Route component={NotFoundPage} />,
];

const routes = mainRoutes.concat(submitClaimsRoutes, uploadImagesRoutes, redirectionRoutes);

function AppRouter(): ReactElement {
	const classes = useStyle()
	return (
		<div className={classes.root}>
			<CustomBrowserRouter>
				<RootWrapper>
					<Switch>
						{routes}
					</Switch>
					<CustomSnackBar />
				</RootWrapper>
			</CustomBrowserRouter>
		</div>
	)
}

export default AppRouter
