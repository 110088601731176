import React, {
	ReactElement,
	useMemo,
	useState,
	createContext,
	useContext,
	useEffect,
	Dispatch,
	SetStateAction,
	useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { BulkReviewCaseType, EListType, ICase } from 'lib/entities/Case'
import {
	Row,
	PageBody,
	PageTitle,
	Typography,
	ListItemCounter,
} from '@copyrightagent/basic-components'
import CaseSearchAndFilter from 'components/CaseSearchAndFilter'
import CaseList from 'components/CaseList'
import DownloadCsv from 'components/DownloadCsv'
import useSelect from 'hooks/useSelect'
import useStyle from './style'

interface ContextProps {
	rowsAreSelectable: boolean
	rowsSelected: string[]
	allCases: ICase[]
	violationType: BulkReviewCaseType
	triggerRefetch: boolean
	setTriggerRefetch: Dispatch<SetStateAction<boolean>>
	setViolationType: Dispatch<SetStateAction<BulkReviewCaseType>>
	toggleSelect: ReturnType<typeof useSelect>['toggleSelect']
	toggleSelectAll: ReturnType<typeof useSelect>['toggleSelectAll']
}

interface IProps {
	listType: EListType
}

export interface Filters {
	countries: string[]
	caseTypes: string[]
	caseStatus: string[]
	searchTerm: string
	domain: string
}

// a function that returns the object instead of an object to make sure
// it always has a different reference in memory to prevent accidental mutations
export const getDefaultFilters = (): Filters => ({
	countries: [],
	caseTypes: [],
	caseStatus: [],
	searchTerm: '',
	domain: '',
})

const CaseListViewContext = createContext<ContextProps | undefined>(undefined)

export const useCaseListView = (): ContextProps => {
	const context = useContext(CaseListViewContext)
	if (!context)
		throw new Error('useCaseListView must be used within CaseListView')

	return context
}

export default function CaseListView({
	listType,
}: IProps): ReactElement | null {
	const classes = useStyle()
	const { t } = useTranslation()
	const [filtersData, setFiltersData] = useState<Filters>(getDefaultFilters())
	const [hasCases, setHasCases] = useState<boolean>(false)
	const [totalCaseCount, setTotalCaseCount] = useState<number>(0)

	const displaySearchAndFilter = useMemo(
		() => <CaseSearchAndFilter initialFilters={filtersData} />,
		[filtersData],
	)

	const [allCases, setAllCases] = useState<ICase[]>([])
	const [violationType, setViolationType] =
		useState<BulkReviewCaseType>(undefined)
	const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false)
	const { selected: rowsSelected, toggleSelect, toggleSelectAll } = useSelect()

	function findCase(violationId: string): ICase | undefined {
		return allCases.find((c) => c.violation_id === violationId)
	}

	// Automatically set case type based on what case rows have been selected
	useEffect(() => {
		const hasCommercial = rowsSelected.some(
			(r) => findCase(r)?.violation_type === 'Commercial',
		)
		const hasEditorial = rowsSelected.some(
			(r) => findCase(r)?.violation_type === 'Editorial',
		)

		if (hasCommercial && !hasEditorial) return setViolationType('Commercial')
		if (hasEditorial && !hasCommercial) return setViolationType('Editorial')
		if (hasCommercial && hasEditorial) return setViolationType('Multiple')
		if (!hasCommercial && !hasEditorial) return setViolationType(undefined)
	}, [allCases, rowsSelected])

	useEffect(() => {
		if (triggerRefetch) setTriggerRefetch(false)
	}, [triggerRefetch])

	const onCasesLoad = useCallback((cases) => {
		setHasCases(true)
		setAllCases(cases)
	}, [])

	// re-render case list only if query string changes (set state functions do not trigger re-render)
	const caseList = useMemo(
		() => (
			<CaseList
				onSetFilters={setFiltersData}
				onCasesLoad={onCasesLoad}
				onTotalCount={setTotalCaseCount}
			/>
		),
		[onCasesLoad],
	)

	const contextValue: ContextProps = useMemo(
		() => ({
			rowsAreSelectable: listType === EListType.inReview,
			rowsSelected,
			allCases,
			violationType,
			triggerRefetch,
			setTriggerRefetch,
			setViolationType,
			toggleSelect,
			toggleSelectAll,
		}),
		[listType, rowsSelected, allCases, violationType, triggerRefetch],
	)

	return (
		<CaseListViewContext.Provider value={contextValue}>
			<PageTitle>
				<Row>
					<Typography variant="h5" className={classes.title}>
						{t(`caseList/${EListType[listType]}/pageTitle`)}
					</Typography>
				</Row>
			</PageTitle>
			<PageBody>
				{displaySearchAndFilter}
				{hasCases ? (
					<Row className={classes.tableHeadRow}>
						<ListItemCounter
							totalItemCount={totalCaseCount}
							title={t('caseList/all/caseCount/caseName/plural')}
							itemNameSingular={t('caseList/all/caseCount/caseName/singular')}
							itemNamePlural={t('caseList/all/caseCount/caseName/plural')}
						/>
						<DownloadCsv caseCount={totalCaseCount} />
					</Row>
				) : (
					<></>
				)}
				{caseList}
			</PageBody>
		</CaseListViewContext.Provider>
	)
}
