import { AnyAction } from 'redux'

export const SET_HELP_OPENED = 'GLOBAL/SET_HELP_OPENED'
export interface IGlobalState {
	drawerOpened: boolean
	helpOpened: boolean
}

const initialState: IGlobalState = {
	drawerOpened: false,
	helpOpened: false,
}

export default function globalReducer(
	state = initialState,
	action: AnyAction,
): IGlobalState {
	switch (action.type) {
		case SET_HELP_OPENED:
			return {
				...state,
				helpOpened: action.opened,
			}
		default:
			return state
	}
}
