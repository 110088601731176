import React from 'react'
import { Page } from '@copyrightagent/basic-components'
import { PageProps } from '@copyrightagent/basic-components/dist/components/layout/PageComponents/Page'
import Navigation from './Navigation'

const PageWithNavigation = (props: PageProps): React.ReactElement => (
	<>
		<Navigation />
		<Page {...props} />
	</>
)

export default PageWithNavigation
