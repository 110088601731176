import moment from 'moment-timezone'
import i18n from 'i18next'
import { buildPhotoURL, buildScreenshotURL } from '../http/ApiProvider'

export type NonViolatingReason = nonViolatingReasons | unclearCopyrightReason
export type ICaseType = 'Commercial' | 'Editorial'
export type BulkReviewCaseType = ICaseType | 'Multiple' | undefined

export enum EListType {
	'onHold',
	'inReview',
	'preapproved',
	'all',
	'reviewed',
	'awaitingReview',
}

export enum nonViolatingReasons {
	whitelisted = 'Whitelisted',
	singleSale = 'SingleSale',
	other = 'OtherReason',
	handout = 'Handout',
}

export enum unclearCopyrightReason {
	unknownPhotographer = 'UnknownPhotographer',
	unknownDate = 'UnknownDate',
	outOfCopyright = 'OutOfCopyright',
	counterpartyOnPicture = 'CounterpartyOnPicture',
	other = 'OtherUnclearReason',
}

export type ICaseStatus = 'open' | 'closed' | 'won' | 'created'

type CaseTypeColorProps =
	| 'purple'
	| 'blue'
	| 'grey'
	| 'red'
	| 'yellow'
	| 'green'

export interface ICase {
	violation_id: string
	reg_date: string
	client_review_required: boolean
	company_name?: string
	violation_type: ICaseType
	photo_id: string
	case_id: string
	days_remaining?: string
	comment: string
	photo_name?: string
	image_archive_url_pattern?: string
	is_violating: boolean
	on_hold: boolean
	non_violating_reason: NonViolatingReason | ''
	seen: boolean
	photographer_name: string
	status: ICaseStatus
	soft_claim: boolean
	url_raw: string
	occurred_at: string
	customer_approve_time: string
	screenshot_timestamp: string
	client_name?: string
	last_modified: string
	supplier: string
	timestamp?: string
	country: string
	domain: string
}

export interface ICaseLocation {
	type: string
	label: string
}

export const caseLocations: { [name: string]: ICaseLocation } = {
	preapproved: {
		type: 'preapproved',
		label: 'caseRow/labels/preapproved',
	},
	inReview: {
		type: 'inReview',
		label: 'caseRow/labels/inReview',
	},
	onHold: {
		type: 'onHold',
		label: 'caseRow/labels/onHold',
	},
	createdCase: {
		type: 'createdCase',
		label: 'caseRow/labels/createdCase',
	},
}

export function updateCase(theCase: ICase, updateProps: Partial<ICase>): ICase {
	return {
		...theCase,
		...updateProps,
	}
}

export function getCaseLabelColor(type: string): CaseTypeColorProps {
	const colorsMap: Record<string, CaseTypeColorProps> = {
		Editorial: 'purple',
		Commercial: 'blue',
		'special-price': 'red',
		SoftClaim: 'yellow',
	}

	if (!colorsMap[type]) return 'grey'

	return colorsMap[type]
}

export function getCalendarRegDate(reg_date: string): string {
	if (!reg_date) return ''

	return moment(Number(reg_date)).format('MMMM D, YYYY')
}

export function getFormatOccurredDate(
	date: string,
	format = 'MMMM DD, YYYY',
): string {
	return moment(date).format(format)
}

export function getIntFormatOccurredDate(date: string): string {
	return moment(parseInt(date, 10)).format('MMMM DD, YYYY')
}

export function getOriginalImageUrl(theCase: ICase, size = 100): string {
	return theCase.violation_id && buildPhotoURL(theCase.photo_id, size)
}

export function getArchiveImageUrl(theCase: ICase, size = 100): string {
	if (theCase.photo_name && theCase.image_archive_url_pattern)
		return theCase.image_archive_url_pattern.replace(
			'{{imageId}}',
			theCase.photo_name.split('.')[0],
		)

	return theCase.violation_id && buildPhotoURL(theCase.photo_id, size)
}

export function getScreenshotUrl(theCase: ICase, size = 100): string {
	return theCase.violation_id && buildScreenshotURL(theCase.violation_id, size)
}

export function isSubmitReviewDisabled(theCase: ICase): boolean {
	if (!theCase.client_review_required) return true
	return false
}

export function getActionPromptPath(theCase: ICase): string {
	if (theCase.is_violating && !theCase.violation_type)
		return 'caseActions/pleaseChoose'
	if (!theCase.is_violating && !theCase.non_violating_reason)
		return 'caseActions/pleaseChoose'
	if (
		!theCase.is_violating &&
		(theCase.non_violating_reason === unclearCopyrightReason.other ||
			theCase.non_violating_reason === nonViolatingReasons.other) &&
		theCase.comment === ''
	)
		return 'caseActions/pleaseComment'
	return ''
}

export function getViolationLabel(theCase: ICase): string {
	if (!theCase.is_violating && theCase.non_violating_reason) {
		const reason = theCase.non_violating_reason
		const isOtherReason = Object.values(unclearCopyrightReason).some(
			(v) => v === reason,
		)

		if (isOtherReason) return i18n.t('caseRow/labels/UnclearReason')

		return i18n.t(`caseRow/labels/${reason}`)
	}
	if (theCase.violation_type) return theCase.violation_type

	return ''
}

export function isCasePreapproved(theCase: ICase): boolean {
	const { reg_date, client_review_required } = theCase

	return (
		moment(reg_date).valueOf() > moment().subtract(14, 'days').valueOf() &&
		!client_review_required
	)
}

export function isCaseInReview(theCase: ICase): boolean {
	const { client_review_required, customer_approve_time } = theCase
	return client_review_required && !customer_approve_time
}

export function getCaseLocation(theCase: ICase): ICaseLocation {
	if (isCasePreapproved(theCase)) return caseLocations.preapproved
	if (theCase.on_hold) return caseLocations.onHold
	return caseLocations.inReview
}

export default function createCase(props: Partial<ICase> = {}): ICase {
	let status = props.status || 'open'
	if (!props.status && !props.is_violating && props.customer_approve_time)
		status = 'closed'

	return {
		violation_id: props.violation_id || '',
		reg_date: props.reg_date || '',
		country: props.country || '',
		client_review_required: !!props.client_review_required,
		company_name: props.company_name || '-',
		violation_type: props.violation_type || 'Commercial',
		photo_id: props.photo_id || '',
		case_id: props.case_id || '',
		days_remaining: props.days_remaining || '',
		comment: props.comment || '',
		image_archive_url_pattern: props.image_archive_url_pattern || '',
		photo_name: props.photo_name ? `${props.photo_name}` : '',
		non_violating_reason: props.non_violating_reason || '',
		is_violating: !!props.is_violating,
		on_hold: !!props.on_hold,
		seen: !!props.seen,
		photographer_name: props.photographer_name || '-',
		supplier: props.supplier || '',
		status,
		soft_claim: !!props.soft_claim,
		url_raw: props.url_raw || '',
		occurred_at: props.occurred_at || '',
		customer_approve_time: props.customer_approve_time || '',
		last_modified: props.last_modified || '',
		screenshot_timestamp: props.screenshot_timestamp
			? moment
					.tz(
						props.screenshot_timestamp,
						Intl.DateTimeFormat().resolvedOptions().timeZone,
					)
					.format('MMM DD, YYYY [at] HH:mm z')
			: '-',
		client_name: props.client_name,
		domain: props.domain || '',
	}
}
