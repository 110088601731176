import { Filters } from 'components/CaseListView'
import { setAwaitingReviewCount, setOnHoldCount } from 'hooks/useCaseStatistics'
import {
	EListType,
	ICase,
	ICaseType,
	NonViolatingReason,
} from 'lib/entities/Case'
import { makeInternalAPIRequest } from 'lib/http/ApiProvider'
import Response from 'lib/http/Response'
import { getCurrentListTypeInUrl, getFiltersFromURL } from 'lib/utils'

export function getFirstCase(): Promise<Response> {
	const filters = getFiltersFromURL()
	const listType = EListType[getCurrentListTypeInUrl()]
	return makeInternalAPIRequest('get', '/cases/first-case', {
		filters,
		listType,
	})
}

export function getNextCase(currentCaseId: string): Promise<Response> {
	const filters = getFiltersFromURL()
	const listType = EListType[getCurrentListTypeInUrl()]
	return makeInternalAPIRequest('get', '/cases/next-case', {
		filters,
		listType,
		currentCaseId,
	})
}

interface getCountCasesProps {
	filters?: Partial<Filters>
	listType: EListType
}

interface getCountCasesRespone extends Response {
	isSuccessful: boolean
	data: {
		count: number
	}
}

export async function getCountCases({
	filters,
	listType,
}: getCountCasesProps): Promise<Response> {
	const response: getCountCasesRespone = await makeInternalAPIRequest(
		'get',
		'/cases/cases-count',
		{
			filters,
			listType: EListType[listType],
		},
	)

	if (!filters || Object.keys(filters).length) {
		if (listType === EListType.onHold)
			setOnHoldCount({ count: response.data.count, loaded: true })
		if (listType === EListType.awaitingReview)
			setAwaitingReviewCount({ count: response.data.count, loaded: true })
	}

	return response
}

interface putCaseOnHoldResponse extends Response {
	isSuccessful: boolean
	data: {
		onHoldCasesCount: number
	}
}

export async function putCaseOnHold(case_id: string): Promise<Response> {
	const response: putCaseOnHoldResponse = await makeInternalAPIRequest(
		'post',
		'/cases/put-on-hold',
		{ case_id },
	)

	if (response.isSuccessful)
		setOnHoldCount({ count: response.data.onHoldCasesCount, loaded: true })

	return response
}

export async function removeCaseFromHold(case_id: string): Promise<Response> {
	const response: putCaseOnHoldResponse = await makeInternalAPIRequest(
		'post',
		'/cases/put-off-hold',
		{ case_id },
	)

	if (response.isSuccessful)
		setOnHoldCount({ count: response.data.onHoldCasesCount, loaded: true })

	return response
}

interface reviewCaseResponse extends Response {
	isSuccessful: boolean
	data: {
		onHoldCasesCount: number
		awaitingReviewCasesCount: number
	}
}

export async function approveCase(case_id: string): Promise<Response> {
	const response: reviewCaseResponse = await makeInternalAPIRequest(
		'post',
		'/cases/approve',
		{ case_id },
	)

	if (response.isSuccessful) {
		setOnHoldCount({ count: response.data.onHoldCasesCount, loaded: true })
		setAwaitingReviewCount({
			count: response.data.awaitingReviewCasesCount,
			loaded: true,
		})
	}

	return response
}

interface rejectCaseProps {
	case_id: string
	non_violating_reason: string
	comment: string
}

export async function rejectCase(body: rejectCaseProps): Promise<Response> {
	const response: reviewCaseResponse = await makeInternalAPIRequest(
		'post',
		'/cases/reject',
		body,
	)

	if (response.isSuccessful) {
		setOnHoldCount({ count: response.data.onHoldCasesCount, loaded: true })
		setAwaitingReviewCount({
			count: response.data.awaitingReviewCasesCount,
			loaded: true,
		})
	}

	return response
}

interface approveRejectedCaseResponse extends Response {
	isSuccessful: boolean
	data: {
		theCase: ICase
	}
}

export async function approveRejectedCase(case_id: string): Promise<Response> {
	const response: approveRejectedCaseResponse = await makeInternalAPIRequest(
		'post',
		'/cases/reopen-and-approve',
		{ case_id },
	)

	return response
}

export interface rejectCasesProps {
	case_ids: string[]
	non_violating_reason: NonViolatingReason
	comment: string
}

export function rejectCases(body: rejectCasesProps): Promise<Response> {
	return makeInternalAPIRequest('post', '/cases/bulk-reject', body)
}

export interface approveCasesProps {
	case_ids: string[]
	violation_type: ICaseType | undefined
	is_soft_claim: boolean
	related_case_id?: string
}

export function approveCases(body: approveCasesProps): Promise<Response> {
	return makeInternalAPIRequest('post', '/cases/bulk-approve', body)
}

type CheckedCase = Pick<ICase, 'violation_id' | 'url_raw'>

export function checkMissingCases(
	selectedCases: CheckedCase[],
): Promise<Response> {
	return makeInternalAPIRequest('post', '/cases/bulk-check', { selectedCases })
}
