import {
	Row,
	Col,
	InfoIconWithTooltip,
	PrimaryButton,
	Typography,
} from '@copyrightagent/basic-components'
import trackEvent from 'hooks/useAnalytics'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import { dark75 } from 'lib/Colors'
import { EListType } from 'lib/entities/Case'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getCaseUrlBasedOnListType } from 'routes/RouteUrls'
import useStyle from './style'

const CasesForReview = (): ReactElement => {
	const classes = useStyle()
	const router = useRouter()
	const { awaitingReview, onHold } = useCaseStatistics()
	const { t } = useTranslation()

	const handleGoToReview = async (): Promise<void> => {
		trackEvent('Open Manual Review from Dashboard', {
			awaitingReviewCount: awaitingReview.count,
		})
		router.history.push(
			await getCaseUrlBasedOnListType({ listType: EListType.inReview }),
		)
	}

	const reviewNowButton = useMemo(() => {
		const disabled = awaitingReview.count === 0 && onHold.count === 0
		return (
			<PrimaryButton
				type="button"
				disabled={disabled}
				onClick={handleGoToReview}
				text={t('dashboard/reviewNow')}
				className={classes.reviewButton}
			/>
		)
	}, [awaitingReview.count, awaitingReview.loaded, onHold.loaded, onHold.count])

	return (
		<Col justify="space-between">
			<Col>
				<Typography variant="body2" className={classes.title}>
					{t('dashboard/title')}
					<InfoIconWithTooltip
						iconColor={dark75}
						styleIcon={{ width: 16, height: 16, marginLeft: 5 }}
						content={t('dashboard/infoBox')}
					/>
				</Typography>
				<Typography className={classes.subtitle} variant="body2">
					{t('dashboard/text')}
				</Typography>
			</Col>
			<Row align="flex-end" justify="space-between" style={{ width: '100%' }}>
				<Typography fontWeight="bold" variant="h2" style={{ padding: 0 }}>
					{awaitingReview.count}
				</Typography>
				{reviewNowButton}
			</Row>
		</Col>
	)
}

export default CasesForReview
