import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(
	{
		pageTitle: {
			zIndex: 400,
		},
		titleWrapper: {
			'& h5': {
				paddingTop: 0,
				paddingBottom: 0,
			},
		},
		title: {
			fontWeight: 600,
		},
		caseName: {
			marginRight: 16,
		},
		caseStatus: {
			marginRight: 15,
			marginLeft: 15,
		},
		caseLocation: {
			marginRight: 7,
		},
		dynamicContentWrap: {
			minWidth: 350,
			height: 30,
			'& > * + *': {
				marginLeft: 16,
				marginRight: 0,
			},
		},
		notLoaded: {
			borderRadius: 58,
			backgroundImage: 'linear-gradient(184.9deg, #F1F2F2 0%, #EAECEC 100%)',
		},
	},
	{ index: 1 },
)
