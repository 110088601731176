export interface Report {
	year: string
	month: string
	name: string
}

export function createReport(props: Partial<Report> = {}): Report {
	return {
		year: props.year || '',
		month: props.month || '',
		name: props.name || '',
	}
}
