import {
	TextArea,
	Row,
	Icons,
	Typography,
	PrimaryButton,
	ModalSection,
} from '@copyrightagent/basic-components'
import { Button } from '@material-ui/core'
import { blue } from 'lib/Colors'
import React, { ReactElement, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'store/utils'
import { useStyle } from '../style'

interface InsertInfoStepProps {
	handleClose: () => void
	handleSubmit: (url: string, submitFile: File) => Promise<void>
	isPending: boolean
}

const InsertInfoStep = ({
	handleClose,
	handleSubmit,
	isPending,
}: InsertInfoStepProps): ReactElement => {
	const classes = useStyle()
	const { t } = useTranslation()
	const [file, setFile] = useState<File | null>(null)
	const [caseUrl, setCaseUrl] = useState<string>('')
	const [validUrl, setValidUrl] = useState<boolean>(false)

	const urlValidator =
		/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/

	const handleInput = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { value } = e.target
		setValidUrl(false)
		if (urlValidator.test(value)) setValidUrl(true)
		setCaseUrl(value)
	}

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		setFile(acceptedFiles[0])
		if (rejectedFiles.length)
			rejectedFiles[0].errors.map((error: any) =>
				toast(`${t('createCase/imageUploadFail')}: ${error.message}`, 'error'),
			)
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg, image/png',
		maxFiles: 1,
		multiple: false,
		onDrop,
	})

	const handleCancel = () => {
		setFile(null)
		setValidUrl(false)
		setCaseUrl('')
		handleClose()
	}
	return (
		<>
			<ModalSection>
				<Typography variant="body2" className={classes.actionLabel}>
					{t('createCase/submit/location')}
				</Typography>
				<TextArea
					value={caseUrl}
					type="text"
					label=""
					className={classes.input}
					variant="filled"
					onChange={handleInput}
				/>
				<Typography variant="body2" className={classes.actionLabel}>
					{t('createCase/submit/image')}
				</Typography>
				<Row>
					<div {...getRootProps({ className: classes.dropzone })}>
						{file ? (
							<Icons.CloseIcon
								color={blue}
								className={classes.discardChoice}
								onClick={() => setFile(null)}
							/>
						) : (
							<></>
						)}
						<input {...getInputProps()} />
						<Icons.UploadIcon color={blue} />
						{file ? (
							<Typography variant="body2">{file.name}</Typography>
						) : (
							<>
								<Typography variant="body2">
									{t('createCase/uploadFile')}
								</Typography>
								<Typography variant="body2">
									{t('images/imageTypes')}
								</Typography>
							</>
						)}
					</div>
				</Row>
			</ModalSection>
			<ModalSection>
				<Row justify="flex-end">
					<Button onClick={handleCancel} className={classes.invisibleButton}>
						<Typography>{t('cancel')}</Typography>
					</Button>
					<PrimaryButton
						type="button"
						loading={isPending}
						loadingText={t('images/uploading')}
						onClick={() => handleSubmit(caseUrl, file as File)}
						disabled={!validUrl || !file}
						text={t('submit')}
					/>
				</Row>
			</ModalSection>
		</>
	)
}
export default InsertInfoStep
