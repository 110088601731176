import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getAuth, onAuthStateChanged, Unsubscribe } from 'firebase/auth'
import {
	SET_PROFILE,
	SET_TOKEN,
	SET_CLAIMS,
	SET_SELECTED_CLIENT,
} from 'store/auth'
import { createClient } from 'lib/entities/Client'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import { identifyUser } from 'hooks/useAnalytics'
import { getSelectedClient, toast, logout } from 'store/utils'
import { me, checkNewUser } from './api'
import { getAwaitingReviewCount, getOnHoldCount } from './useCaseStatistics'

export default (): void => {
	const router = useRouter()
	const auth = getAuth()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const checkIfOn = (route: string) => router.location.pathname.includes(route)
	const [onLandingPage, setOnLadingPage] = useState(
		router.location.pathname === RouteUrls.landingPage,
	)
	const [onDashboard, setOnDashboard] = useState(checkIfOn(RouteUrls.dashboard))
	const [onLogin, setOnLogin] = useState(checkIfOn(RouteUrls.login))
	const [onLoggingIn, setOnLoggingIn] = useState(checkIfOn(RouteUrls.loggingIn))
	const [onClientSelect, setOnClientSelect] = useState(
		checkIfOn(RouteUrls.selectClient),
	)
	const [onCreateAccount, setOnCreateAccount] = useState(
		checkIfOn(RouteUrls.createAccount),
	)
	const [onCountrySelect, setOnCountrySelect] = useState(
		checkIfOn(RouteUrls.countrySelect),
	)
	const [onNotAvailable, setOnNotAvailable] = useState(
		checkIfOn(RouteUrls.notAvailable),
	)
	const [onTerms, setOnTerms] = useState(checkIfOn(RouteUrls.terms))

	const { search } = useLocation()

	const newAccount = new URLSearchParams(search).get('newAccount')

	const checkOnAccountCreation = (): boolean =>
		onCreateAccount || onTerms || onCountrySelect || onNotAvailable
	const checkIfCreatingAccount = (): boolean => !!newAccount && onLoggingIn
	const shouldRedirectToDashboard = (): boolean =>
		onLandingPage ||
		onLogin ||
		onLoggingIn ||
		onClientSelect ||
		checkOnAccountCreation()

	const fetchCaseStatistics = () => {
		getOnHoldCount()
		getAwaitingReviewCount()
	}

	const setProfile = async () => {
		const userResponse = await me()

		if (!userResponse.isSuccessful) {
			if (checkOnAccountCreation()) return
			let errorCode = userResponse.data?.code

			if (errorCode === 'user_not_found') {
				const canCreateAccount = await checkNewUser()
				if (canCreateAccount.isSuccessful && !onCountrySelect)
					return router.history.replace(RouteUrls.countrySelect)
				errorCode = canCreateAccount.data.code
			}
			if (errorCode) await toast(t(`login/${errorCode}`), 'warning')
			else await toast(t('login/authentication_failed'), 'warning')

			return logout()
		}

		identifyUser({
			email: userResponse.data.email,
			name: userResponse.data.firstname,
			id: userResponse.data.user_id,
			regDate: new Date(),
		})

		dispatch({ type: SET_PROFILE, data: userResponse.data })

		const user = userResponse.data
		if (!user.user_id) return logout()

		if (user.hubs?.length === 1) {
			dispatch({
				type: SET_SELECTED_CLIENT,
				client: createClient(user.hubs[0]),
			})
			fetchCaseStatistics()
			if (newAccount) return router.history.push(RouteUrls.getStarted)
			if (shouldRedirectToDashboard())
				return router.history.push(RouteUrls.dashboard)
		}

		if (user.hubs?.length === 0) {
			dispatch({
				type: SET_SELECTED_CLIENT,
				client: createClient(user.hubs[0]),
			})
		}
		fetchCaseStatistics()

		const client = getSelectedClient()
		if (client.client_id && !onDashboard && (onLogin || onLandingPage))
			return router.history.replace(RouteUrls.dashboard)

		if (!onClientSelect && !client.client_id)
			return router.history.push(RouteUrls.selectClient)
	}

	if (checkIfCreatingAccount()) setProfile()

	useEffect(() => {
		let unsubscribe: Unsubscribe

		const checkUser = async () => {
			let throttle = true
			unsubscribe = await onAuthStateChanged(auth, (user) => {
				if (router.location.pathname.includes(RouteUrls.loggingIn) && !user)
					return
				if (!user) return logout()
				if (throttle) {
					throttle = false
					user.getIdTokenResult(true).then((idToken) => {
						const claims = idToken.claims
						dispatch({ type: SET_CLAIMS, claims })
					})

					user.getIdToken(true).then((idToken) => {
						dispatch({ type: SET_TOKEN, token: idToken })
						setProfile()
					})
				}
			})
		}
		checkUser()

		return function cleanup() {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		setOnDashboard(checkIfOn(RouteUrls.dashboard))
		setOnClientSelect(checkIfOn(RouteUrls.selectClient))
		setOnLogin(checkIfOn(RouteUrls.login))
		setOnLoggingIn(checkIfOn(RouteUrls.loggingIn))
		setOnLadingPage(router.location.pathname === RouteUrls.landingPage)
		setOnCreateAccount(checkIfOn(RouteUrls.createAccount))
		setOnCountrySelect(checkIfOn(RouteUrls.countrySelect))
		setOnLogin(checkIfOn(RouteUrls.login))
		setOnTerms(checkIfOn(RouteUrls.terms))
		setOnNotAvailable(checkIfOn(RouteUrls.notAvailable))
	}, [router.location.pathname])
}
