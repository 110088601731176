import { makeStyles } from '@material-ui/core'
import { dark10, dark5, dark50, dark75 } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		imageDetailsContainer: {
			backgroundColor: dark10,
			left: 0,
			width: '100vw',
			height: '100vh',
			zIndex: 1300,
		},
		fixedView: {
			position: 'fixed',
		},
		image: {
			width: 'calc(100vw - 320px)',
			'& img': {
				maxWidth: '100%',
				maxHeight: '100vh',
				width: 'auto',
			},
		},
		imageDetailsPanel: {
			backgroundColor: 'white',
			boxShadow:
				'0px 0px 1px rgba(41, 50, 65, 0.25), 10px 10px 40px rgba(41, 50, 65, 0.15)',
			borderRadius: '12px 0 0 12px',
			width: 320,
			minWidth: 320,
			height: '100vh',
			padding: 24,
		},
		imageDetailsClose: {
			width: '100%',
			paddingBottom: 36,
			'& svg': {
				cursor: 'pointer',
				width: 16,
				height: 16,
			},
		},
		closeButton: {
			minWidth: 'unset',
		},
		copyLink: {
			marginRight: 20,
		},
		imageTitle: {
			'& h5': {
				fontWeight: 600,
				overflowWrap: 'anywhere',
				padding: 0,
			},
		},
		imageTitleLabel: {
			padding: '5px 12px',
			backgroundColor: dark5,
			color: dark50,
			fontSize: 14,
			paddingBottom: 6,
			borderRadius: 6,
			marginBottom: 8,
		},
		imageDetails: {
			padding: '24px 0px',
			borderBottom: `1px solid ${dark10}`,
			width: '100%',
			marginBottom: 24,
		},
		icon: {
			marginRight: 12,
		},
		imageDetailsTitle: {
			color: dark75,
			paddingBottom: 12,
		},
		hidden: {
			opacity: 0,
		},
		showImage: {
			transition: 'opacity .5s',
		},
		loading: {
			animation: '$rotating 2s linear infinite',
		},
		'@keyframes rotating': {
			'0%': {
				transform: 'rotate(0deg)',
			},
			'100%': {
				transform: 'rotate(360deg)',
			},
		},
	},
	{ index: 1 },
)
