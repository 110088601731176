import React, {
	useMemo,
	useState,
	useCallback,
	ReactElement,
	Fragment,
	useEffect,
} from 'react'
import clsx from 'clsx'
import { Paper, Link, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SideBySideMagnifier } from 'react-image-magnifiers'
import {
	Row,
	Col,
	Typography,
	ImageComponent,
	CopyrightAgentLoader,
	Icons,
	SecondaryButton,
} from '@copyrightagent/basic-components'
import {
	preloadImage,
	copyToClipboard,
	getCurrentListTypeInUrl,
} from 'lib/utils'
import createCase, {
	getScreenshotUrl,
	getOriginalImageUrl,
	getArchiveImageUrl,
	ICase,
	getFormatOccurredDate,
	EListType,
} from 'lib/entities/Case'
import { dark50 } from 'lib/Colors'
import { getListUrlBasedOnListType } from 'routes/RouteUrls'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getCountCases, getNextCase } from '../../routes/Private/InReview/api'
import OtherCasesByDomain from './ByDomain'
import useStyle from './style'

interface IProps {
	theCase: ICase
}

export default function ImageAndScreenshot({ theCase }: IProps): ReactElement {
	const { t } = useTranslation()
	const classes = useStyle()
	const router = useRouter()
	const [loaded, setLoaded] = useState<boolean>(false)
	const [domainCaseCount, setDomainCaseCount] = useState<number>(0)

	const domain = theCase.domain
	const listType = getCurrentListTypeInUrl()
	const isReview = listType === EListType.inReview

	useEffect(() => {
		getCountCases({ listType: EListType.inReview, filters: { domain } }).then(
			(response) => setDomainCaseCount(response.data.count),
		)
	}, [domain, theCase.case_id])

	const setPreloadedImage = useCallback(async () => {
		const response = await getNextCase(theCase.case_id)
		if (response.isSuccessful && response.data === 200) {
			const newCase = createCase(response.data)
			preloadImage(getScreenshotUrl(newCase, 400))
			preloadImage(getOriginalImageUrl(newCase, 400))
		}
	}, [theCase.case_id])

	const setImageLoaded = (load: boolean) => {
		setLoaded(load)
		setPreloadedImage()
	}

	const handleGoToDomainCases = useCallback(() => {
		const pathname = getListUrlBasedOnListType({
			listType: EListType.inReview,
			filters: { domain },
			resetFilter: true,
		})

		router.history.push(pathname)
	}, [router.history, theCase])

	const renderedImageComponent = useMemo(
		() => (
			<ImageComponent
				className={classes.image}
				src={getOriginalImageUrl(theCase, 400)}
				onLoad={setImageLoaded}
			/>
		),
		[theCase.photo_id],
	)

	const renderedLeftPaper = useMemo(() => {
		if (!theCase.violation_id) return <></>
		return (
			<Paper
				classes={{ root: `${classes.paper} ${classes.imagePaper}` }}
				className={loaded ? classes.visible : classes.hidden}
			>
				<Typography
					variant="caption"
					align="center"
					className={`${classes.imageLabel} ${classes.originalImageLabel}`}
				>
					{t('inReview/originalImageLabel')}
				</Typography>
				{renderedImageComponent}

				<Row
					className={classes.infoWrapLeft}
					justify="flex-start"
					align="flex-start"
				>
					<Col style={{ marginRight: 32 }}>
						<Typography className={`${classes.textItem} ${classes.bold}`}>
							{t('inReview/image')}:
						</Typography>
						<Typography
							className={clsx(
								classes.textItem,
								classes.lightGrey,
								classes.semiBold,
							)}
						>
							{t('inReview/photographer')}:
						</Typography>
						{theCase.supplier ? (
							<Typography
								className={clsx(
									classes.textItem,
									classes.lightGrey,
									classes.semiBold,
								)}
							>
								{t('inReview/supplier')}
							</Typography>
						) : (
							<Fragment />
						)}
						<Typography
							className={clsx(
								classes.textItem,
								classes.lightGrey,
								classes.semiBold,
							)}
						>
							{t('inReview/uploaded')}:
						</Typography>
					</Col>
					<Col className={classes.noOverflow}>
						<Row justify="flex-start" className={classes.noOverflow}>
							<Link
								href={getArchiveImageUrl(theCase, 1024)}
								target="_blank"
								style={{ textDecoration: 'none' }}
								className={classes.truncate}
							>
								<Row className={classes.noOverflow}>
									<Typography
										className={clsx(
											classes.textItem,
											classes.bold,
											classes.truncate,
										)}
									>
										{theCase.photo_name}
									</Typography>
									<Icons.LinkIcon size="small" />
								</Row>
							</Link>
							<IconButton
								className={classes.iconButton}
								onClick={(e) => copyToClipboard(e, theCase.photo_name || '')}
							>
								<Icons.CopyIcon size="small" color={dark50} />
							</IconButton>
						</Row>
						<Typography className={clsx(classes.textItem)}>
							{theCase.photographer_name}
						</Typography>
						{theCase.supplier ? (
							<Typography className={clsx(classes.textItem)}>
								{theCase.supplier}
							</Typography>
						) : (
							<Fragment />
						)}
						<Typography className={classes.textItem}>
							{getFormatOccurredDate(theCase.reg_date)}
						</Typography>
					</Col>
				</Row>
			</Paper>
		)
	}, [theCase, classes, t, loaded])

	const renderedRightPaper = useMemo(() => {
		if (!theCase.violation_id) return <></>

		const extractedDomain = theCase.domain

		return (
			<Paper
				classes={{ root: `${classes.paper} ${classes.screenshotPaper}` }}
				className={loaded ? classes.visible : classes.hidden}
			>
				<Typography
					variant="caption"
					align="center"
					className={`${classes.imageLabel} ${classes.infringingImageLabel}`}
				>
					{t('inReview/infringementImageLabel')}
				</Typography>
				<div className={classes.relative}>
					<SideBySideMagnifier
						className={classes.image}
						imageSrc={getScreenshotUrl(theCase, 400)}
						alwaysInPlace
					/>
					{domainCaseCount > 1 && (
						<SecondaryButton
							className={classes.domainButton}
							text={t('inReview/casesOnDomain', { domainCaseCount })}
							onClick={handleGoToDomainCases}
						/>
					)}
				</div>
				<Col className={classes.infoWrapRight} justify="space-between">
					<Row align="flex-start" className={classes.infoRow}>
						<Col className={clsx(classes.infoCol, classes.noOverflow)}>
							<Typography className={`${classes.textItem} ${classes.bold}`}>
								{theCase.company_name}
							</Typography>
							<Typography className={clsx(classes.textItem, classes.lightGrey)}>
								{t('inReview/caseFoundAt')}
							</Typography>
							<a
								style={{ textDecoration: 'none' }}
								className={classes.truncate}
								rel="noopener noreferrer"
								href={theCase.url_raw}
								target="_blank"
							>
								<Typography
									className={clsx(
										classes.textItem,
										classes.semiBold,
										classes.truncate,
										classes.paddingRight,
									)}
								>
									{extractedDomain.length > 140
										? `${extractedDomain.slice(0, 140)}...`
										: extractedDomain}
								</Typography>
							</a>
						</Col>
						<OtherCasesByDomain theCase={theCase} />
					</Row>
					<Typography
						fontWeight={600}
						fontColor={dark50}
						style={{ marginBottom: 0 }}
					>
						{theCase.screenshot_timestamp && `${t('inReview/detected')}:`}&nbsp;
						{theCase.screenshot_timestamp}
					</Typography>
				</Col>
			</Paper>
		)
	}, [theCase, classes, t, loaded, domainCaseCount])

	return (
		<Row className={classes.container} align={'flex-start'}>
			{!theCase.violation_id ? (
				<CopyrightAgentLoader className={classes.absoluteCenter} />
			) : (
				<>
					{!loaded && (
						<CopyrightAgentLoader className={classes.absoluteCenter} />
					)}
					{renderedLeftPaper}
					{renderedRightPaper}
				</>
			)}
		</Row>
	)
}
