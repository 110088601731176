import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import WebFont from 'webfontloader'
import 'normalize.css/normalize.css'

import ScreenSizeNotSupported from 'components/ScreenSizeNotSupported'
import { StylesProvider, ThemeProvider } from '@material-ui/styles'
import initializeInterceptors from 'lib/http/interceptors'
import AppRouter from './routes'
import getStore from './store/store'
import theme from './lib/theme'
import './lib/locales'

initializeInterceptors()

WebFont.load({
	google: {
		families: ['Open Sans:400,400i,600,700'],
	},
})

const { store, persistor } = getStore()

const App: React.FC = () => (
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<ScreenSizeNotSupported />
					<AppRouter />
				</ThemeProvider>
			</StylesProvider>
		</PersistGate>
	</Provider>
)

export default App
