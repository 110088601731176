import { makeStyles, Theme } from '@material-ui/core'
import { red, green, dark50 } from 'lib/Colors'

export default makeStyles(
	(theme: Theme) => ({
		container: {
			flex: 3,
		},
		absoluteCenter: {
			position: 'absolute',
			top: 'calc(50% - 20px)',
			left: 'calc(50% - 20px)',
			zIndex: 2,
		},
		visible: {
			visibility: 'visible',
		},
		hidden: {
			visibility: 'hidden',
		},
		paper: {
			borderRadius: 6,
			position: 'relative',
		},
		imagePaper: {
			flex: 1,
			borderTopLeftRadius: 6,
			borderTopRightRadius: 0,
			borderBottomRightRadius: 6,
			overflow: 'hidden',
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
			[theme.breakpoints.down('lg')]: {
				width: theme.spacing(46),
			},
			[theme.breakpoints.down('md')]: {
				width: theme.spacing(41),
			},
		},
		screenshotPaper: {
			flex: 1.5,
			borderTopLeftRadius: 0,
			borderTopRightRadius: 6,
			overflow: 'hidden',
			boxShadow: '10px 10px 40px rgba(41, 50, 65, 0.1)',
			[theme.breakpoints.down('lg')]: {
				width: theme.spacing(62),
			},
			[theme.breakpoints.down('md')]: {
				width: theme.spacing(57),
			},
		},
		iconButton: {
			width: 20,
			height: 20,
			marginBottom: 4,
			padding: 0,

			'& svg': {
				'& path': {
					fill: 'black',
				},
			},
		},
		imageLabel: {
			position: 'absolute',
			top: 0,
			left: 'calc(50% - 57px)',
			backgroundColor: 'lightblue',
			zIndex: 1,
			padding: '4px 15px',
			borderBottomLeftRadius: 6,
			borderBottomRightRadius: 6,
			fontWeight: 600,
		},
		originalImageLabel: {
			backgroundColor: green,
			color: '#fff',
			textTransform: 'uppercase',
		},
		infringingImageLabel: {
			backgroundColor: red,
			color: '#fff',
			textTransform: 'uppercase',
		},
		infoWrapLeft: {
			padding: 35,
			[theme.breakpoints.down('md')]: {
				padding: 20,
			},
			height: 193,
			width: '100%',
		},
		infoWrapRight: {
			padding: 35,
			height: 205,
			width: '100%',
		},
		infoRow: {
			width: '100%',
		},
		infoCol: {
			flex: 1,
		},
		textItem: {
			marginBottom: 10,
			height: 20,
		},
		noOverflow: {
			overflow: 'hidden',
			width: '100%',
		},
		truncate: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			maxWidth: '100%',
		},
		paddingRight: {
			paddingRight: 16,
		},
		lightGrey: {
			color: dark50,
		},
		semiBold: {
			fontWeight: 600,
		},
		bold: {
			fontWeight: 700,
		},
		loading: {
			width: 150,
			height: 15,
			marginBottom: 10,
			borderRadius: 43,
			position: 'relative',
			overflow: 'hidden',
			backgroundColor: 'rgb(243, 243, 243)',
			color: '#EAECEC',
			'&::after': {
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				content: '""',
				position: 'absolute',
				animation: 'wawe 2s linear infinite',
				display: 'block',
				transform: 'translateX(-100%)',
				background:
					'linear-gradient(90deg, transparent, rgb(236, 235, 235), transparent)',
			},
		},
		byDomainPaper: {
			width: '100%',
			maxWidth: 320,
		},
		fullWidth: {
			width: '100%',
		},
		viewAllText: {
			fontSize: 10,
			lineHeight: '14px',
			color: dark50,
			height: 20,
			marginRight: 10,
		},
		arrowImage: {
			marginLeft: 4,
		},
		byDomainCaseId: {
			fontSize: 10,
			lineHeight: '14px',
			color: dark50,
		},
		marginBottom10: {
			marginBottom: 10,
		},
		link: {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'none',
			},
		},
		caseStatusStyle: {
			width: 15,
			height: 15,
			margin: '0 5px',
		},
		image: {
			width: '100%',
			height: 'auto',
			minHeight: 200,
			minWidth: '100%',
			position: 'relative',
			'&::before': {
				content: '""',
				display: 'block',
				position: 'absolute',
				width: '100%',
				zIndex: 200,
				height: 50,
				bottom: 0,
				background:
					'linear-gradient(180deg, rgba(41, 50, 65, 0) 0%, rgba(41, 50, 65, 0.15) 100%);',
			},
		},
		relative: {
			position: 'relative',
		},
		domainButton: {
			position: 'absolute',
			bottom: 16,
			right: 16,
			zIndex: 1000,
		},
	}),
	{ index: 1 },
)
