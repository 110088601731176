import React, { ReactElement, useState, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	Icons,
	Typography,
	PageContainer,
	DropdownSelect,
} from '@copyrightagent/basic-components'

import { SET_ACCOUNT_COUNTRY } from 'store/auth'
import { logout } from 'store/utils'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import trackEvent from 'hooks/useAnalytics'
import InfoBubble from 'components/InfoBubble'
import Footer from 'components/Footer'
import useStyle from './style'

const AccountTypePage = (): ReactElement => {
	const router = useRouter()
	const dispatch = useDispatch()
	const classes = useStyle()
	const { t } = useTranslation()
	const [nextDisabled, setNextDisabled] = useState(true)
	const [country, setCountry] = useState('')

	const availableCountries = ['se']
	const countries = [
		{
			label: 'Sweden',
			value: 'se',
		}
	]

	useEffect(() => {
		if (!country) return
		setNextDisabled(false)
		dispatch({ type: SET_ACCOUNT_COUNTRY, country })
	}, [country])

	const onClickNext = () => {
		trackEvent('Country selected', { date: new Date(), country })
		if (availableCountries.includes(country))
			return router.history.push(RouteUrls.terms)
		router.history.push(RouteUrls.notAvailable)
	}

	const onClickBack = () => {
		trackEvent('Back', {
			date: new Date(),
			page: router.history.location.pathname,
		})
		logout()
	}

	return (
		<Fragment>
			<Icons.Logo className={classes.logo} />
			<PageContainer className={classes.page}>
				<InfoBubble
					text={t('loginPage/earlyAccess')}
					className={classes.infoBubble}
				/>
				<Grid className={classes.title}>
					<Typography className={classes.titleText} variant="h5">
						{t('countrySelect/title')}
					</Typography>
					<Typography className={classes.titleSubText} variant="subtitle2">
						{t('countrySelect/subTitle')}
					</Typography>
				</Grid>
				<Grid className={classes.countrySelect}>
					<Typography className={classes.subtitleText} variant="h6">
						{t('countrySelect/subTitle2')}
					</Typography>
					<Typography className={classes.formTitle} variant="body2">
						{t('user/country')}
					</Typography>
					<DropdownSelect
						placeholder={t('createAccount/selectCountry')}
						options={countries}
						onSelect={setCountry}
					/>
				</Grid>
				<Footer
					nextLabel={t('countrySelect/next')}
					handleNext={onClickNext}
					nextDisabled={nextDisabled}
					previousLabel={t('countrySelect/back')}
					handlePrevious={onClickBack}
				></Footer>
			</PageContainer>
		</Fragment>
	)
}

export default AccountTypePage
