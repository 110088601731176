import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { dark50 } from 'lib/Colors'

const useStyle = makeStyles(
	(theme: Theme) => ({
		container: {
			width: 400,
			textAlign: 'center',
		},
		errorTitle: {
			fontWeight: 600,
			lineHeight: '24px',
			marginBottom: theme.spacing(2),
		},
		errorMessage: {
			color: dark50,
		},
		actionMessage: {
			fontWeight: 600,
			marginBottom: theme.spacing(2),
		},
		button: {
			padding: theme.spacing(1, 2),
			width: 'auto',
		},
		resetButton: {
			marginTop: 16,
		},
	}),
	{ index: 1 },
)

export default useStyle
