import axios from 'axios'
import i18n from 'i18next'
import { setSessionToken, toast, logout } from 'store/utils'

export default (): void => {
	const userCreateCodes = [
		'user_not_found',
		'not_authenticated',
		'token_expired',
	]

	const shouldLogout = (code: string): boolean =>
		!userCreateCodes.includes(code) &&
		window.location.href.indexOf('create-account') === -1

	axios.interceptors.response.use(
		(res) => res,
		(err) => {
			if (err.response.status === 401) {
				if (err.response.data.code === 'token_expired') {
					setSessionToken()
					toast(i18n.t('authentication/tokenExpired'), 'warning', 14000)
				}
				if (shouldLogout(err.response.data.code)) logout()
			}
			return Promise.reject(err)
		},
	)
}
