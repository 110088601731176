import { makeStyles } from '@material-ui/core'
import Color from 'color'
import { dark10, green, red } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		tableRow: {
			width: '100%',
			borderRadius: 6,
			border: `1px solid ${dark10}`,
			marginTop: 12,
			padding: 10,
		},
		icon: {
			width: 40,
			height: 40,
			borderRadius: 6,
			marginRight: 10,
			overflow: 'hidden',
			backgroundColor: dark10,
			objectFit: 'cover',
			cursor: 'pointer',
			'& img': {
				maxHeight: 150,
				minHeight: '100%',
			},
		},
		hidden: {
			display: 'none',
		},
		showImage: {
			display: 'block',
		},
		loading: {
			backgroundColor: Color(green).alpha(0.1).toString(),
			'& svg': {
				animation: '$rotating 2s linear infinite',
			},
		},
		'@keyframes rotating': {
			'0%': {
				transform: 'rotate(0deg)',
			},
			'100%': {
				transform: 'rotate(360deg)',
			},
		},
		error: {
			backgroundColor: Color(red).alpha(0.1).toString(),
		},
		nameRow: {
			width: '45%',
			'& p': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '80%',
			},
		},
		statusRow: {
			width: '30%',
		},
		menuRow: {
			marginLeft: 'auto',
		},
		imageDetails: {
			maxWidth: 'unset',
			width: '100%',
			margin: 0,
			padding: 0,
			maxHeight: 'unset',
		},
	},
	{ index: 1 },
)
