import {
	Checkbox,
	Icons,
	ImageComponent,
	InvisibleButton,
	Modal,
	ModalSection,
	PrimaryButton,
	Row,
} from '@copyrightagent/basic-components'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import clsx from 'clsx'
import trackEvent from 'hooks/useAnalytics'
import { dark75 } from 'lib/Colors'
import { ICase, ICaseType } from 'lib/entities/Case'
import { buildPhotoURL } from 'lib/http/ApiProvider'
import React, { ReactElement, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { checkMissingCases } from 'routes/Private/InReview/api'
import { useStyles } from './style'

type CheckedCase = Pick<
	ICase,
	| 'case_id'
	| 'photo_id'
	| 'violation_id'
	| 'violation_type'
	| 'url_raw'
	| 'domain'
>

interface MoreCasesModalProps {
	shouldCheckCases: boolean
	casesToCheck: {
		violation_id: string
		url_raw: string
		violation_type: ICaseType
		domain: string
	}[]
	multiple?: boolean
	onCancel: () => void | Promise<void>
	onValidate: (missingCases: string[]) => void | Promise<void>
	onAfterCheckCases?: (casesAreEmpty: boolean) => void | Promise<void>
}

export default function MoreCasesModal({
	shouldCheckCases,
	casesToCheck,
	multiple = false,
	onCancel,
	onValidate,
	onAfterCheckCases,
}: MoreCasesModalProps): ReactElement {
	const [hasCheckedMoreCases, setHasCheckedMoreCases] = useState<boolean>(false)
	const [caseCheckResponse, setCaseCheckResponse] = useState<
		Record<string, CheckedCase[]>
	>({})
	const [selectedCases, setSelectedCases] = useState<string[]>([])
	const [openedDomains, setOpenedDomains] = useState<Record<string, boolean>>(
		{},
	)

	const classes = useStyles()
	const { t } = useTranslation()

	const moreCasesAreEmpty = (obj: typeof caseCheckResponse): boolean =>
		Object.keys(obj).length === 0

	const flattenCases = (obj: typeof caseCheckResponse) =>
		Object.values(obj).reduce<string[]>(
			(acc, val) => [...acc, ...val.map((c) => c.violation_id)],
			[],
		)

	const toggleCheckCase = (violation_id: string) => {
		setSelectedCases((prev) => {
			const isSelected = prev.includes(violation_id)

			return !isSelected
				? [...prev, violation_id]
				: prev.filter((c) => c !== violation_id)
		})
	}

	async function onCheckMissingDomainCases(): Promise<void> {
		const response = await checkMissingCases(casesToCheck)
		setCaseCheckResponse(response.data)
		setSelectedCases(flattenCases(response.data))
		setHasCheckedMoreCases(true)
		await onAfterCheckCases?.(moreCasesAreEmpty(response.data))
	}

	useEffect(() => {
		if (shouldCheckCases && !hasCheckedMoreCases) onCheckMissingDomainCases()
	}, [shouldCheckCases, hasCheckedMoreCases])

	const mixpanelPayload = useMemo(
		() => ({
			caseType: casesToCheck[0].violation_type,
			casesOnDomain: flattenCases(caseCheckResponse).length,
			domain: casesToCheck[0].domain,
		}),
		[casesToCheck, caseCheckResponse],
	)

	const moreThanOneDomain = Object.keys(caseCheckResponse).length > 1

	return (
		<Modal
			title={t(`bulkReview/moreCases/title/${multiple ? 'plural' : 'single'}`)}
			titleText={t(
				`bulkReview/moreCases/titleText/${multiple ? 'plural' : 'single'}`,
			)}
			open={
				shouldCheckCases &&
				hasCheckedMoreCases &&
				!moreCasesAreEmpty(caseCheckResponse)
			}
			onClose={() => {
				setHasCheckedMoreCases(false)
				setCaseCheckResponse({})
				onCancel()
			}}
			size="small"
			disableScrollLock
			disableBackdropClick
		>
			<ModalSection>
				<div className={classes.missingDomainContainer}>
					{hasCheckedMoreCases &&
						Object.entries(caseCheckResponse).map(([domain, cases]) => (
							<div key={domain}>
								<Row
									className={classes.missingCaseDomain}
									justify="space-between"
								>
									<Typography
										className={clsx(classes.colorDark75, classes.caseRowText)}
									>
										{domain}
									</Typography>
									<div className={classes.flex}>
										<div className={classes.numOfCasesForDomain}>
											<Typography
												className={clsx(classes.caseRowText, classes.colorDark)}
											>
												{cases.length}
											</Typography>
										</div>
										{moreThanOneDomain && (
											<InvisibleButton
												className={classes.toggleAccordionButton}
												onClick={() => {
													trackEvent(
														`${
															openedDomains[domain] ? 'Closed' : 'Opened'
														} cases for domain`,
														{ domain, casesAmount: cases.length },
													)
													setOpenedDomains((prev) => ({
														...prev,
														[domain]: !prev[domain],
													}))
												}}
											>
												<Icons.ChevronIcon
													color={dark75}
													direction={!openedDomains[domain] ? 'right' : 'down'}
													className={classes.toggleAccordionIcon}
												/>
											</InvisibleButton>
										)}
									</div>
								</Row>
								<Collapse in={moreThanOneDomain ? openedDomains[domain] : true}>
									<div className={classes.caseRowContainer}>
										{cases.map((c) => (
											<div
												key={c.violation_id}
												className={classes.caseRow}
												onClick={() => toggleCheckCase(c.violation_id)}
											>
												<ImageComponent
													className={classes.caseRowImage}
													src={buildPhotoURL(c.photo_id, 32)}
												/>
												<Typography
													className={clsx(
														classes.colorDark75,
														classes.caseRowText,
													)}
												>
													{c.case_id}
												</Typography>
												<div
													className={clsx(
														classes.caseRowCaseType,
														c.violation_type === 'Commercial' && classes.bgBlue,
														c.violation_type === 'Editorial' &&
															classes.bgPurple,
													)}
												>
													<Typography
														className={clsx(
															classes.colorWhite,
															classes.caseRowText,
														)}
													>
														{c.violation_type.charAt(0).toUpperCase()}
													</Typography>
												</div>
												<IconButton
													size="small"
													onClick={(e) => {
														e.stopPropagation()
														window.open(c.url_raw, '_blank')
													}}
												>
													<Icons.LinkIcon external color={dark75} />
												</IconButton>

												<div className={classes.checkbox}>
													<Checkbox
														checked={selectedCases.includes(c.violation_id)}
													/>
												</div>
											</div>
										))}
									</div>
								</Collapse>
							</div>
						))}
				</div>
			</ModalSection>
			<ModalSection>
				<Row justify="flex-end">
					<InvisibleButton
						text={t('bulkReview/moreCases/cancelValidate')}
						onClick={() => {
							setHasCheckedMoreCases(false)
							setCaseCheckResponse({})
							trackEvent(
								'No, do not validate other cases on domain',
								mixpanelPayload,
							)
							onCancel()
						}}
						className={classes.doNotValidateBtn}
					/>
					<PrimaryButton
						text={t('bulkReview/moreCases/confirmValidate')}
						onClick={async () => {
							await onValidate(selectedCases)
							trackEvent('Validate other cases on domain', {
								...mixpanelPayload,
								casesAmount: selectedCases.length,
							})
							setHasCheckedMoreCases(false)
							setCaseCheckResponse({})
						}}
					/>
				</Row>
			</ModalSection>
		</Modal>
	)
}
