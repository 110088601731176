import React, {
	Fragment,
	ReactElement,
	useCallback,
	useEffect,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
	Typography,
	Row,
	PrimaryButton,
	Col,
	CopyrightAgentLoader,
} from '@copyrightagent/basic-components'
import { Paper } from '@material-ui/core'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { getImagesActions, useWorkspace } from 'hooks/useWorkspace'
import { dark50 } from 'lib/Colors'
import InfiniteScroll from 'react-infinite-scroll-component'
import trackEvent from 'hooks/useAnalytics'
import { RouteComponentProps, StaticContext, useParams } from 'react-router'
import { useStyle } from './style'
import UploadModal from './UploadModal'
import Uploader from './Uploader'
import ImageRow from './ImageRow'
import { getImagesCount } from './api'
import PageWithNavigation from '../HomeLayout/PageWithNavigation'

export interface ImageProps {
	name: string
	uploaded: string
	id: string
	size?: number
	src?: string
}
interface ImageStoredProps {
	name: string
	id: string
	uploaded: string
}

type Params = ReturnType<typeof useParams>

type ImagesProps = RouteComponentProps<
	{ [K in keyof Params]?: string },
	StaticContext,
	{ getStarted?: boolean }
>

const Images = (props: ImagesProps): ReactElement => {
	const { t } = useTranslation()
	const classes = useStyle()
	const [isUploading, setIsUploading] = useState<boolean>(false)
	const { currentlyUploading, uploadQueue } = useWorkspace()
	const [storedImages, setStoredImages] = useState<ImageStoredProps[]>([])
	const [modalOpen, setModalOpen] = useState(false)
	const [hasMore, setHasMore] = useState<boolean>(false)
	const [page, setPage] = useState<number>(0)
	const [loaded, setLoaded] = useState<boolean>(false)
	const perPage = 30
	const [totalCount, setTotalCount] = useState<number>(perPage)
	const { fetchImages } = getImagesActions()

	useSetPageTitle(t('images'))

	const getTotalCount = async () => {
		const result = await getImagesCount()
		if (result.isSuccessful) setTotalCount(result.data.count)
	}
	useEffect(() => {
		if (!props.location.state) return
		if (props.location.state.getStarted) setModalOpen(true)
	}, [props.location])

	const getImages = useCallback(async () => {
		setHasMore(false)
		const images = await fetchImages(page, perPage)
		if (!loaded) setLoaded(true)
		setStoredImages((prevState) => [...prevState, ...images])
		if (storedImages.length < totalCount) {
			setHasMore(true)
			setPage((prevPage) => prevPage + 1)
		}
	}, [page])

	useEffect(() => {
		getTotalCount()
		getImages()
	}, [])

	return (
		<PageWithNavigation title={t('images')}>
			<Paper
				classes={{
					root: classes.paper,
				}}
			>
				<Row justify="space-between" className={classes.paperThin}>
					<Col>
						<Typography variant="h4">{t('images/uploadedImages')}</Typography>
						<Typography variant="body2" fontColor={dark50}>
							{t('images/uploadedImages/description')}
						</Typography>
					</Col>
					<PrimaryButton
						type="button"
						text={t('images/uploadImages')}
						onClick={() => {
							setModalOpen(true)
							trackEvent('Started upload', {
								date: new Date(),
							})
						}}
						disabled={uploadQueue.length > 0}
					/>
				</Row>
				<Col className={classes.folders} justify="flex-start" align="stretch">
					<Row className={classes.head} justify="flex-start">
						<Row className={classes.nameRow} justify="flex-start">
							<Typography variant="body2">{t('images/fileName')}</Typography>
						</Row>
						<Row className={classes.statusRow} justify="flex-start">
							<Typography variant="body2">{t('images/uploaded')}</Typography>
						</Row>
					</Row>
					{!loaded ? (
						<Row className={classes.spinner}>
							<CopyrightAgentLoader />
						</Row>
					) : (
						<></>
					)}
					<Fragment>
						{loaded &&
							currentlyUploading.map((image) => {
								if (
									image.errorMessage ||
									!storedImages.find((item) => item.name === image.name)
								) {
									return (
										<ImageRow
											key={image.name}
											id={image.name}
											uploadingImage={image}
										/>
									)
								}
								return <></>
							})}
					</Fragment>
					<Fragment>
						{loaded && storedImages ? (
							<InfiniteScroll
								className={classes.infiniteScrollWrap}
								dataLength={storedImages.length}
								next={getImages}
								hasMore={hasMore}
								loader={
									storedImages.length ? (
										<div key={0} className={classes.loadMoreWrap}>
											<CopyrightAgentLoader />
										</div>
									) : (
										<></>
									)
								}
							>
								{storedImages.map((image) => (
									<ImageRow
										key={image.id}
										id={image.id}
										imageDownloaded={{
											id: image.id,
											name: image.name,
											uploaded: image.uploaded,
										}}
									/>
								))}
							</InfiniteScroll>
						) : (
							<></>
						)}
					</Fragment>
					{loaded && !currentlyUploading.length && !storedImages.length ? (
						<Col
							justify="center"
							align="center"
							className={classes.noImagesUploaded}
						>
							<Typography variant="h5">{t('images/noImages')}</Typography>
							<Typography variant="body2">
								{t('images/noImages/desc')}
							</Typography>
							<PrimaryButton
								type="button"
								text={t('images/uploadImages')}
								onClick={() => {
									setModalOpen(true)
									trackEvent('Started upload', {
										date: new Date(),
									})
								}}
							/>
						</Col>
					) : (
						<></>
					)}
				</Col>
				{isUploading ? (
					<Uploader closeUploader={() => setIsUploading(false)} />
				) : (
					<></>
				)}
			</Paper>
			<UploadModal
				opened={modalOpen}
				setDialogOpened={setModalOpen}
				setForUpload={() => setIsUploading(true)}
			/>
		</PageWithNavigation>
	)
}

export default Images
