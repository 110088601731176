import { Row } from '@copyrightagent/basic-components'
import clsx from 'clsx'
import React from 'react'
import { useStyle } from './style'

export default function LoadingRow({ style }: any): JSX.Element {
	const classes = useStyle()
	return (
		<Row className={classes.root} style={style}>
			<LoadingRowColumns />
		</Row>
	)
}
export function LoadingRowColumns(): JSX.Element {
	const classes = useStyle()
	return (
		<Row className={classes.caseRows}>
			<div
				className={clsx(classes.imagePlaceholder, classes.placeholder)}
			></div>
			<Row style={{ flex: 1 }} className={classes.caseRowColumn} align="start">
				<Row>
					<div
						className={clsx(
							classes.longPlaceholder,
							classes.placeholder,
							classes.delayImage,
						)}
					></div>
					<div
						className={clsx(
							classes.smallPlaceholder,
							classes.placeholder,
							classes.delayLong,
						)}
					></div>
					<div
						className={clsx(classes.mediumPlaceholder, classes.placeholder)}
					></div>
				</Row>
			</Row>
			<Row
				style={{ flex: 1 }}
				className={classes.caseRow}
				align="center"
				justify="flex-start"
			>
				<div
					className={clsx(
						classes.smallPlaceholder,
						classes.placeholder,
						classes.delayMedium,
					)}
				></div>
				<div
					className={clsx(classes.longPlaceholder, classes.placeholder)}
				></div>
				<div
					className={clsx(
						classes.mediumPlaceholder,
						classes.placeholder,
						classes.delayLong,
					)}
				></div>
			</Row>
			<Row style={{ flex: 1 }} className={classes.caseRowColumn} align="start">
				<div
					className={clsx(classes.longPlaceholder, classes.placeholder)}
				></div>
			</Row>
			<Row
				style={{ flex: 1 }}
				className={classes.caseRowLastColumn}
				align="start"
			>
				<div
					className={clsx(classes.longPlaceholder, classes.placeholder)}
				></div>
			</Row>
		</Row>
	)
}
