import React, { useMemo, Fragment, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Row, Typography } from '@copyrightagent/basic-components'
import CaseStatus from 'components/CaseStatus'
import CaseLocation from 'components/CaseLocation'
import { useCase } from 'hooks/useCase'
import CaseType from 'components/CaseTypeLabel'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import { useStyle } from './style'

export default function CasePageTitle(): ReactElement {
	const { theCase, loaded, errorMessage } = useCase()
	const { t } = useTranslation()
	const classes = useStyle()
	const { awaitingReview, onHold } = useCaseStatistics()

	const renderContent = useMemo(() => {
		if (errorMessage) {
			return (
				<Typography variant="h5" fontWeight={600}>
					{t('inReview/pageTitleNotFound')}
				</Typography>
			)
		}

		if (!awaitingReview.count && !onHold.count) {
			return (
				<Typography variant="h5" fontWeight={600}>
					{t('inReview/pageTitle')}
				</Typography>
			)
		}

		return (
			<Row className={classes.titleWrapper}>
				<Typography variant="h5" className={classes.title}>
					{theCase.case_id ? '' : t('inReview/pageTitle')}
				</Typography>
				<Row
					className={clsx(
						classes.dynamicContentWrap,
						!loaded && classes.notLoaded,
					)}
				>
					{loaded ? (
						<Fragment>
							<Typography
								variant="h5"
								fontWeight={600}
								className={classes.caseName}
							>
								{theCase.case_id}
							</Typography>
							<CaseStatus className={classes.caseStatus} theCase={theCase} />
							<CaseLocation
								theCase={theCase}
								className={classes.caseLocation}
							/>
							<CaseType theCase={theCase} />
						</Fragment>
					) : (
						<Typography variant="h5" fontWeight={600}>
							{theCase.case_id}
						</Typography>
					)}
				</Row>
			</Row>
		)
	}, [t, theCase, classes, loaded, errorMessage, onHold.count, awaitingReview])

	return (
		<Fragment>
			<Row>{renderContent}</Row>
		</Fragment>
	)
}
