import { Button } from '@material-ui/core'
import React from 'react'
import { useStyle } from './style'

export type PanelSwitchButtonType = {
	text: string
	selected: boolean
	onClick: () => void
}

interface PanelSwitchButtonProps {
	buttons: Array<PanelSwitchButtonType>
}

export default function PanelSwitchButton(
	props: PanelSwitchButtonProps,
): JSX.Element {
	const { buttons } = props

	const classes = useStyle()

	return (
		<div className={classes.switchModeWrapper}>
			{buttons.map(({ text, selected, onClick }, index) => (
				<Button
					key={index}
					className={
						!selected
							? classes.switchModeButton
							: `${classes.switchModeButton} ${classes.switchModeButtonSeleted}`
					}
					onClick={onClick}
				>
					{text}
				</Button>
			))}
		</div>
	)
}
