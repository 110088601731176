import {
	ExtraActionButton,
	ExtraActionOffsetButton,
	dangerButtonStyle,
} from '@copyrightagent/basic-components'
import { ButtonProps } from '@material-ui/core'
import { getCaseActions } from 'hooks/useCase'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import { ICase, updateCase } from 'lib/entities/Case'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { putCaseOnHold, removeCaseFromHold } from 'routes/Private/InReview/api'
import useStyle from './style'

interface OnHoldButtonProps extends ButtonProps {
	theCase: ICase
	onPutOnHold: () => void
}

export default function OnHoldButton(props: OnHoldButtonProps): ReactElement {
	const { theCase, onPutOnHold } = props
	const [loading, setLoading] = useState<boolean>(false)
	const { replaceCase } = getCaseActions()
	const classes = useStyle()
	const { t } = useTranslation()
	const { onHold } = useCaseStatistics()

	async function handlePutOnHold() {
		setLoading(true)
		const response = await putCaseOnHold(theCase.violation_id)
		if (response.isSuccessful) {
			replaceCase(updateCase(theCase, { on_hold: true }))
			setLoading(false)
			onPutOnHold()
		}
	}

	async function handleRemoveFromHold() {
		setLoading(true)
		const response = await removeCaseFromHold(theCase.violation_id)
		if (response.isSuccessful) {
			replaceCase(updateCase(theCase, { on_hold: false }))
			setLoading(false)
		}
	}

	return theCase.on_hold ? (
		<ExtraActionOffsetButton
			type="button"
			loading={loading}
			text={t('putOnHold/casesOnHold')}
			hoverText={t('putOnHold/removeFromHold')}
			className={`${classes.button} ${classes.mainButton}`}
			buttonStyle={{
				hoverCSS: dangerButtonStyle.defaultCSS,
			}}
			onClick={handleRemoveFromHold}
		/>
	) : (
		<ExtraActionButton
			type="button"
			loading={!onHold.loaded}
			text={
				onHold.count ? `${t('putOnHold')} (${onHold.count})` : t('putOnHold')
			}
			loadingText={t('putOnHold/processing')}
			onClick={handlePutOnHold}
			className={`${classes.button} ${classes.mainButton}`}
		/>
	)
}
