import { markCaseCommercial, markCaseEditorial } from 'routes/Private/Cases/api'
import { ICase, updateCase } from 'lib/entities/Case'
import { getCaseActions } from 'hooks/useCase'

const { replaceCase } = getCaseActions()

export const handleChangeCase = (
	theCase: ICase,
	key: string,
	value: string,
): void => {
	const newCase = updateCase(theCase, { [key]: value })

	if (key === 'violation_type' && value === 'Commercial')
		markCaseCommercial(theCase.violation_id)
	if (key === 'violation_type' && value === 'Editorial')
		markCaseEditorial(theCase.violation_id)

	replaceCase(newCase)
}

export const updateCaseInfringement = (
	theCase: ICase,
	updates: Partial<ICase>,
): void => {
	const newCase = updateCase(theCase, updates)
	replaceCase(newCase)
}
