import {
	Typography,
	TextArea,
	PrimaryButton,
	ModalSection,
	Row,
} from '@copyrightagent/basic-components'
import { Button } from '@material-ui/core'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyle } from '../style'

interface RenamingFileStepProps {
	handleClose: () => void
	file: File | undefined
	setUpdatedFileName: (newName: string) => Promise<void>
	isPending: boolean
}

const RenamingFileStep = (props: RenamingFileStepProps): ReactElement => {
	const { handleClose, file, setUpdatedFileName, isPending } = props
	const classes = useStyle()
	const { t } = useTranslation()
	const [name, setName] = useState<string>('')
	const [validName, setValidName] = useState<boolean>(false)
	const [ext, setExt] = useState<string>('')

	useEffect(() => {
		if (file?.name) {
			const fileNameParts = file.name.split('.')
			setName(fileNameParts[0])
			setExt(fileNameParts[1])
		}
	}, [file])

	const imageNameValidator = /[\w-]+$/
	const handleInput = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { value } = e.target
		setValidName(false)
		if (imageNameValidator.test(value) && value !== file?.name)
			setValidName(true)
		setName(value)
	}

	const handleCancel = () => {
		setName('')
		setValidName(false)
		setExt('')
		handleClose()
	}
	const handleUpdate = () => {
		setUpdatedFileName(`${name}.${ext}`)
	}
	return (
		<>
			<ModalSection>
				<TextArea
					value={name}
					type="text"
					label=""
					variant="filled"
					className={classes.input}
					onChange={handleInput}
				/>
			</ModalSection>
			<ModalSection>
				<Row justify="flex-end">
					<Button className={classes.invisibleButton} onClick={handleCancel}>
						<Typography>{t('back')}</Typography>
					</Button>
					<PrimaryButton
						type="button"
						loading={isPending}
						loadingText={t('images/uploading')}
						text={t('done')}
						onClick={handleUpdate}
						disabled={!validName || name === file?.name}
					/>
				</Row>
			</ModalSection>
		</>
	)
}
export default RenamingFileStep
