const green = '#48D2A0'
const dark75 = '#65706C'
const dangerColor = '#BC2525'
const red = '#CE505E'
const red100 = '#EB5952'
const red10 = '#FAEDEF'
const yellow = '#F8C51C'
const orange = '#F8A01C'
const dark50 = '#8E939B'
const dark5 = '#F4F5F5'
const dark25 = '#C9CCCF'
const dark10 = '#E9EAEC'
const dark = '#293833'
const blue = '#2AA7DC'
const blue10 = '#EAF6FB'
const purple = '#ba5bb7'
const greenGradient = 'linear-gradient(135deg, #47D19F 0%, #167E58 100%)'
const redGradient = 'linear-gradient(135deg, #BC2525 0%, #470000 100%)'
const yellowGradient = 'linear-gradient(135deg, #DFC249 0%, #C67800 100%)'
const shadowGreenColor = 'rgba(72, 210, 160, 0.25)'

export {
	green,
	dark,
	dark5,
	dark10,
	dark25,
	dark50,
	greenGradient,
	yellowGradient,
	redGradient,
	dangerColor,
	dark75,
	red,
	red10,
	red100, 
	yellow,
	orange,
	blue,
	blue10,
	purple,
	shadowGreenColor,
}
