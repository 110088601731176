import { useCallback, useState } from 'react'

export default function useSelect() {
	const [selected, setSelected] = useState<string[]>([])

	const toggleSelectAll = useCallback(
		(checked: boolean, items: (oldItems: string[]) => string[]) => {
			if (!checked) return setSelected([])
			setSelected(items(selected))
		},
		[setSelected, selected],
	)

	const addToSelected = useCallback(
		(item: string) => {
			setSelected((prevState) => [...prevState, item])
		},
		[setSelected],
	)

	const removeFromSelected = useCallback(
		(item: string) => {
			setSelected((prevState) => prevState.filter((el) => el !== item))
		},
		[setSelected],
	)

	const toggleSelect = useCallback(
		(item: string) => {
			if (selected.includes(item)) removeFromSelected(item)
			else addToSelected(item)
		},
		[selected, removeFromSelected, addToSelected],
	)

	return {
		addToSelected,
		removeFromSelected,
		selected,
		toggleSelectAll,
		toggleSelect,
	}
}
