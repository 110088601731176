import {
	Icons,
	Row,
	Typography,
} from '@copyrightagent/basic-components'
import { Button, Dialog } from '@material-ui/core'
import clsx from 'clsx'
import { red } from 'lib/Colors'
import { buildCollectionImageURL } from 'lib/http/ApiProvider'
import { getDateTime } from 'lib/utils'
import React, {
	Fragment,
	ReactElement,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IUploadingImage } from 'store/workspace'
import { ImageProps } from '..'
import ImageDetails from '../ImageDetails'
import { useStyle } from './style'

interface ImageRowProps {
	id: string
	imageDownloaded?: ImageProps
	uploadingImage?: IUploadingImage
}

const ImageRow = (props: ImageRowProps): ReactElement => {
	const { id, uploadingImage, imageDownloaded } = props

	const [status, setStatus] = useState<string>('')
	const [image, setImage] = useState<ImageProps>({
		id: '',
		name: '',
		uploaded: '',
		src: '',
		size: 0,
	})
	const [imageLoaded, setImageLoaded] = useState<boolean>(false)
	const [loaded, setLoaded] = useState<boolean>(true)
	const [error, setError] = useState<boolean>(false)
	const [showDetails, setShowDetails] = useState<boolean>(false)
	const { t } = useTranslation()

	const setImageSrc = (imageId: string) => {
		setImage((prevState) => ({
			...prevState,
			src: buildCollectionImageURL(imageId),
		}))
	}

	useEffect(() => {
		if (!uploadingImage) return
		const { name, src, size, errorMessage } = uploadingImage
		setImage((prevState) => ({ ...prevState, name }))
		setLoaded(uploadingImage.loaded)
		if (errorMessage) {
			setError(true)
			setStatus(t(`images/${errorMessage}`))
			return
		}
		const uploadDate = new Date().toString()
		setImage((prevState) => ({
			...prevState,
			...{
				name,
				src,
				size,
				uploaded: uploadDate,
			},
		}))
		setStatus(getDateTime(uploadDate))
	}, [uploadingImage?.src, uploadingImage?.errorMessage])

	useEffect(() => {
		if (!loaded) setStatus(`${t('images/uploading')}...`)
	}, [loaded])

	useEffect(() => {
		if (!imageDownloaded) return
		setImage(imageDownloaded)
		if (!image.src) setImageSrc(imageDownloaded.id)
		setStatus(getDateTime(imageDownloaded.uploaded))
	}, [imageDownloaded?.id])

	const classes = useStyle()

	const renderIcon = useMemo(() => {
		if (error) return <Icons.CloseIcon color={red} />
		return <Icons.SpinnerIcon />
	}, [loaded, error])

	const renderImage = useMemo(() => {
		if (image.src) {
			return (
				<img
					src={image.src}
					alt={image.name}
					onLoad={() => {
						setImageLoaded(true)
					}}
					className={imageLoaded ? classes.showImage : classes.hidden}
					onClick={() => setShowDetails(true)}
				/>
			)
		}
		if (!error) return <Icons.SpinnerIcon />
		return <Icons.CloseIcon color={red} />
	}, [image.src, error, imageLoaded])

	return (
		<Row className={classes.tableRow} justify="flex-start">
			<Row className={classes.nameRow} justify="flex-start">
				<Row
					className={clsx(
						classes.icon,
						!loaded && !error && !imageLoaded ? classes.loading : '',
						image.id && !error && !imageLoaded ? classes.loading : '',
						error ? classes.error : '',
					)}
				>
					{image.src ? renderImage : renderIcon}
				</Row>
				<Typography variant="body2">{image.name}</Typography>
			</Row>
			<Row className={classes.statusRow} justify="flex-start">
				<Typography variant="body2">{status}</Typography>
			</Row>
			<Row className={classes.menuRow}>{error ? <Button /> : <Fragment />}</Row>
			<Dialog
				classes={{
					container: classes.imageDetails,
					paper: classes.imageDetails,
				}}
				open={showDetails}
			>
				<ImageDetails
					closeDetails={() => setShowDetails(false)}
					image={image}
				/>
			</Dialog>
		</Row>
	)
}

export default ImageRow
