import React, { useState, ReactElement, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, PrimaryButton } from '@copyrightagent/basic-components'
import CreatedCaseList from 'components/CreatedCaseList'
import useSetPageTitle from 'hooks/useSetPageTitle'
import CreateCaseModal from 'components/CreateCaseModal/CreateCaseModal'
import { getCaseActions, useCase } from 'hooks/useCase'
import trackEvent from 'hooks/useAnalytics'
import { RouteComponentProps, StaticContext, useParams } from 'react-router'
import { getSubmittedCasesCount } from './api'
import PageWithNavigation from '../HomeLayout/PageWithNavigation'

type Params = ReturnType<typeof useParams>

type CreateCaseProps = RouteComponentProps<
	{ [K in keyof Params]?: string },
	StaticContext,
	{ getStarted?: boolean }
>

const CreateCase = (props: CreateCaseProps): ReactElement => {
	const { t } = useTranslation()
	const [loaded, setLoaded] = useState<boolean>(false)
	const [showModal, setShowModal] = useState(false)
	const [hasMore, setHasMore] = useState<boolean>(false)
	const [page, setPage] = useState<number>(0)
	const perPage = 30
	const { submittedCases } = useCase()
	const { fetchSubmittedCases } = getCaseActions()
	const [totalCount, setTotalCount] = useState<number>(perPage)

	const getTotalCount = async () => {
		const result = await getSubmittedCasesCount()
		setTotalCount(result.data.count)
	}
	useEffect(() => {
		if (!props.location.state) return
		if (props.location.state.getStarted) setShowModal(true)
	}, [props.location])

	const getCases = useCallback(async () => {
		setHasMore(false)
		await fetchSubmittedCases(page, perPage)
		if (!loaded) setLoaded(true)
		if (submittedCases.length < totalCount) {
			setHasMore(true)
			setPage((prevPage) => prevPage + 1)
		}
	}, [submittedCases, page])

	useEffect(() => {
		getTotalCount()
		getCases()
	}, [])

	useSetPageTitle(t('createCase/pageTitle'))
	return (
		<PageWithNavigation title={t('createCase/pageTitle')}>
			<Row justify="flex-end">
				<PrimaryButton
					type="button"
					text={t('createCase/pageTitle')}
					onClick={() => {
						setShowModal(true)
						trackEvent('Start submit case', {
							date: new Date(),
						})
					}}
				/>
			</Row>
			<CreatedCaseList
				cases={submittedCases}
				loadNextCases={getCases}
				hasMore={hasMore}
				createCase={() => {
					setShowModal(true)
					trackEvent('Start submit case', {
						date: new Date(),
					})
				}}
				loaded={loaded}
			/>
			<CreateCaseModal opened={showModal} setDialogOpened={setShowModal} />
		</PageWithNavigation>
	)
}
export default CreateCase
