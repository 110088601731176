import React, { useMemo, ReactElement, useEffect } from 'react'
import { Row } from '@copyrightagent/basic-components'
import ImageAndScreenshot from 'components/ImageAndScreenshot'
import { useRouter } from 'routes/CustomBrowserRouter'
import { useCase } from 'hooks/useCase'
import { RouteUrls } from 'routes/RouteUrls'

export default function CaseToReview(): ReactElement {
	const { theCase } = useCase()
	const router = useRouter()

	useEffect(() => {
		if (theCase.customer_approve_time)
			return router.history.push(`${RouteUrls.case}/${theCase.case_id}`)
	}, [theCase.customer_approve_time])

	const renderContent = useMemo(
		() => theCase.case_id && <ImageAndScreenshot theCase={theCase} />,
		[theCase],
	)

	return (
		<Row align="stretch" justify="flex-start" style={{ flex: 1 }}>
			{renderContent}
		</Row>
	)
}
