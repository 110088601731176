import { useEffect } from 'react'

export default function useSetPageTitle(
	pageName?: string,
	caseId?: string,
): void {
	useEffect(() => {
		if (caseId) document.title = `Copyright Agent - ${caseId}`

		if (!caseId && pageName) document.title = `Copyright Agent - ${pageName}`

		if (!caseId && !pageName) document.title = 'Copyright Agent'
	}, [pageName, caseId])
}
