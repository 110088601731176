import { getSelectedClient } from 'store/utils'
import { makeInternalAPIRequest } from 'lib/http/ApiProvider'
import Response from 'lib/http/Response'
import moment from 'moment'

export function getDashboardApi(): Promise<Response> {
	const client = getSelectedClient()
	return makeInternalAPIRequest('get', `/dashboard/${client.client_id}`, {
		timezone: moment.tz.guess(),
	})
}
