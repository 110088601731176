import {
	Row,
	Icons,
	Typography,
	ModalSection,
} from '@copyrightagent/basic-components'
import { Button } from '@material-ui/core'
import trackEvent from 'hooks/useAnalytics'
import { setUploadQueue } from 'hooks/useWorkspace'
import { blue } from 'lib/Colors'
import React, { ReactElement, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useStyle } from '../style'

interface UploadImagesProps {
	handleClose(): void
	setForUpload(): void
}
const UploadImages = (props: UploadImagesProps): ReactElement => {
	const { handleClose, setForUpload } = props
	const classes = useStyle()
	const [files, setFiles] = useState<File[]>([])
	const { t } = useTranslation()

	const onDrop = useCallback((acceptedFiles) => {
		setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg, image/png',
		onDrop,
	})

	const handleSubmit = () => {
		setForUpload()
		setFiles([])
		setUploadQueue(files)
		handleClose()
	}

	const handleCancel = () => {
		setFiles([])
		handleClose()
	}

	return (
		<>
			<ModalSection>
				<Row>
					<div {...getRootProps({ className: classes.dropzone })}>
						{files.length ? (
							<Icons.CloseIcon
								color={blue}
								className={classes.discardChoice}
								onClick={() => setFiles([])}
							/>
						) : undefined}
						<input {...getInputProps()} />
						<Icons.UploadIcon color={blue} />
						{files.length ? (
							<Typography variant="body2">
								{files.length} {t('images/imagesSelected')}
							</Typography>
						) : (
							<>
								<Typography variant="body2">{t('images/dropFiles')}</Typography>
								<Typography variant="body2">
									{t('images/imageTypes')}
								</Typography>
							</>
						)}
					</div>
				</Row>
			</ModalSection>
			<ModalSection>
				<Row justify="flex-end">
					<Button className={classes.invisibleButton} onClick={handleCancel}>
						<Typography>{t('cancel')}</Typography>
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							handleSubmit()
							trackEvent('Upload images', {
								date: new Date(),
								image_amount: files.length,
							})
						}}
						disabled={!files.length}
					>
						{t('images/upload')}
					</Button>
				</Row>
			</ModalSection>
		</>
	)
}
export default UploadImages
