import { makeStyles } from '@material-ui/core'
import { dark10, dark75 } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		paper: {
			padding: 44,
			borderRadius: 6,
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
		},
		actions: {
			borderTop: `1px solid ${dark10}`,
			marginTop: 24,
			paddingTop: 24,
		},
		action: {
			width: 400,
			borderRadius: 6,
			border: `1px solid ${dark10}`,
			padding: 24,
			marginRight: 24,
			'& p': {
				marginTop: 12,
			},
		},
		actionTitle: {
			fontSize: 20,
		},
		actionImage: {
			backgroundColor: 'transparent',
			margin: 'auto',
			marginBottom: 24,
			width: 300,
		},
		actionLink: {
			backgroundColor: dark75,
			borderRadius: 6,
			padding: 10,
			width: '100%',
			marginTop: 28,
			textAlign: 'center',
			textDecoration: 'none',
			'& p': {
				color: 'white',
				fontSize: 14,
				marginTop: 0,
			},
		},
	},
	{ index: 1 },
)
