import React, {
	Fragment,
	ReactElement,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Row, TextLabel } from '@copyrightagent/basic-components'
import { useCase } from 'hooks/useCase'
import { filtersIsSet } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getListUrlBasedOnListType } from 'routes/RouteUrls'
import { getCaseCountForList } from '../Cases/api'

export default function CasePageTitle(): ReactElement {
	const [caseCount, setCaseCount] = useState<number | undefined>(undefined)

	const { t } = useTranslation()
	const { theCase } = useCase()
	const router = useRouter()

	const filterIsSet = filtersIsSet()

	useEffect(() => {
		getCaseCountForList().then((count) => setCaseCount(count))
	}, [])

	const labelText = useMemo(() => {
		if (caseCount === 0) return t('inReview/casesCount/zero')
		if (caseCount === 1) return t('inReview/casesCount/single')
		if (caseCount) return t('inReview/casesCount/plural', { count: caseCount })
		return t('inReview/casesCountLoading')
	}, [caseCount])

	return (
		<Fragment>
			{theCase && (
				<Row>
					{filterIsSet ? (
						<TextLabel
							size="thick"
							color="yellow"
							labelText={t('inReview/activeFilter/pageInfo')}
						/>
					) : (
						<></>
					)}
					<TextLabel
						size="thick"
						color="white"
						labelText={labelText}
						onClick={() => router.history.push(getListUrlBasedOnListType())}
					/>
				</Row>
			)}
		</Fragment>
	)
}
