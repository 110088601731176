import React, { Fragment, ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { Link, Typography } from '@material-ui/core'
import { Icons, PageContainer, Row } from '@copyrightagent/basic-components'
import { NewLogin } from '@copyrightagent/login-component'

import trackEvent from 'hooks/useAnalytics'
import { getSessionToken } from 'store/utils'
import { attemptLogin } from './firebaseConfig'
import useStyle from './style'

const { REACT_APP_API_URL: API_URL } = process.env

// eslint-disable-next-line @typescript-eslint/no-var-requires
const translations = require('./login.json')

const LoginPage = (): ReactElement => {
	const classes = useStyle()

	return (
		<Fragment>
			<Icons.Logo className={classes.logo} />
			<PageContainer className={classes.page}>
				<NewLogin
					login={attemptLogin}
					token={getSessionToken()}
					api={`${API_URL}/api`}
					translations={translations}
					tracking={trackEvent}
				/>
				<Row className={classes.bottom}>
					<Typography className={classes.terms}>
						<Trans
							components={[
								<Link
									key="terms"
									className={classes.termsLink}
									href="https://copyrightagent.com/legal/terms/"
									target="_blank"
								/>,
								<Link
									key="privacy"
									className={classes.termsLink}
									href="https://copyrightagent.com/privacy/"
									target="_blank"
								/>,
							]}
						>
							{translations.terms}
						</Trans>
					</Typography>
				</Row>
			</PageContainer>
		</Fragment>
	)
}

export default LoginPage
