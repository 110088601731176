import { Filters } from 'components/CaseListView'
import { EListType } from 'lib/entities/Case'
import {
	getCurrentListTypeInUrl,
	getFiltersFromURL,
	makeFilterURLSearchParams,
} from 'lib/utils'
import _ from 'lodash'
import { getFirstCase } from './Private/InReview/api'

export enum RouteUrls {
	landingPage = '/',
	login = '/login',
	loggingIn = '/loggingIn',
	selectClient = '/select-client',
	createAccount = '/create-account',
	countrySelect = '/country-select',
	notAvailable = '/not-available',
	terms = '/terms',
	dashboard = '/dashboard',
	preApprovedList = '/preapproved',
	inReview = '/manual-review',
	inReviewList = '/review',
	onHoldList = '/on-hold',
	images = '/image-upload',
	submitCase = '/submit-case',
	findCaseList = '/cases',
	reports = '/reports',
	settings = '/settings/user-details',
	getStarted = '/get-started',
	case = '/case',
	imageDetails = '/image-details',
}

const getBaseListURLBasedOnListType = (listType: EListType) => {
	switch (listType) {
		case EListType.onHold:
			return RouteUrls.onHoldList
		case EListType.preapproved:
			return RouteUrls.preApprovedList
		case EListType.all:
			return RouteUrls.findCaseList
		default:
			return RouteUrls.inReviewList
	}
}

interface getListUrlBasedOnListTypeProps {
	listType?: EListType
	filters?: Partial<Filters>
	resetFilter?: boolean
	caseId?: string
}

interface DataForListTypeBasesURLsProps {
	listType: EListType
	searchParamString: string
}

const getDataForListTypeBasesURLS = (
	props?: getListUrlBasedOnListTypeProps,
): DataForListTypeBasesURLsProps => {
	const URLFilters = getFiltersFromURL()
	const filters: Filters = props?.resetFilter
		? ({ ...props?.filters } as Filters)
		: _.merge(props?.filters, URLFilters)

	const listType =
		props && props.listType !== undefined
			? props.listType
			: getCurrentListTypeInUrl()

	const searchParamString = makeFilterURLSearchParams(filters)

	return { listType, searchParamString }
}

export const getListUrlBasedOnListType = (
	props?: getListUrlBasedOnListTypeProps,
): string => {
	const { listType, searchParamString } = getDataForListTypeBasesURLS(props)

	return `${getBaseListURLBasedOnListType(listType)}?${searchParamString}`
}

export const getCaseUrlBasedOnListType = async (
	props?: getListUrlBasedOnListTypeProps,
): Promise<string> => {
	const { listType, searchParamString } = getDataForListTypeBasesURLS(props)
	const caseId = props?.caseId ?? (await getFirstCase()).data.case_id

	return `${getBaseListURLBasedOnListType(
		listType,
	)}/${caseId}?${searchParamString}`
}
