import createCase, { ICase } from 'lib/entities/Case'
import _ from 'lodash'
import { AnyAction } from 'redux'

export interface ICaseState {
	theCase: ICase
	errorMessage: string
	loaded: boolean
	countLoaded: boolean
	countOnHold: number
	submittedCases: any[]
}

export const REPLACE_CASE = 'replace-case'
export const SET_ERROR_MESSAGE = 'set-error-message'
export const SET_LOADED = 'set-loaded'
export const SET_SUBMITTED_CASES = 'set-self-uploaded-cases'

const initialState: ICaseState = {
	theCase: createCase(),
	countOnHold: 0,
	loaded: false,
	countLoaded: false,
	errorMessage: '',
	submittedCases: [],
}

export default function caseReducer(
	state = initialState,
	action: AnyAction,
): ICaseState {
	switch (action.type) {
		case REPLACE_CASE:
			return {
				...state,
				theCase: action.theCase,
			}
		case SET_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: action.message,
			}
		case SET_LOADED:
			return {
				...state,
				loaded: action.loaded,
			}

		case SET_SUBMITTED_CASES:
			return {
				...state,
				submittedCases: _.unionBy(
					state.submittedCases,
					action.submittedCases,
					'submitted_case_id',
				),
			}
		default:
			return state
	}
}
