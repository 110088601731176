import React, { ReactElement, useCallback } from 'react'
import createCase, { ICase } from 'lib/entities/Case'
import CaseRow from 'routes/Private/Cases/components/CaseRow'
import NoCases from 'routes/Private/NoCases'
import {
	getDefaultFilters,
	Filters,
	useCaseListView,
} from 'components/CaseListView'
import { RowRenderProps, ListRender } from '@copyrightagent/basic-components'
import { useLocation } from 'react-router-dom'
import { getCasesForList } from 'routes/Private/Cases/api'
import LoadingRow from 'routes/Private/Cases/components/CaseRow/LoadingRow'
import { parseQueryParams } from 'lib/utils'

interface IProps {
	onCasesLoad: (cases: ICase[]) => void
	onTotalCount: (count: number) => void
	onSetFilters: (filters: Filters) => void
}

export default function CaseList({
	onCasesLoad,
	onTotalCount,
	onSetFilters,
}: IProps): ReactElement | null {
	const location = useLocation()
	const { triggerRefetch } = useCaseListView()

	function rowRenderer({ key, isVisible, style, item }: RowRenderProps<ICase>) {
		if (!item) return <LoadingRow key={key} style={style} />
		return (
			<CaseRow theCase={item} key={key} style={style} isVisible={isVisible} />
		)
	}

	const getCases = useCallback(
		async (startIndex: number, stopIndex: number) => {
			const pageSize = stopIndex - startIndex + 1
			const response = await getCasesForList(startIndex, pageSize, {
				filters: parseQueryParams(getDefaultFilters(), location.search),
			})
			const {
				isSuccessful,
				data: { cases, count, countries, caseTypes, caseStatus },
			} = response

			const createdCases = cases.map((caseItem) => createCase(caseItem))

			return {
				isSuccessful,
				items: createdCases,
				count,
				filters: { countries, caseTypes, caseStatus },
			}
		},
		[location.search],
	)

	const updateFilters = useCallback(
		async (filtersToUpdate: {
			countries: string[]
			caseTypes: string[]
			caseStatus: string[]
		}) => {
			const filters: Filters = getDefaultFilters()
			if (filtersToUpdate.countries?.length)
				filters.countries = filtersToUpdate.countries
			if (filtersToUpdate.caseTypes?.length)
				filters.caseTypes = filtersToUpdate.caseTypes
			if (filtersToUpdate.caseStatus?.length)
				filters.caseStatus = filtersToUpdate.caseStatus
			onSetFilters(filters)
		},
		[onSetFilters],
	)

	return (
		<ListRender<ICase>
			rowRender={rowRenderer}
			noItemsRender={() => <NoCases />}
			onUpdateTotalCount={onTotalCount}
			getItems={getCases}
			updateFilters={updateFilters}
			onItemsLoad={onCasesLoad}
			triggerRefetch={triggerRefetch}
			locationSearch={location.search}
		/>
	)
}
