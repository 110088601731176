import React, { ReactElement, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	PageContainer,
	CopyrightAgentLoader,
} from '@copyrightagent/basic-components'
import { checkIfLoggingIn } from '../Login/firebaseConfig'
import useStyles from './style'

const LogginInPage = (): ReactElement => {
	const { t } = useTranslation()
	const classes = useStyles()

	useEffect(() => {
		checkIfLoggingIn()
	}, [])

	return (
		<PageContainer className={classes.loading}>
			<CopyrightAgentLoader />
			<Typography className={classes.loadingText}>
				{t('loggingIn/loading')}
			</Typography>
		</PageContainer>
	)
}

export default LogginInPage
