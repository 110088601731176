import React, { ReactElement } from 'react'
import { Input } from '@copyrightagent/basic-components'
import { FilledTextFieldProps } from '@material-ui/core/TextField'
import { useStyle } from './style'

type CommentProps = Omit<FilledTextFieldProps, 'variant'> & {
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	value: string
}

export default function Comment({
	onChange,
	onBlur,
	className,
	value,
	placeholder,
}: CommentProps): ReactElement {
	const classes = useStyle()
	return (
		<Input
			className={`${classes.comment} ${className}`}
			placeholder={placeholder}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			type="text"
		/>
	)
}
