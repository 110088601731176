import { makeStyles, Theme } from '@material-ui/core'
import { dark, dark10, dark5, green } from 'lib/Colors'

export const useRadioButtonStyle = makeStyles(
	(theme: Theme) => ({
		labelRoot: {
			position: 'relative',
			borderRadius: 6,
			display: 'flex',
			border: `1px solid ${dark10}`,
			marginBottom: 8,
			marginLeft: '0px!important',
			marginRight: '0px!important',
		},
		labelSelected: {
			backgroundColor: dark5,
		},
		radioRoot: {
			margin: 6,
			'& > span > input[type="radio"] + label': {
				width: 16,
				height: 16,
				borderRadius: 10,
				backgroundColor: theme.palette.grey['300'],
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: `1px solid ${dark10}`,
			},
			'& > span > input[type="radio"] + label:after': {
				content: "''",
				width: 0,
				height: 0,
				borderRadius: 6,
				backgroundColor: green,
			},
			'& > span > input[type="radio"]:checked + label:after': {
				width: 8,
				height: 8,
			},
		},
		labelContainer: {
			minWidth: '100% !important',
			flex: '1 !important',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		label: {
			fontWeight: 600,
		},
		labelFloat: {
			cursor: 'help',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 2,
			right: 12,
			width: 24,
			height: 24,
		},
	}),
	{ index: 1 },
)
