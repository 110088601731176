import { makeStyles } from '@material-ui/styles'
import { dark75, dark } from 'lib/Colors'

export default makeStyles(
	{
		title: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 12,
			color: dark,
		},
		subtitle: {
			color: dark75,
			maxWidth: 372,
		},
		reviewButton: {
			width: 200,
			height: 50,
		},
	},
	{ index: 1 },
)
