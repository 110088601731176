import React, {
	Fragment,
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
	ReactElement,
} from 'react'
import { maxBy } from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import {
	Col,
	Row,
	Typography,
	CopyrightAgentLoader,
	Icons,
} from '@copyrightagent/basic-components'

import { downloadReport } from 'lib/utils'
import { Report, createReport } from 'lib/entities/Report'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { dark50, dark75 } from 'lib/Colors'
import trackEvent from 'hooks/useAnalytics'
import useStyle from './style'
import { getReports } from './api'
import flattifyReports from './flattifyReports'
import PageWithNavigation from '../HomeLayout/PageWithNavigation'

function ReportsPage(): ReactElement {
	const [allReports, setAllReports] = useState<Report[]>([])
	const [datesOfReports, setDatesOfReports] = useState<string[]>([])
	const [filteredReports, setFilteredReports] = useState<Report[]>([])
	const [selectedIndex, setSelectedIndex] = useState(0)

	const [loaded, setLoaded] = useState(false)
	const [year, setYear] = useState('')
	const [month, setMonth] = useState('')
	const { t } = useTranslation()
	const classes = useStyle()

	useSetPageTitle(t('reports'))

	const setDefaultFilters = useCallback((reports: Report[]) => {
		const maxYear =
			maxBy(reports, (item) => Number(item.year)) || ({} as Report)
		const maxMonth =
			maxBy(
				reports.filter((item) => item.year === maxYear.year),
				(item) => Number(moment().month(item.month).format('M')),
			) || ({} as Report)
		setYear(maxYear.year)
		setMonth(maxMonth.month)
	}, [])

	const fetchReports = useCallback(async () => {
		const { data: result, status } = await getReports()
		if (status >= 400) return setLoaded(true)

		const resultReports = flattifyReports(result).map((item) =>
			createReport(item),
		)

		const dates = new Set(
			resultReports
				.map((item) => `${item.month} ${item.year}`)
				.sort((a: string, b: string) =>
					moment(b, 'MMMM YYYY').diff(moment(a, 'MMMM YYYY')),
				),
		)

		setAllReports(resultReports)
		setLoaded(true)
		setDatesOfReports(Array.from(dates))
		setDefaultFilters(resultReports)
		setFilteredReports(resultReports)
	}, [setDefaultFilters])

	const init = useRef(false)
	useEffect(() => {
		if (init.current) return
		fetchReports()
		init.current = true
	}, [fetchReports])

	useEffect(() => {
		const newFilteredReport = allReports.filter(
			(item) => item.year === year && item.month === month,
		)
		setFilteredReports(newFilteredReport)
	}, [month, year, allReports])

	const renderDatesList = useMemo(() => {
		if (loaded && datesOfReports.length === 0) {
			return (
				<Typography fontStyle="italic" fontWeight="normal" fontColor={dark50}>
					{t('reports/emptyList')}
				</Typography>
			)
		}

		return (
			<Fragment>
				{datesOfReports.map((item, index) => (
					<MenuItem
						onClick={() => handleDateClick(item, index)}
						key={index}
						selected={index === selectedIndex}
						value={item}
						disableTouchRipple={true}
						classes={{
							root: classes.menuItem,
							selected: classes.selectedMenuItem,
						}}
					>
						<Typography>{item}</Typography>
						<Typography variant="h5" fontWeight={600} className={classes.flex}>
							<ArrowForwardIos className={classes.arrowIcon} />
						</Typography>
					</MenuItem>
				))}
			</Fragment>
		)
	}, [datesOfReports, loaded, classes, t, selectedIndex])

	function handleDateClick(value: string, index: number) {
		setSelectedIndex(index)
		const [newMonth, newYear] = value.split(' ')
		setYear(newYear)
		setMonth(newMonth)
		trackEvent('Open reports month', {
			openedMonth: `${newMonth} ${newYear}`,
		})
	}

	return (
		<PageWithNavigation title={t('reports')}>
			{!loaded ? (
				<CopyrightAgentLoader className={classes.absoluteCenter} />
			) : (
				<Row className={classes.row} align="stretch" justify="space-between">
					<Col
						justify="flex-start"
						className={`${classes.whitePaper} ${classes.columnSpace}`}
					>
						<Typography
							variant="h5"
							fontWeight={600}
							className={classes.whitePaperHeader}
						>
							{t('reports/reports')}
						</Typography>
						<Typography
							fontWeight="normal"
							className={`${classes.greyText} ${classes['marginTop-10']} ${classes.whitePaperHeader}`}
						>
							{t('reports/reportsTitle')}
						</Typography>
						<Typography
							className={clsx(
								classes.greyText,
								classes.margin20,
								classes['marginTop-50'],
								classes.whitePaperHeader,
							)}
						>
							{t('reports/month')}
						</Typography>
						<Col className={classes.documentItemList}>{renderDatesList}</Col>
					</Col>
					<Col justify="flex-start" className={classes.whitePaper}>
						<Typography
							variant="h5"
							fontWeight={600}
							className={classes.whitePaperHeader}
						>
							{t('reports/documents')}&nbsp;
							<ArrowForwardIos className={classes.arrowIcon} />
							&nbsp;
							{datesOfReports[selectedIndex]}
						</Typography>
						<Typography
							fontWeight="normal"
							className={`${classes.greyText} ${classes['marginTop-10']} ${classes.whitePaperHeader}`}
						>
							{t('reports/documentsTitle')}
						</Typography>
						<Row className={classes['marginTop-30']}>
							<Typography
								className={`${classes.greyText} ${classes.margin20} ${classes.whitePaperHeader}`}
							>
								{t('document')}
							</Typography>
						</Row>
						<Col className={classes.documentItemList}>
							{filteredReports.map((report: Report) => (
								<ReportDocument key={report.name} report={report} />
							))}
						</Col>
					</Col>
				</Row>
			)}
		</PageWithNavigation>
	)
}

interface ReportDocument {
	report: Report
}

function ReportDocument({ report }: ReportDocument) {
	const classes = useStyle()
	return (
		<Row
			onClick={() => {
				downloadReport(report)
				trackEvent('Download report', {
					reportName: report.name,
				})
			}}
			className={classes.documentItem}
			justify="space-between"
		>
			<Typography>{report.name}</Typography>
			<Icons.DownloadIcon color={dark75} />
		</Row>
	)
}

export default ReportsPage
