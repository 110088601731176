import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(
	{
		loading: {
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
		},
		loadingText: {
			fontSize: 20,
			marginTop: 20,
			fontWeight: 800,
		},
	},
	{ index: 1 },
)

export default useStyles
