import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export default makeStyles(
	(theme: Theme) => ({
		root: {
			position: 'fixed',
			backgroundColor: '#fff',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 99999,
			padding: 50,
			width: '100%',
			height: '100%',
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
			},
		},
		logo: {
			width: 262,
			position: 'absolute',
			top: 50,
			left: 50,
			[theme.breakpoints.down('xs')]: {
				left: '50%',
				marginLeft: '-131px',
			},
		},
		title: {
			marginBottom: 20,
		},
		body: {
			whiteSpace: 'pre',
		},
	}),
	{ index: 1 },
)
