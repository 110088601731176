import { makeInternalAPIRequest } from 'lib/http/ApiProvider'
import Response from 'lib/http/Response'

export const getImages = (page: number, perPage: number): Promise<Response> =>
	makeInternalAPIRequest('get', '/image-upload', {
		limit: perPage,
		offset: Math.abs(page * perPage),
	})
export const getImagesCount = (): Promise<Response> =>
	makeInternalAPIRequest('get', '/image-upload/count')
export const uploadImageToFolder = (body: FormData): Promise<Response> =>
	makeInternalAPIRequest('post', '/image-upload', body, {
		'Content-Type': 'multipart/form-data;',
	})
