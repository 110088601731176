import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles(
	(theme: Theme) => ({
		paper: {
			backgroundColor: 'white',
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
			borderRadius: '6px',
			padding: 35,
			marginBottom: 20,
			[theme.breakpoints.down('md')]: {
				padding: 20,
			},
		},
		logOut: {
			cursor: 'pointer',
			marginLeft: 'auto',
		},
		sectionTitle: {
			marginBottom: 20,
		},
		input: {
			width: 350,
			marginRight: 10,
		},
	}),
	{ index: 1 },
)
