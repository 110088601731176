import moment from 'moment'
import i18n from 'i18next'
import qs from 'query-string'
import { getCurrentUser, getSelectedClient, toast } from 'store/utils'
import { RouteUrls } from 'routes/RouteUrls'
import { MouseEvent } from 'react'
import { UseRouterReturnType } from 'routes/CustomBrowserRouter'
import { Filters, getDefaultFilters } from 'components/CaseListView'
import { AxiosError } from 'axios'
import { Report } from './entities/Report'
import { EListType } from './entities/Case'
import { KeysWhereValueMatches } from './utility-types'

const { REACT_APP_API_URL: API_URL, REACT_APP_API_PREFIX: API_PREFIX } =
	process.env

export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const SWEDISH_ORGANIZATION_NUMBER =
	/^\d{6}-\d{4}$/;

export const sleep = (time: number): Promise<void> =>
	new Promise((resolve) => setTimeout(resolve, time))

const fallbackCopyToClipboard = (id: string) => {
	const textArea = document.createElement('textarea')
	textArea.value = id

	textArea.style.top = '0'
	textArea.style.left = '0'
	textArea.style.position = 'fixed'

	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()

	try {
		document.execCommand('copy')
		toast(i18n.t('snackMessage/textCoppied'), 'info')
	} catch (err) {
		toast(i18n.t('snackMessage/textCoppiedError'), 'info')
	}

	document.body.removeChild(textArea)
}

export const copyToClipboard = (
	e: MouseEvent<HTMLButtonElement>,
	id: string,
): void => {
	e.stopPropagation()

	// Fallback for older browsers
	if (!navigator.clipboard) return fallbackCopyToClipboard(id)

	// Async method for chrome
	navigator.clipboard.writeText(id).then(
		() => {
			toast(i18n.t('snackMessage/textCoppied'), 'info')
		},
		() => {
			toast(i18n.t('snackMessage/textCoppiedError'), 'info')
		},
	)
}

export function downloadReport(report: Report): void {
	const user = getCurrentUser()
	const client = getSelectedClient()
	let url = `${API_URL}${API_PREFIX}/reports`
	url += `/${client.client_id}`
	url += `/${report.year}/${report.month}`
	url += `/${report.name}?auth_token=${user.token}`

	window.open(url, 'blank')
}

export const preloadImage = (src: string): void => {
	const image = new Image()
	image.src = src
}

export const getDateTime = (date: string): string =>
	moment(date).format('DD.MM.YYYY')

export const getDateAndTime = (date: string): string =>
	moment(date).format('DD.MM.YYYY HH:MM')

export function formatBytes(bytes: number, decimals = 2): string {
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const isInEnum = (
	value: string,
	_enum: { [s: number]: string | number },
): boolean => Object.values(_enum).includes(value)

const getURLParam = (paramName: string): string => {
	const url = new URL(window.location.href)
	return url.searchParams.get(paramName) || ''
}

export const filtersIsSet = (withSearch = true): boolean =>
	!!(
		getURLParam('countries') ||
		getURLParam('caseTypes') ||
		getURLParam('caseStatus') ||
		(withSearch && getURLParam('searchTerm'))
	)
export const searchIsSet = (): boolean => !!getURLParam('searchTerm')

export const makeFilterURLSearchParams = (filters: Filters): string => {
	const searchParams: Array<string> = []
	if (filters.caseStatus && filters.caseStatus.length)
		searchParams.push(`caseStatus=${filters.caseStatus.join(',')}`)
	if (filters.caseTypes && filters.caseTypes.length)
		searchParams.push(`caseTypes=${filters.caseTypes.join(',')}`)
	if (filters.countries && filters.countries.length)
		searchParams.push(`countries=${filters.countries.join(',')}`)
	if (filters.searchTerm) searchParams.push(`searchTerm=${filters.searchTerm}`)
	if (filters.domain) searchParams.push(`domain=${filters.domain}`)

	return searchParams.join('&')
}

export const makeListTypeURL = (listType: EListType): string =>
	`listtype=${EListType[listType]}`

export const getCurrentListTypeInUrl = (): EListType => {
	const url = new URL(window.location.href)
	if (url.pathname.indexOf(RouteUrls.onHoldList) !== -1) return EListType.onHold
	if (url.pathname.indexOf(RouteUrls.preApprovedList) !== -1)
		return EListType.preapproved
	if (url.pathname.indexOf(RouteUrls.case) !== -1) return EListType.all
	return EListType.inReview
}

export const clearFiltersFromURL = (router: UseRouterReturnType): void => {
	const url = new URL(window.location.href)
	url.searchParams.delete('caseStatus')
	url.searchParams.delete('searchTerm')
	url.searchParams.delete('caseTypes')
	url.searchParams.delete('countries')

	router.history.push(url.pathname + url.search)
}

const transformValue = (
	value: string | null | (string | null)[],
): string | string[] => {
	if (value === null) return ''
	if (Array.isArray(value)) return value.map((v) => v || '')
	return value
}

type ArrayFilterKeys = KeysWhereValueMatches<Filters, string[]>
type StringFilterKeys = KeysWhereValueMatches<Filters, string>

export const parseQueryParams = (
	initialFilters: Filters,
	search: string,
): Filters => {
	const parsedQueryParams = qs.parse(search, { arrayFormat: 'comma' })

	const parsedFilters = Object.entries(initialFilters).reduce((acc, [key]) => {
		const value = parsedQueryParams[key]
		const transformedValue = transformValue(value)
		const valueIsArray = Array.isArray(transformedValue)
		const arrayValue = valueIsArray ? transformedValue : [transformedValue]
		const singleValue = valueIsArray ? transformedValue[0] : transformedValue

		if (parsedQueryParams[key]) {
			if (Array.isArray(initialFilters[key as keyof Filters])) {
				// we have to cast the key here because TS doesn't know we're only working with keys that hold arrays
				acc[key as ArrayFilterKeys] = arrayValue as Filters[ArrayFilterKeys]
			} else {
				// same here, except now it's going to be only keys that hold strings as values
				acc[key as StringFilterKeys] = singleValue as Filters[StringFilterKeys]
			}
		}

		return acc
	}, initialFilters)

	return parsedFilters
}

export const getFiltersFromURL = (): Filters =>
	parseQueryParams(getDefaultFilters(), window.location.search)

export const isAxiosError = (value: any): value is AxiosError =>
	typeof value === 'object' && 'isAxiosError' in value
