import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Row, Col } from '@copyrightagent/basic-components'
import { IRootState } from 'store'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useGetDashboard, { useDashboard } from 'hooks/useDashboard'
import useStyle from './style'
import CasesForReview from './components/CasesForReview'
import TargetStatistics from './components/TargetStatistics'
import PageWithNavigation from '../HomeLayout/PageWithNavigation'
import { TargetMeter } from './components/TargetMeter'

export default function DashboardPage(): ReactElement {
	const classes = useStyle()
	const { reviewTarget } = useDashboard()
	const currentUser = useSelector(
		(store: IRootState) => store.AuthState.currentUser,
	)
	const { t } = useTranslation()

	useSetPageTitle(t('dashboard/pageTitle'))
	useGetDashboard()

	const renderTargetMeter = useMemo(() => {
		if (
			process.env.REACT_APP_SHOW_TARGET_METER === 'true' &&
			reviewTarget.monthlyTarget !== 0
		)
			return <TargetMeter />

		return <></>
	}, [reviewTarget])

	const renderTargetStatistics = useMemo(() => {
		if (
			process.env.REACT_APP_SHOW_TARGET_METER === 'true' &&
			reviewTarget.monthlyTarget !== 0
		) {
			return (
				<Row className={classes.widgetRow}>
					<TargetStatistics />
				</Row>
			)
		}
		return <></>
	}, [reviewTarget])

	return (
		<PageWithNavigation
			title={`${t('dashboard/welcomeBack')}, ${currentUser.firstname}!`}
		>
			<Col
				align="flex-start"
				justify="space-between"
				className={classes.pageContainer}
			>
				<Row className={classes.widgetRow}>
					<CasesForReview />
					{renderTargetMeter}
				</Row>
				{renderTargetStatistics}
			</Col>
		</PageWithNavigation>
	)
}
