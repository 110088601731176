import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { updateCase } from 'lib/entities/Case'
import { getCaseActions, useCase } from 'hooks/useCase'
import { updateComment } from 'routes/Private/Cases/api'
import { useStyle } from './style'
import Comment from './Comment'

interface CommentProps {
	className?: string
	optional?: boolean
}

export default function CaseComment({
	className,
	optional = true,
}: CommentProps): ReactElement {
	const { t } = useTranslation()
	const { theCase } = useCase()
	const { replaceCase } = getCaseActions()
	const classes = useStyle()

	function handleChange(
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) {
		const newCase = updateCase(theCase, { comment: e.target.value as string })
		replaceCase(newCase)
	}

	function submitComment(
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
	) {
		updateComment(theCase.violation_id, e.target.value)
	}

	return (
		<Comment
			onChange={handleChange}
			onBlur={submitComment}
			className={`${classes.comment} ${className}`}
			value={theCase.comment}
			placeholder={
				optional
					? t('caseActions/commentButtonTextShortOptional')
					: t('caseActions/commentButtonTextShort')
			}
		/>
	)
}
