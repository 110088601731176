import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { green, dark75, dark5, dark50 } from 'lib/Colors'

export const drawerWidth = 240

export const useStyle = makeStyles(
	(theme: Theme) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			height: '100vh',
			position: 'sticky',
			top: 0,
			width: 240,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
			[theme.breakpoints.down('md')]: {
				width: 60,
			},
			[theme.breakpoints.down('xs')]: {
				width: 0,
			},
		},
		drawerPaperRoot: {
			borderRight: 'none',
			width: drawerWidth,
			[theme.breakpoints.down('md')]: {
				width: 60,
				overflowX: 'hidden',
			},
		},
		list: {
			flex: 1,
			paddingTop: 20,
			[theme.breakpoints.down('md')]: {
				paddingTop: 0,
			},
		},
		bottomList: {
			paddingBottom: 0,
			overflow: 'hidden',
		},
		listItem: {
			color: dark50,
			position: 'relative',
			padding: 0,
			height: 60,
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0 !important',
			},
			'&:hover': {
				background: dark5,
				color: dark75,
			},
		},
		selectedListItem: {
			backgroundColor: '#fff !important',
			color: dark75,
			'&:after': {
				content: "''",
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				width: 5,
				backgroundColor: `${green} !important`,
			},
		},
		listItemIcon: {
			minWidth: 'unset',
			padding: 20,
		},
		listItemIconProfile: {
			minWidth: 'unset',
			padding: 20,
		},
		linkActive: {
			color: dark75,
			cursor: 'pointer',
			fontWeight: 600,
			lineHeight: '20px',
		},
		linkNonActive: {
			color: dark50,
			cursor: 'pointer',
			fontWeight: 600,
			lineHeight: '20px',
		},
		onHoldOption: {
			paddingLeft: 36,
			paddingRight: 20,
			'&:after': {
				content: "''",
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				width: 5,
				backgroundColor: dark5,
			},
		},
		onHoldOptionTextWrap: {
			width: '100%',
		},
	}),
	{ index: 1 },
)

export const useStyleTooltip = makeStyles(
	() => ({
		arrow: {
			color: dark75,
			opacity: 100,
		},
		tooltip: {
			fontSize: 14,
			backgroundColor: dark75,
			opacity: 100,
			visibility: 'hidden',
			'@media (max-width: 1440px)': {
				visibility: 'visible',
				marginLeft: '0 !important',
			},
		},
	}),
	{ index: 1 },
)
