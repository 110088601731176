import React, { useEffect, useState, ReactElement, Fragment } from 'react'
import {
	Col,
	Row,
	TextArea,
	useFormInput,
	PrimaryButton,
} from '@copyrightagent/basic-components'
import { Activity, Note } from 'lib/entities/Activity'
import { makeInternalAPIRequest } from 'lib/http/ApiProvider'
import { ICase } from 'lib/entities/Case'
import trackEvent from 'hooks/useAnalytics'
import { isAxiosError } from 'lib/utils'
import { ActivityItem } from 'components/ActivityItem'
import { useActivityStyle } from './style'

interface FetcherOptions {
	onSuccess?: (data: (Activity | Note)[]) => void | Promise<void>
	onError?: (e: unknown) => void | Promise<void>
	onSettled?: () => void | Promise<void>
}

async function fetchActivity(caseId: string, options?: FetcherOptions) {
	try {
		const { data } = await makeInternalAPIRequest(
			'get',
			`/cases/${caseId}/get-activity`,
		)
		if (options?.onSuccess) options!.onSuccess(data)
	} catch (e) {
		if (options?.onError) options!.onError(e)
		if (isAxiosError(e)) console.error(e.message, e.response)
	} finally {
		if (options?.onSettled) options!.onSettled()
	}
}

interface IProps {
	theCase: ICase
}

export default function CaseActivity({ theCase }: IProps): ReactElement {
	const classes = useActivityStyle()
	const [activities, setActivities] = useState<(Activity | Note)[]>([])
	const note = useFormInput('')

	useEffect(() => {
		if (!theCase.violation_id) return

		fetchActivity(theCase.violation_id, {
			onSuccess: (data) => setActivities(data),
		})
	}, [theCase.violation_id, theCase.status])

	const submitComment = async () => {
		const noteValue = note.attrs.value
		note.changeValue('')

		await makeInternalAPIRequest(
			'post',
			`/cases/${theCase.violation_id}/add-note`,
			{ note: noteValue },
		)

		fetchActivity(theCase.violation_id, {
			onSuccess: (data) => setActivities(data),
			onSettled: () => note.changeValue(''),
		})
	}

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()

		if (!theCase.violation_id) return
		if (!note.attrs.value) return

		await submitComment()

		if (!theCase.client_review_required) {
			trackEvent('Added a note in pre-approved', {
				caseId: theCase.case_id,
			})
		}
	}

	return (
		<Fragment>
			<Col align="stretch" className={classes.activityItemsWrap}>
				{activities.map((activity) => (
					<ActivityItem key={activity.id} item={activity} />
				))}
			</Col>

			<form onSubmit={handleSubmit} className={classes.form}>
				<Row>
					<TextArea
						className={classes.input}
						variant="filled"
						type="text"
						label={'Add a note'}
						{...note.attrs}
						multiline={false}
						onKeyPress={(e) => {
							if (e.key === 'Enter') submitComment()
						}}
					/>
					<PrimaryButton
						type="submit"
						disabled={!theCase.violation_id}
						text="Send"
					/>
				</Row>
			</form>
		</Fragment>
	)
}
