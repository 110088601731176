import React, { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Typography, Input } from '@copyrightagent/basic-components'

import { getCurrentUser } from 'store/utils'
import useStyle from './style'

function UserDetails(): ReactElement {
	const classes = useStyle()
	const { t } = useTranslation()
	const user = getCurrentUser()
	const [firstname, setName] = useState(user.firstname)
	const [email, setEmail] = useState(user.email)

	return (
		<Col justify="space-between" className={classes.paper}>
			<Row justify="space-between" style={{ width: '100%' }}>
				<Typography
					variant="h5"
					fontWeight={600}
					className={classes.sectionTitle}
				>
					{t('settings/userDetails')}
				</Typography>
			</Row>
			<Row justify="flex-start">
				<Input
					value={firstname}
					type="text"
					fullWidth
					disabled
					variant="filled"
					label={t('settings/yourName')}
					className={classes.input}
					onChange={(event) => {
						setName(event.target.value)
					}}
				/>
				<Input
					value={email}
					type="email"
					fullWidth
					disabled
					variant="filled"
					label={t('user/email')}
					className={classes.input}
					onChange={(event) => {
						setEmail(event.target.value)
					}}
				/>
			</Row>
		</Col>
	)
}
export default UserDetails
