import React, { ReactElement, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { nonViolatingReasons, NonViolatingReason } from 'lib/entities/Case'
import { useCase } from 'hooks/useCase'
import {
	PrimaryButton,
	Modal,
	ModalSection,
	Row,
} from '@copyrightagent/basic-components'
import RadioButton from './RadioButton'

interface AddToWhiteListProps {
	onUpdateNonViolationReason: (value: NonViolatingReason) => void
	onOpenModal?: () => void
}

export default function AddToWhiteListModal({
	onUpdateNonViolationReason,
	onOpenModal,
}: AddToWhiteListProps): ReactElement {
	const [dialogOpened, setDialogOpened] = useState(false)
	const { t } = useTranslation()
	const { theCase } = useCase()

	const onClick = () => {
		onUpdateNonViolationReason(nonViolatingReasons.whitelisted)
		setDialogOpened(false)
	}

	const onRadioClick = () => {
		setDialogOpened(true)
		if (onOpenModal) onOpenModal()
	}

	const titleText = (
		<>
			{t('nonInfringement/whiteList/dialogBodyPart1')}
			<strong>{theCase.domain}</strong>
			{t('nonInfringement/whiteList/dialogBodyPart2')}
		</>
	)

	return (
		<Fragment>
			<RadioButton
				id="radio-whitelist"
				value={nonViolatingReasons.whitelisted}
				label={t('nonInfringement/whitelist')}
				toolTipText={t('nonInfringement/whitelist/tooltip')}
				onChange={onRadioClick}
			/>
			<Modal
				title={t('nonInfringement/whiteList/dialogTitle')}
				titleText={titleText}
				size="small"
				open={dialogOpened}
				onClose={() => setDialogOpened(false)}
			>
				<ModalSection>
					<Row justify={'flex-end'}>
						<PrimaryButton
							onClick={onClick}
							type="submit"
							text={t('nonInfringement/IUnderstand')}
						/>
					</Row>
				</ModalSection>
			</Modal>
		</Fragment>
	)
}
