import { makeStyles } from '@material-ui/styles'

import { dark10, dark5, dark50, dark75, green } from 'lib/Colors'

export const useStyle = makeStyles(
	() => ({
		root: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginBottom: 8,
			backgroundColor: 'transparent',
			width: '100%',
			position: 'relative',
		},
		selectIndicator: {
			width: 4,
			backgroundColor: green,
			height: 72,
			position: 'absolute',
			left: 0,
			borderTopLeftRadius: 6,
			borderBottomLeftRadius: 6,
			zIndex: 2,
		},
		caseRows: {
			flex: 1,
			zIndex: 1,
			height: 72,
			minWidth: 680,
			borderRadius: 6,
			backgroundColor: '#fff',
			padding: 16,
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
			cursor: 'pointer',
		},
		caseRowColumn: {
			paddingLeft: 16,
			flexDirection: 'column',
			borderRight: `1px solid ${dark10}`,
		},
		caseRow: {
			height: '100%',
			paddingLeft: 16,
			flexDirection: 'row',
			borderRight: `1px solid ${dark10}`,
		},
		caseRowLastColumn: {
			paddingLeft: 16,
			flexDirection: 'column',
		},
		chip: {
			marginRight: 8,
		},
		accentText: {
			color: dark75,
			fontWeight: 600,
		},
		iconButton: {
			padding: 2,
			marginRight: 8,
		},
		caseID: {
			paddingRight: 2,
			maxWidth: 270,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			cursor: 'pointer',
		},
		caseStatusText: {
			paddingRight: 10,
			textTransform: 'capitalize',
		},
		caseStatusDate: {
			color: dark50,
			marginLeft: 4,
		},
		columnTitle: {
			color: dark50,
		},
		image: {
			overflow: 'hidden',
			paddingTop: 0,
			width: 40,
			height: 40,
			borderRadius: 4,
			objectFit: 'cover',
			'& img': {
				maxHeight: 150,
				minHeight: '100%',
			},
		},
		imageOverlay: {
			borderRadius: 4,
			overflow: 'hidden',
		},
		placeholder: {
			borderRadius: 4,
			backgroundColor: dark5,
			position: 'relative',
			overflow: 'hidden',
			'&::after': {
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				content: '""',
				position: 'absolute',
				animation: 'wawe 2s linear infinite',
				display: 'block',
				transform: 'translateX(-100%)',
				background:
					'linear-gradient(90deg, transparent, rgb(236, 235, 235), transparent)',
			},
		},
		delayImage: {
			'&:after': {
				animationDelay: '0.3s',
			},
		},
		delayMedium: {
			'&:after': {
				animationDelay: '0.5s',
			},
		},
		delayLong: {
			'&:after': {
				animationDelay: '0.8s',
			},
		},
		imagePlaceholder: {
			width: 40,
			height: 40,
		},
		smallPlaceholder: {
			height: 16,
			marginRight: 4,
			width: 16,
		},
		mediumPlaceholder: {
			height: 16,
			marginRight: 4,
			width: 48,
		},
		longPlaceholder: {
			height: 16,
			marginRight: 4,
			width: 83,
		},
	}),
	{ index: 1 },
)
