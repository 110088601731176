import React, { ReactElement } from 'react'
import { CaseTypeLabel } from '@copyrightagent/basic-components'
import { getViolationLabel, getCaseLabelColor, ICase } from 'lib/entities/Case'

export default function CaseType({
	theCase,
}: {
	theCase: ICase
}): ReactElement | null {
	const violationLabel = getViolationLabel(theCase)
	const showSoftClaim: boolean = theCase.status === 'open' && theCase.soft_claim
	if (!violationLabel) return null

	return (
		<CaseTypeLabel
			color={getCaseLabelColor(violationLabel)}
			labelText={violationLabel}
			isSoftClaim={showSoftClaim}
		/>
	)
}
