import { Icons } from '@copyrightagent/basic-components'
import { getListUrlBasedOnListType, RouteUrls } from 'routes/RouteUrls'
import { ReactElement } from 'react'
import { EListType } from 'lib/entities/Case'

interface INavRoute {
	icon: (props: any) => ReactElement
	labelString: string
	route: string
	regex: RegExp
}

export const navRoutes: INavRoute[] = [
	{
		icon: Icons.DashboardIcon,
		labelString: 'navigation/dashboard',
		route: RouteUrls.dashboard,
		regex: new RegExp(`${RouteUrls.dashboard}$`),
	},
	{
		icon: Icons.InReviewIcon,
		labelString: 'navigation/inReview',
		route: getListUrlBasedOnListType({
			listType: EListType.inReview,
			resetFilter: true,
		}),
		regex: new RegExp(`${RouteUrls.inReviewList}(.*)`),
	},
	{
		icon: Icons.ImageImportIcon,
		labelString: 'navigation/images',
		route: RouteUrls.images,
		regex: new RegExp(RouteUrls.images),
	},
	{
		icon: Icons.FileIcon,
		labelString: 'navigation/createCase',
		route: RouteUrls.submitCase,
		regex: new RegExp(RouteUrls.submitCase),
	},
	{
		icon: Icons.SearchIcon,
		labelString: 'navigation/findCase',
		route: getListUrlBasedOnListType({
			listType: EListType.all,
			resetFilter: true,
		}),
		regex: new RegExp(`${RouteUrls.findCaseList}(.*)`),
	},
]

export const navBottomRoutes: INavRoute[] = [
	{
		icon: Icons.ReportsIcon,
		labelString: 'navigation/reports',
		route: RouteUrls.reports,
		regex: new RegExp(`${RouteUrls.reports}(.*)`),
	},
	{
		icon: Icons.SettingsIcon,
		labelString: 'navigation/settings',
		route: RouteUrls.settings,
		regex: new RegExp(`${RouteUrls.settings}(.*)`),
	},
]
