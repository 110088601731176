import React, { Fragment, ReactElement, useMemo } from 'react'
import { TextLabel } from '@copyrightagent/basic-components'
import { useTranslation } from 'react-i18next'
import { ICase, isCasePreapproved } from 'lib/entities/Case'
import { useStyle } from './style'

export default function LocationLabel({
	theCase,
}: {
	theCase: ICase
}): ReactElement | null {
	const classes = useStyle()
	const { t } = useTranslation()

	const InReviewLabel = useMemo(
		() => (
			<TextLabel
				uppercase
				size="small"
				labelText={t('caseRow/labels/inReview')}
				className={classes.chip}
			/>
		),
		[classes, t],
	)

	const OnHoldLabel = useMemo(
		() => (
			<TextLabel
				uppercase
				size="small"
				labelText={t('caseRow/labels/onHold')}
				color="blue"
			/>
		),
		[classes, t],
	)

	const PreapprovedLabel = useMemo(
		() => (
			<TextLabel
				uppercase
				size="small"
				labelText={t('caseRow/labels/preapproved')}
				className={classes.chip}
			/>
		),
		[classes, t],
	)

	function displayLabel(): ReactElement | undefined {
		if (theCase.customer_approve_time) return
		if (theCase.on_hold) return OnHoldLabel
		if (isCasePreapproved(theCase)) return PreapprovedLabel
		return InReviewLabel
	}

	return <Fragment>{displayLabel()}</Fragment>
}
