import React, { ReactNode, createContext, useContext } from 'react'
import { BrowserRouter, Route, RouteChildrenProps } from 'react-router-dom'

export type UseRouterReturnType = RouteChildrenProps<
	Record<string, string | undefined>,
	unknown
>

export const RouterContext = createContext<UseRouterReturnType | undefined>(
	undefined,
)

interface CustomBrowserRouterProps {
	children: ReactNode
}

const CustomBrowserRouter = (props: CustomBrowserRouterProps): JSX.Element => (
	<BrowserRouter>
		<Route>
			{(routeProps) => (
				<RouterContext.Provider value={routeProps}>
					{props.children}
				</RouterContext.Provider>
			)}
		</Route>
	</BrowserRouter>
)

export const useRouter = (): UseRouterReturnType => {
	const context = useContext(RouterContext)
	if (!context)
		throw new Error('useRouter must be used within CustomBrowserRouter')

	return context
}

export default CustomBrowserRouter
