import React, { ReactElement } from 'react'
import {
	PrimaryButton,
	SecondaryButton,
} from '@copyrightagent/basic-components'
import { Tooltip } from '@material-ui/core'
import { useStyle, useToolTipStyle } from './style'

interface FooterProps {
	className?: string
	nextLabel: string
	handleNext(): void
	nextDisabled?: boolean
	previousLabel?: string
	handlePrevious?(): void
	previousDisabled?: boolean
	tooltipText?: string
	loading?: boolean
	children?: ReactElement | ReactElement[]
}

const Footer = (props: FooterProps): ReactElement => {
	const {
		className,
		nextLabel,
		handleNext,
		nextDisabled,
		previousLabel,
		handlePrevious,
		previousDisabled,
		tooltipText,
		loading,
		children,
	} = props

	const classes = useStyle()
	const toolTipClasses = useToolTipStyle()

	const renderNextButtonWithTooltip = () => (
		<Tooltip
			classes={toolTipClasses}
			title={tooltipText || ''}
			disableHoverListener={!nextDisabled}
			placement="top-start"
			arrow
		>
			<div>
				<PrimaryButton
					className={classes.button}
					type="button"
					text={nextLabel}
					onClick={handleNext}
					disabled={nextDisabled}
				/>
			</div>
		</Tooltip>
	)

	const renderNextButton = () => (
		<PrimaryButton
			className={classes.button}
			type="button"
			text={nextLabel}
			onClick={handleNext}
			disabled={nextDisabled}
			loading={loading}
		/>
	)

	const renderButton = (): ReactElement => {
		if (tooltipText) return renderNextButtonWithTooltip()
		return renderNextButton()
	}

	return (
		<div className={`${classes.footer} ${className}`}>
			{previousLabel && (
				<SecondaryButton
					className={classes.secondaryButton}
					type="button"
					text={previousLabel}
					onClick={handlePrevious}
					disabled={previousDisabled}
				/>
			)}
			{renderButton()}
			{children}
		</div>
	)
}

export default Footer
