import React, { useMemo, ReactElement, Fragment, CSSProperties } from 'react'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	ICase,
	getOriginalImageUrl,
	getCaseLocation,
	getArchiveImageUrl,
} from 'lib/entities/Case'
import {
	Row,
	Typography,
	ImageComponent,
	Icons,
} from '@copyrightagent/basic-components'
import CaseType from 'components/CaseTypeLabel'
import CaseStatus from 'components/CaseStatus'
import { dark50 } from 'lib/Colors'
import { copyToClipboard } from 'lib/utils'
import { getCaseUrlBasedOnListType, RouteUrls } from 'routes/RouteUrls'
import { useRouter } from 'routes/CustomBrowserRouter'
import LocationLabel from './LocationLabel'
import CaseLocation from './CaseLocation'
import { useStyle } from './style'
import { LoadingRowColumns } from './LoadingRow'
import getCountryByCode from 'lib/getCountryByCode'

interface CaseRowProps {
	theCase: ICase
	style?: CSSProperties
	isVisible: boolean
}

export default function CaseRow({
	theCase,
	style,
	isVisible,
}: CaseRowProps): ReactElement {
	const classes = useStyle()
	const { t } = useTranslation()
	const router = useRouter()
	const caseLocation = getCaseLocation(theCase)

	const navigateToCase = async (destination: ICase) => {
		const caseUrl = destination.customer_approve_time ? `${RouteUrls.case}/${destination.case_id}` : await getCaseUrlBasedOnListType({ caseId: destination.case_id })
		window.open(caseUrl, '_blank')
	}

	const handlePreview = () => {
		if (!getArchiveImageUrl(theCase)) return

		window.open(getArchiveImageUrl(theCase, 1024), '_blank')
	}

	const caseRowColumns = (
		<Row className={classes.caseRows}>
			{showCaseTitle()}
			{showCaseStatus()}
			{showCaseCountry()}
			{showCaseLocation()}
		</Row>
	)

	const caseRowContent = useMemo((): ReactElement => {
		if (!isVisible) return <LoadingRowColumns />
		return caseRowColumns
	}, [isVisible])

	return (
		<div
			className={classes.root}
			style={style}
			onClick={() => navigateToCase(theCase)}
		>
			{caseRowContent}
		</div>
	)

	function showCaseTitle(): ReactElement {
		return (
			<Fragment>
				<ImageComponent
					className={classes.image}
					overlayClassName={classes.imageOverlay}
					src={getOriginalImageUrl(theCase)}
					onPreview={handlePreview}
				/>
				<Row
					style={{ flex: 1 }}
					className={classes.caseRowColumn}
					align="start"
				>
					<Row>
						<Typography className={classes.caseID} fontColor={dark50}>
							{theCase.case_id}
						</Typography>
						<IconButton
							className={classes.iconButton}
							onClick={(e) => copyToClipboard(e, theCase.case_id)}
						>
							<Icons.CopyIcon color={dark50} size="small" />
						</IconButton>
						<CaseType theCase={theCase} />
					</Row>
					<Typography fontWeight={600} variant="body1">
						{theCase.company_name}
					</Typography>
				</Row>
			</Fragment>
		)
	}

	function showCaseStatus(): ReactElement {
		return (
			<Row style={{ flex: 1 }} className={classes.caseRow} justify="flex-start">
				<CaseStatus theCase={theCase} />
				<Typography fontWeight={600} className={classes.caseStatusText}>
					{theCase.status}
				</Typography>
				<LocationLabel theCase={theCase} />
			</Row>
		)
	}

	function showCaseLocation(): ReactElement {
		return (
			<Row
				style={{ flex: 1 }}
				className={classes.caseRowLastColumn}
				align="flex-start"
			>
				<CaseLocation theCase={theCase} caseLocation={caseLocation} />
			</Row>
		)
	}

	function showCaseCountry(): ReactElement {
		return (
			<Row
				style={{ flex: 1 }}
				className={classes.caseRowColumn}
				align="flex-start"
			>
				<Typography fontWeight={600} className={classes.columnTitle}>
					{t('caseRow/country')}
				</Typography>
				{renderCountry()}
			</Row>
		)
	}
	function renderCountry(): ReactElement {
		return (
			<Typography fontWeight={600} className={classes.caseStatusText}>
				{getCountryByCode(theCase.country)}
			</Typography>
		)
	}
}
