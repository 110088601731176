import { makeStyles } from '@material-ui/styles'

import { Theme } from '@material-ui/core'
import { dark10, dark5 } from 'lib/Colors'

export default makeStyles(
	(theme: Theme) => ({
		page: {
			display: 'flex',
			minHeight: '100vh',
			flexDirection: 'row',
		},
		form: {
			width: '60%',
			minHeight: 320,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		selfStart: {
			alignSelf: 'flex-start',
		},
		formTitle: {
			marginBottom: 10,
		},
		formSubtitle: {
			marginBottom: 20,
		},
		leftCol: {
			flex: 1,
			backgroundColor: 'white',
			minWidth: 400,
		},
		listWrap: {
			backgroundColor: dark5,
			padding: 10,
			border: `1px solid ${dark10}`,
			borderRadius: 6,
			minHeight: 400,
			minWidth: 350,
		},
		listItem: {
			backgroundColor: '#fff',
			borderRadius: 6,
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
			height: 50,
			marginBottom: 10,
			'&:last-child': {
				marginBottom: 0,
			},
		},
		bottomRow: {
			marginTop: 20,
		},
		bottom: {
			position: 'absolute',
			bottom: theme.spacing(4.5),
		},
	}),
	{ index: 1 },
)
