import React, { Fragment, ReactElement, useCallback } from 'react'
import { Typography } from '@material-ui/core'
import { Col, PrimaryButton } from '@copyrightagent/basic-components'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getListUrlBasedOnListType } from 'routes/RouteUrls'
import { EListType } from 'lib/entities/Case'
import {
	clearFiltersFromURL,
	filtersIsSet,
	getCurrentListTypeInUrl,
	searchIsSet,
} from 'lib/utils'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import useStyle from './style'

interface NoCasesMessageProps {
	title: string
	message: string
	actionMessage?: string
	button?: ReactElement
}

export default function NoCases(): ReactElement {
	const listType = getCurrentListTypeInUrl()
	const filterActive = filtersIsSet(false)
	const searchActive = searchIsSet()
	const classes = useStyle()
	const router = useRouter()
	const { t } = useTranslation()
	const {
		onHold: { count: onHoldCount },
	} = useCaseStatistics()

	const goToOnHold = useCallback(
		() =>
			router.history.push(
				getListUrlBasedOnListType({
					listType: EListType.onHold,
					resetFilter: true,
				}),
			),
		[router.history],
	)

	const NoCasesMessage = useCallback(
		(messageProps: NoCasesMessageProps) => (
			<Col align="center" style={{ flex: 1 }}>
				<Typography variant="h5" className={classes.errorTitle}>
					{messageProps.title}
				</Typography>
				<Typography className={classes.errorMessage}>
					{messageProps.message}
				</Typography>
				{messageProps.actionMessage ? (
					<Typography className={classes.actionMessage}>
						{messageProps.actionMessage}
					</Typography>
				) : (
					<></>
				)}
				{messageProps.button ?? <></>}
			</Col>
		),
		[classes],
	)

	const content = useCallback(() => {
		if (listType === EListType.onHold) {
			if (searchActive) {
				return (
					<NoCasesMessage
						title={t('inReview/search/noCasesTitle')}
						message={t('inReview/search/noCasesMessage')}
					/>
				)
			}
			return (
				<NoCasesMessage
					title={t('caseList/onHold/noCases/title')}
					message={t('caseList/onHold/noCases/message')}
				/>
			)
		}
		if (filterActive) {
			return (
				<NoCasesMessage
					title={t('inReview/activeFilter/noCasesTitle')}
					message={t('inReview/activeFilter/noCasesMessage')}
					button={
						<PrimaryButton
							className={classes.resetButton}
							text={t('inReview/activeFilter/noCasesResetFilter')}
							onClick={() => clearFiltersFromURL(router)}
						/>
					}
				/>
			)
		}
		if (searchActive) {
			return (
				<NoCasesMessage
					title={t('inReview/search/noCasesTitle')}
					message={t('inReview/search/noCasesMessage')}
				/>
			)
		}
		if (onHoldCount !== 0) {
			return (
				<NoCasesMessage
					title={t('inReview/noCasesTitle')}
					message={t('inReview/noCasesMessage')}
					actionMessage={
						onHoldCount === 1
							? t('caseList/inReview/onHoldCases/single')
							: t('caseList/inReview/onHoldCases/plural', {
									countOnHold: onHoldCount,
							  })
					}
					button={
						<PrimaryButton
							className={classes.button}
							type="button"
							onClick={goToOnHold}
							text={t('putOnHold/goToHold')}
						/>
					}
				/>
			)
		}
		return (
			<NoCasesMessage
				title={t('inReview/noCasesTitle')}
				message={t('inReview/noCasesMessage')}
			/>
		)
	}, [
		listType,
		searchActive,
		t,
		filterActive,
		classes,
		router,
		onHoldCount,
		goToOnHold,
	])

	return (
		<Col align="center" style={{ flex: 1 }} className={classes.container}>
			{content()}
		</Col>
	)
}
