import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export default makeStyles(
	(theme: Theme) => ({
		pageContainer: {
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		widgetRow: {
			width: '100%',
			'& > div': {
				backgroundColor: '#fff',
				borderRadius: 6,
				boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
				height: 337,
				flex: 1,
				padding: 24,
				'&:not(:first-child)': {
					marginLeft: 12,
				},
			},
			'&:not(:first-child)': {
				marginTop: 12,
			},
		},
	}),
	{ index: 1 },
)
