import { Modal } from '@copyrightagent/basic-components'
import React, {
	ReactElement,
	useEffect,
	useState,
	Dispatch,
	SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import UploadImages from './UploadImages'

interface UploadModalProps {
	opened: boolean
	setDialogOpened: Dispatch<SetStateAction<boolean>>
	setForUpload: () => void
}

const UploadModal = ({
	opened,
	setDialogOpened,
	setForUpload,
}: UploadModalProps): ReactElement => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)

	useEffect(() => {
		setOpen(opened)
	}, [opened])

	const handleClose = () => {
		setOpen(false)
		setDialogOpened(false)
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			title={t('images/uploadImages/title')}
			titleText={t('images/uploadImages/description')}
			size="large"
		>
			<UploadImages setForUpload={setForUpload} handleClose={handleClose} />
		</Modal>
	)
}

export default UploadModal
