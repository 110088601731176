import { makeStyles } from '@material-ui/core'
import Color from 'color'
import { dark5, shadowGreenColor, dark50, dark75 } from 'lib/Colors'

export const useStyle = makeStyles(
	() => ({
		container: {
			display: 'flex',
			flexDirection: 'column',
			maxHeight: 'calc(100vh - 72px)',
			position: 'sticky',
			top: 72,
			flex: 0.3,
			maxWidth: 330,
			padding: 24,
			backgroundColor: '#fff',
			borderTop: `1px solid ${dark5}`,
			marginTop: 72,
		},
		rightPanelContent: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			justifyContent: 'space-between',
			overflow: 'auto',
		},
		title: {
			color: '#293241',
			fontWeight: 600,
			marginBottom: 12,
			fontSize: 16,
			lineHeight: '22px',
		},
		fullWidthButton: {
			flex: 'unset !important',
			marginBottom: 12,
			height: 50,
		},
		fullWidthButtonSeleted: {
			flex: 'unset !important',
			marginBottom: 12,
			height: 50,
			pointerEvents: 'none',
		},
		infringementType: {
			marginBottom: 12,
		},
		loader: {
			alignSelf: 'center',
		},
		comment: {
			color: dark50,
			width: '100%',
			fontSize: 14,
			fontWeight: 600,
			'& > label': {
				'&[data-shrink="true"]': {
					display: 'none',
				},
			},
		},
		rightSideComment: {
			marginTop: 12,
			marginBottom: 12,
		},
		rejectButton: {
			minWidth: 50,
			width: 50,
			height: 50,
			marginRight: 12,
		},
	}),
	{ index: 1 },
)

export const useSelectableButtonStyle = makeStyles(
	{
		root: {
			flex: 1,
			padding: 20,
			height: 50,
		},
		contained: {
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: Color(dark5).darken(0.1).string(),
			},
		},
		containedSelected: {
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none',
			},
		},
		containedPrimary: {
			backgroundColor: dark75,
			textTransform: 'none',
			'&:hover': {
				boxShadow: `2px 2px 4px ${shadowGreenColor}`,
			},
		},
	},
	{ index: 1 },
)
