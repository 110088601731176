import { makeStyles } from '@material-ui/core'
import { dark, dark10, dark75 } from 'lib/Colors'

export const useStyles = makeStyles(() => ({
	activityItemWrap: {
		paddingBottom: 20,
		marginBottom: 20,
		borderBottom: `1px solid ${dark10}`,
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	activityItemText: {
		fontWeight: 400,
		color: dark75,
	},
	activityItemTitle: {
		width: '100%',
		marginBottom: 8,
	},
	activityItemBody: {
		padding: '0 20px 0 22px',
	},
	activityItemNote: {
		padding: 20,
		border: `1px solid ${dark10}`,
		borderRadius: 6,
		marginTop: 10,
		width: '100%',
		fontWeight: 400,
	},
	activityItemName: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		paddingRight: 8,
		flexGrow: 1,
		fontWeight: 600,
		color: dark,
	},
	activityItemDate: {
		fontSize: 14,
		fontWeight: 400,
		whiteSpace: 'nowrap',
		color: dark75,
	},
	black: {
		color: dark,
	},
}))
