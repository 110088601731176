import { makeStyles } from '@material-ui/styles'

import { Theme } from '@material-ui/core'
import { dark50 } from 'lib/Colors'

export default makeStyles(
	(theme: Theme) => ({
		page: {
			display: 'flex',
			height: '100vh',
			justifyContent: 'center',
			alignItems: 'center',
		},
		logo: {
			marginTop: 48,
			marginLeft: 48,
			position: 'absolute',
		},
		terms: {
			color: dark50,
			fontWeight: 400,
			fontSize: 14,
		},
		termsLink: {
			color: 'black',
			fontSize: 14,
			fontWeight: 400,
		},
		bottom: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			maxWidth: 750,
			textAlign: 'center',
			position: 'absolute',
			bottom: theme.spacing(4.5),
		},
	}),
	{ index: 1 },
)
