import { makeStyles } from '@material-ui/core'

export default makeStyles(
	() => ({
		softClaim: {
			marginTop: 24,
		},
	}),
	{ index: 1 },
)
