import { makeStyles } from '@material-ui/styles'

import { dark10, dark50 } from 'lib/Colors'

export default makeStyles(
	() => ({
		page: {
			display: 'flex',
			overflow: 'auto',
			height: '100vh',
			alignItems: 'center',
			justifyContent: 'center',
		},
		title: {
			width: 600,
		},
		titleText: {
			fontWeight: 600,
			marginBottom: 12,
		},
		infoBubble: {
			position: 'absolute',
			top: 53,
			right: 60,
		},
		logo: {
			marginTop: 48,
			marginLeft: 48,
			position: 'absolute',
		},
		formTitle: {
			paddingBottom: 10,
		},
		titleSubText: {
			color: dark50,
			paddingBottom: 24,
		},
		subtitleText: {
			fontSize: 16,
			fontWeight: 600,
			marginBottom: 24,
		},
		countrySelect: {
			display: 'flex',
			width: 600,
			borderRadius: 6,
			padding: 48,
			border: `1px solid ${dark10}`,
			flexDirection: 'column',
			justifyContent: 'space-around',
		},
	}),
	{ index: 1 },
)
