import { makeStyles } from '@material-ui/core'
import { dark, dark10, dark50 } from 'lib/Colors'

export default makeStyles(
	{
		tooltip: {
			borderRadius: 6,
			boxShadow:
				'0px 0px 1px rgba(41, 56, 51, 0.24), 10px 10px 40px -5px rgba(41, 56, 51, 0.12)',
			position: 'absolute',
			padding: 8,
			backgroundColor: 'white',
		},
		tooltipTitle: {
			color: dark50,
			fontSize: 14,
		},
		tooltipDivider: {
			display: 'block',
			height: 1,
			width: '100%',
			margin: '4px 0',
			backgroundColor: dark10,
		},
		tooltipValue: {
			color: dark,
			fontSize: 14,
		},
	},
	{ index: 1 },
)
