import { CSSProperties } from 'react'
import { AnyAction } from 'redux'

export const OPEN_SNACK = 'SNACKBAR/OPEN'
export const CLOSE_SNACK = 'SNACKBAR/CLOSE'

export interface ISnackbarState {
	opened: boolean
	message: string
	variant: 'success' | 'error' | 'warning' | 'info'
	style?: CSSProperties
}

const initialState: ISnackbarState = {
	opened: false,
	message: '',
	variant: 'info',
}

export default function snakbarReducer(
	state = initialState,
	action: AnyAction,
): ISnackbarState {
	switch (action.type) {
		case OPEN_SNACK:
			return {
				...state,
				opened: true,
				message: action.message,
				variant: action.variant,
				style: action.style,
			}
		case CLOSE_SNACK:
			return {
				...state,
				opened: false,
				message: '',
				variant: 'info',
				style: undefined,
			}

		default:
			return state
	}
}
