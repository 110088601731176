import { useEffect } from 'react'

import { IRootState } from 'store'
import getStore from 'store/store'
import {
	IDashboardState,
	IReviewTargetStats,
	SET_DASHBOARD_DATA_LOADED,
	SET_REVIEW_TARGET_DASHBOARD_DATA,
} from 'store/dashboard'
import { useSelector } from 'react-redux'
import { getDashboardApi } from '../routes/Private/Dashboard/api'

const { store } = getStore()
const { dispatch } = store

export function useDashboard(): IDashboardState {
	return useSelector((state: IRootState) => state.DashboardState)
}

function setReviewTargetStats(data: IReviewTargetStats) {
	dispatch({ type: SET_REVIEW_TARGET_DASHBOARD_DATA, reviewTarget: data })
}

function setLoaded(loaded: boolean) {
	dispatch({ type: SET_DASHBOARD_DATA_LOADED, loaded })
}

async function getDashboardData(): Promise<void> {
	setLoaded(false)

	const { data, isSuccessful } = await getDashboardApi()
	if (!isSuccessful || data.status === 'Not authenticated.') return
	setReviewTargetStats(data.reviewStatistics)

	setLoaded(true)
}

export default function useGetDashboard(): void {
	useEffect(() => {
		getDashboardData()
	}, [])
}
