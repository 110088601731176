import React, { ReactElement } from 'react'
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	ChartOptions,
	Title,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { green, red100 } from 'lib/Colors'
import { Col } from '@copyrightagent/basic-components'
import { useTranslation } from 'react-i18next'
import { useDashboard } from 'hooks/useDashboard'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

export const TargetMeter = (): ReactElement => {
	const { reviewTarget } = useDashboard()
	const { t } = useTranslation()

	const approvedThisMonth = reviewTarget.approvedThisMonth?.totalInMonth
	const rejectedThisMonth = reviewTarget.rejectedCountThisMonth
	const data = {
		labels: [
			t('dashboard/targetMeter/rejected'),
			t('dashboard/targetMeter/approved'),
		],
		datasets: [
			{
				data: [rejectedThisMonth, approvedThisMonth],
				backgroundColor: [red100, green],
				borderWidth: 2,
				borderColor: [red100, green],
				borderRadius: 6,
				circumference: 180,
				rotation: 270,
			},
		],
	}
	const options: ChartOptions<'doughnut'> = {
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					usePointStyle: true,
					font: {
						size: 10,
					},
					padding: 15,
				},
			},
			title: {
				display: true,
				text: t('dashboard/targetMeter/title'),
				align: 'start',
				font: {
					size: 14,
					weight: '600',
				},
			},
			tooltip: { displayColors: false },
		},
		cutout: '80%',
		responsive: true,
		maintainAspectRatio: false,
	}

	const textCenter = {
		id: 'textCenter',
		beforeDatasetDraw(chart: { getDatasetMeta?: any; ctx?: any; data?: any }) {
			const { ctx, data } = chart
			ctx.save()
			ctx.font = 'bold 44px open sans'
			ctx.textAlign = 'center'
			ctx.textBaseline = 'middle'
			ctx.fillText(
				data.datasets[0].data[0] + data.datasets[0].data[1],
				chart.getDatasetMeta(0).data[0].x,
				chart.getDatasetMeta(0).data[0].y - 50,
			)
		},
	}
	return (
		<Col justify="space-between" style={{ maxWidth: 436 }}>
			<Col style={{ position: 'relative', width: '100%', height: '100%' }}>
				<Doughnut
					id="myChart"
					data={data}
					options={options}
					plugins={[textCenter]}
				/>
			</Col>
		</Col>
	)
}
