import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NonViolatingReason, nonViolatingReasons } from 'lib/entities/Case'
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import RadioButton from './RadioButton'

type OtherProps = {
	onUpdateNonViolationReason: (value: NonViolatingReason) => void
}

export default function Other({
	onUpdateNonViolationReason,
}: OtherProps): ReactElement {
	const { t } = useTranslation()

	return (
		<RadioButton
			id="radio-whitelist"
			value={nonViolatingReasons.other}
			label={t('nonInfringement/other')}
			toolTipText={t('nonInfringement/other/tooltip')}
			onChange={() => onUpdateNonViolationReason(nonViolatingReasons.other)}
		/>
	)
}
