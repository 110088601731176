import React, { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from '@copyrightagent/basic-components'
import { ICase } from 'lib/entities/Case'
import { toast } from 'store/utils'
import { approveRejectedCase } from '../InReview/api'
import resolveSnackMessage from '../InReview/resolveSnackMessage'

interface Props {
	theCase: ICase
	setTheCase: React.Dispatch<React.SetStateAction<ICase>>
}

export default function CaseAction(props: Props): ReactElement {
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)

	async function reopenAndApproveCase() {
		setLoading(true)

		const response = await approveRejectedCase(props.theCase.violation_id)

		if (!response.isSuccessful) {
			return toast(t('caseActions/error'), 'warning')
		}

		toast(resolveSnackMessage(response.data.theCase), 'info')
		props.setTheCase({ ...props.theCase, ...response.data.theCase })
	}

	if (!props.theCase.non_violating_reason) return <></>

	return (
		<SubmitButton
			loading={loading}
			tooltipText={t('caseActions/undoRejection')}
			onClick={reopenAndApproveCase}
			text={t('caseActions/reopenAndApproveCase')}
			loadingText={t('inReview/processing')}
			buttonStyle={{
				defaultCSS: {
					marginTop: 15,
				},
			}}
		/>
	)
}
