import { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { createClient, IClient } from 'lib/entities/Client'
import { createClaims, IClaims } from 'lib/entities/Claims'
import { createUser, IUser } from 'lib/entities/User'
import { sleep } from 'lib/utils'
import { getAuth, signOut } from 'firebase/auth'

import { RouteUrls } from 'routes/RouteUrls'
import { IRootState } from 'store'
import getStore from './store'
import { CLOSE_SNACK, ISnackbarState, OPEN_SNACK } from './snackbar'
import { IGlobalState } from './global'
import { LOG_OUT, SET_TOKEN } from './auth'

const { store } = getStore()

export const getCurrentUser = (): IUser =>
	createUser(store.getState().AuthState.currentUser)

export const getUserClaims = (): IClaims =>
	createClaims(store.getState().AuthState.claims)

export const hasUserClaim = (claim: string): boolean => {
	const claims = getUserClaims()
	let claimFound = false
	Object.entries(claims).forEach(([key, value]) => {
		if (claim === key && value === true) claimFound = true
	})

	return claimFound
}

export const getSelectedClient = (): IClient =>
	createClient(store.getState().AuthState.selectedClient)

export const getSessionToken = (): string => store.getState().AuthState.token

export const setSessionToken = (): void => {
	const auth = getAuth()
	const { dispatch } = store
	const user = auth.currentUser
	if (user) {
		user.getIdToken(true).then((idToken) => {
			if (idToken !== getSessionToken())
				dispatch({ type: SET_TOKEN, token: idToken })
		})
	}
}

export const useUtils = (): IGlobalState =>
	useSelector((state: IRootState) => state.GlobalState)

export const toast = async (
	message = '',
	variant: ISnackbarState['variant'],
	duration?: number,
	style?: CSSProperties,
): Promise<void> => {
	const { dispatch } = store

	dispatch({ type: OPEN_SNACK, message, variant, style })
	await sleep(duration || 7000)
	dispatch({ type: CLOSE_SNACK })
}

export const logout = (): void => {
	const { dispatch } = store
	const auth = getAuth()
	signOut(auth)
	dispatch({ type: LOG_OUT })
	if (window.location.href.indexOf('login') === -1)
		window.location.pathname = RouteUrls.login
}
