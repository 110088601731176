import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NonViolatingReason, nonViolatingReasons } from 'lib/entities/Case'
import RadioButton from './RadioButton'

type HandoutProps = {
	onUpdateNonViolationReason: (value: NonViolatingReason) => void
}

export default function Handout({
	onUpdateNonViolationReason,
}: HandoutProps): ReactElement {
	const { t } = useTranslation()

	return (
		<RadioButton
			id="radio-whitelist"
			value={nonViolatingReasons.handout}
			label={t('nonInfringement/handout')}
			toolTipText={t('nonInfringement/handout/tooltip')}
			onChange={() => onUpdateNonViolationReason(nonViolatingReasons.handout)}
		/>
	)
}
