import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

export default makeStyles(
	(theme: Theme) => ({
		container: {
			flex: 1,
			flexGrow: 1,
			marginRight: 0,
			[theme.breakpoints.up('lg')]: {
				minWidth: 1000,
				width: '100%',
				overflowX: 'hidden',
			},
		},
	}),
	{ index: 1 },
)
