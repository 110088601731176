import axios, {
	AxiosError,
	AxiosRequestConfig,
	AxiosResponse,
	ResponseType,
} from 'axios'
import {
	getSelectedClient,
	getSessionToken,
	setSessionToken,
} from 'store/utils'
import Response, { createResponse } from './Response'

const { REACT_APP_API_URL: API_URL, REACT_APP_API_PREFIX: API_PREFIX } =
	process.env

type requestConfig = JSONPayload | FormData

export type JSONPayload = {
	[name: string]: any
}

type method = 'get' | 'post' | 'put' | 'patch' | 'delete'

export async function makeInternalAPIRequest(
	method: method,
	url: string,
	config?: requestConfig,
	headers?: JSONPayload,
	responseType?: ResponseType,
): Promise<Response> {
	setSessionToken()

	const defaultHeaders = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Authorization: getSessionToken(),
	}

	const defaultParams = {
		clientId: getSelectedClient()?.client_id,
	}

	const axiosParameters: AxiosRequestConfig = {
		url: `${API_URL}${API_PREFIX}${url}`,
		method,
		headers: { ...defaultHeaders, ...headers },
		responseType: responseType || 'json',
	}

	if (method === 'get' || method === 'delete')
		axiosParameters.params = { ...defaultParams, ...config }
	if (method === 'post' || method === 'put' || method === 'patch') {
		axiosParameters.params = defaultParams
		axiosParameters.data = config
	}

	return new Promise((resolve) => {
		axios(axiosParameters)
			.then((response: AxiosResponse) => resolve(createResponse(response)))
			.catch((error: AxiosError) => {
				if (!error.response) return
				return resolve(createResponse(error.response))
			})
	})
}

export const buildPhotoURL = (photoId: string, width = 100): string =>
	`${API_URL}${API_PREFIX}/resource/photo/${photoId}/${width}?token=${getSessionToken()}`

export const buildCollectionImageURL = (imageId: string, width = 100): string =>
	`${API_URL}${API_PREFIX}/image-upload/${imageId}/${width}?token=${getSessionToken()}`
export const buildCollectionFullsizeImageURL = (imageId: string): string =>
	`${API_URL}${API_PREFIX}/image-upload/${imageId}?token=${getSessionToken()}`
export const buildScreenshotURL = (violationId: string, size = 100): string =>
	`${API_URL}${API_PREFIX}/violations/screenshot/${size}/${violationId}`
