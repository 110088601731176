import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { yellow } from 'lib/Colors'

const useStyles = makeStyles(
	(theme: Theme) => ({
		success: {
			backgroundColor: theme.palette.primary.main,
			boxShadow: '10px 10px 40px rgba(72, 210, 160, 0.25)',
		},
		error: {
			backgroundColor: theme.palette.error.main,
			boxShadow: '10px 10px 40px rgba(222, 69, 91, 0.25)',
		},
		info: {
			backgroundColor: 'white',
			color: theme.palette.text.primary,
			boxShadow:
				'0px 0px 1px rgba(41, 56, 51, 0.24), 10px 10px 40px -5px rgba(41, 56, 51, 0.12)',
		},
		warning: {
			backgroundColor: yellow,
			color: theme.palette.text.primary,
			boxShadow: '10px 10px 40px rgba(248, 197, 28, 0.25)',
		},
		messageError: {
			color: theme.palette.text.secondary,
		},
	}),
	{ index: 1 },
)

export default useStyles
