import { Col, InfoIconWithTooltip, Row } from '@copyrightagent/basic-components'
import { Typography } from '@material-ui/core'
import { useDashboard } from 'hooks/useDashboard'
import { dark75 } from 'lib/Colors'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Chart from './Chart'
import useStyle from './style'

export default function TargetStatistics(): ReactElement {
	const classes = useStyle()
	const { t } = useTranslation()
	const { reviewTarget } = useDashboard()

	return (
		<Col justify="space-between">
			<Row justify="space-between" className={classes.titleRow}>
				<Typography variant="body2" className={classes.title}>
					{t('dashboard/targetStats/title')}
					<InfoIconWithTooltip
						iconColor={dark75}
						styleIcon={{ width: 16, height: 16, marginLeft: 5 }}
						content={t('dashboard/targetStats/infoBox')}
					/>
				</Typography>
				<Typography variant="body2" className={classes.title}>
					{t('dashboard/targetStats/target', {
						target: reviewTarget.monthlyTarget,
					})}
				</Typography>
			</Row>
			<Row style={{ width: '100%', height: 337, paddingTop: 24 }}>
				<Chart />
			</Row>
		</Col>
	)
}
