import React, { Fragment, ReactElement } from 'react'
import { CopyrightAgentLoader } from '@copyrightagent/basic-components'
import useStyle from './style'

const FrontPage = (): ReactElement => {
	const classes = useStyle()

	return (
		<Fragment>
			<div className={classes.loaderwrapper}>
				<CopyrightAgentLoader />
			</div>
		</Fragment>
	)
}

export default FrontPage
