import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { useSelectableButtonStyle } from './style'

interface IProps extends ButtonProps {
	selected: boolean
	label: string
	className: string
}

export default function SelectableButton(props: IProps): ReactElement {
	const { className, selected, label, ...restProps } = props
	const classes = useSelectableButtonStyle()

	return (
		<Button
			variant={'contained'}
			color={selected ? 'primary' : 'default'}
			classes={{
				root: clsx(classes.root, className),
				contained: selected ? classes.containedSelected : classes.contained,
				containedPrimary: classes.containedPrimary,
			}}
			disableTouchRipple
			{...restProps}
		>
			{label}
		</Button>
	)
}

SelectableButton.defaultProps = {
	selected: false,
	label: 'label',
	className: '',
}
