import { makeStyles } from '@material-ui/styles'
import NotFoundImage from 'assets/images/page-not-found.png'
import { dark5, dark50 } from 'lib/Colors'

export default makeStyles(
	() => ({
		page: {
			backgroundColor: dark5,
		},
		title: {
			marginTop: 24,
			marginBottom: 8,
			padding: 0,
		},
		pageNotExistsText: {
			color: dark50,
			marginBottom: 16,
			fontWeight: 600,
		},
	}),
	{ index: 1 },
)

export const notFoundImageProps = {
	disableSpinner: true,
	style: {
		paddingTop: 0,
		width: 300,
		height: 178,
		backgroundColor: 'rgba(255,255,255,0)',
	},
	imageStyle: { objectFit: 'contain' },
	src: NotFoundImage,
}
