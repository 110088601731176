import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Row,
	PageBody,
	PageTitle,
	PageContainer,
} from '@copyrightagent/basic-components'
import { getCaseActions } from 'hooks/useCase'
import useSetPageTitle from 'hooks/useSetPageTitle'
import CaseToReview from './CaseToReview'
import CasePageTitle from './CasePageTitle'
import CaseActions from './CaseActions'
import { useStyle } from './style'
import CasePageInfo from './CasePageInfo'
import Navigation from '../HomeLayout/Navigation'

interface Props {
	match: {
		params: {
			caseId: string
		}
	}
}

export default function InReview(props: Props): ReactElement {
	const classes = useStyle()
	const {
		match: {
			params: { caseId },
		},
	} = props
	const { fetchCaseForInReview } = getCaseActions()
	const { t } = useTranslation()

	useSetPageTitle(t('inReview/pageTitle'), caseId)

	useEffect(() => {
		fetchCaseForInReview(caseId)
	}, [caseId])

	return (
		<>
			<Navigation />
			<PageContainer>
				<span className={classes.pageTitle}>
					<PageTitle>
						<CasePageTitle />
						<CasePageInfo />
					</PageTitle>
				</span>

				<Row align="stretch" style={{ flex: 1 }}>
					<PageBody>
						<CaseToReview />
					</PageBody>
					<CaseActions />
				</Row>
			</PageContainer>
		</>
	)
}
