import { AnyAction } from 'redux'

export interface IWorkspaceState {
	uploadQueue: File[]
	currentlyUploading: IUploadingImage[]
}
export interface IUploadingImage {
	id: string
	name: string
	uploaded: string
	size: number
	loaded: boolean
	errorMessage?: string
	src?: any
}
const initialState = {
	uploadQueue: [],
	currentlyUploading: [],
}
export const SET_UPLOAD_QUEUE = 'images/set-upload-queue'
export const RESET_UPLOAD_QUEUE = 'images/reset-upload-queue'
export const ADD_TO_UPLOADING = 'images/add-to-uploading'
export const REMOVE_FROM_UPLOADING = 'images/remove-from-uploading'
export const UPDATE_UPLOADING = 'images/update-uploading'

const folderReducer = (
	state = initialState,
	action: AnyAction,
): IWorkspaceState => {
	switch (action.type) {
		case SET_UPLOAD_QUEUE:
			return {
				...state,
				uploadQueue: [...state.uploadQueue, ...action.uploadQueue],
			}
		case RESET_UPLOAD_QUEUE:
			return {
				...state,
				uploadQueue: [],
			}
		case ADD_TO_UPLOADING:
			return {
				...state,
				currentlyUploading: [
					action.uploadingImage,
					...state.currentlyUploading,
				],
			}
		case REMOVE_FROM_UPLOADING:
			return {
				...state,
				currentlyUploading: state.currentlyUploading.filter(
					(image: IUploadingImage) => !action.selected.includes(image),
				),
			}
		case UPDATE_UPLOADING:
			return {
				...state,
				currentlyUploading: state.currentlyUploading.map(
					(image: IUploadingImage) => {
						if (image.name !== action.image.name) return image
						return { ...image, ...action.image }
					},
				),
			}
		default:
			return state
	}
}
export default folderReducer
