import React, { useEffect, useMemo, useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import createCase, { ICase } from 'lib/entities/Case'
import {
	Row,
	Col,
	PageBody,
	PageTitle,
	Typography,
	PageContainer,
	CopyrightAgentLoader,
} from '@copyrightagent/basic-components'
import ImageAndScreenshot from 'components/ImageAndScreenshot'
import CaseLocation from 'components/CaseLocation'
import CaseStatus from 'components/CaseStatus'
import useSetPageTitle from 'hooks/useSetPageTitle'
import CaseActivity from './CaseActivity'
import CaseAction from './CaseAction'
import useStyle from './style'
import { getCase } from '../Cases/api'
import Navigation from '../HomeLayout/Navigation'

interface Props {
	match: {
		params: {
			caseId: string
		}
	}
}

export default function Case(props: Props): ReactElement {
	const {
		match: {
			params: { caseId },
		},
	} = props
	const [theCase, setTheCase] = useState<ICase>(createCase())
	const [loaded, setLoaded] = useState<boolean>(true)
	const classes = useStyle()
	const { t } = useTranslation()

	useSetPageTitle(t('casePage/pageTitle'), caseId)

	useEffect(() => {
		async function fetchCase() {
			setLoaded(false)
			const { data, status } = await getCase(caseId)

			if (status >= 400) return setLoaded(true)

			setLoaded(true)
			setTheCase(createCase(data))
		}

		fetchCase()
	}, [caseId])

	const renderPageTitle = useMemo(() => {
		if (loaded && !theCase.violation_id) {
			return (
				<Typography variant="h5" className={classes.title}>
					{t('inReview/pageTitleNotFound')}
				</Typography>
			)
		}

		return (
			<Row justify="space-between">
				<Col align="flex-start">
					<Row className={classes.dynamicContentWrap}>
						<Typography variant="h5" className={classes.title}>
							{theCase.case_id}
						</Typography>
						<CaseStatus theCase={theCase} />
						<CaseLocation theCase={theCase} />
					</Row>
				</Col>
			</Row>
		)
	}, [t, loaded, theCase])

	const renderBody = useMemo(() => {
		if (loaded && !theCase.violation_id) {
			return (
				<PageBody>
					<React.Fragment></React.Fragment>
				</PageBody>
			)
		}

		return (
			<Row align="stretch" style={{ flex: 1 }}>
				<PageBody>
					<ImageAndScreenshot theCase={theCase} />
				</PageBody>
				<div className={classes.rightPanel}>
					<CaseActivity theCase={theCase} />
					<CaseAction theCase={theCase} setTheCase={setTheCase} />
				</div>
			</Row>
		)
	}, [loaded, theCase, classes.rightPanel])

	if (!loaded)
		return <CopyrightAgentLoader className={classes.absoluteCenter} />

	return (
		<>
			<Navigation />
			<PageContainer>
				<PageTitle>{renderPageTitle}</PageTitle>
				{renderBody}
			</PageContainer>
		</>
	)
}
