import { makeStyles } from '@material-ui/core'
import { dark50, dark75 } from 'lib/Colors'

export default makeStyles(
	{
		button: {
			width: '100%',
		},
		mainButton: {
			marginBottom: 12,
		},
		goToOnHoldButton: {
			fontWeight: 600,
			color: dark50,
			'&:hover': {
				color: dark75,
			},
		},
		buttonNextReview: {
			marginLeft: 8,
			paddingLeft: 15,
			paddingRight: 15,
		},
		dialogActionRow: {
			flex: 1,
		},
		link: {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'none',
				boxShadow: 'none',
			},
		},
	},
	{ index: 1 },
)
