import { useSelector } from 'react-redux'

import { IRootState } from 'store'
import {
	IWorkspaceState,
	SET_UPLOAD_QUEUE,
	RESET_UPLOAD_QUEUE,
	IUploadingImage,
	ADD_TO_UPLOADING,
	UPDATE_UPLOADING,
	REMOVE_FROM_UPLOADING,
} from 'store/workspace'

import getStore from 'store/store'
import { getImages } from 'routes/Private/Images/api'

const { store } = getStore()
const { dispatch } = store
interface ImagesActions {
	fetchImages(page: number, perPage: number): Promise<any[]>
}

export const useWorkspace = (): IWorkspaceState =>
	useSelector((state: IRootState) => state.WorkspaceState)

export const setUploadQueue = (uploadQueue: File[]): void => {
	dispatch({ type: SET_UPLOAD_QUEUE, uploadQueue })
}
export const resetUploadQueue = (): void => {
	dispatch({ type: RESET_UPLOAD_QUEUE })
}
export const addUploadingImage = (uploadingImage: IUploadingImage): void => {
	dispatch({ type: ADD_TO_UPLOADING, uploadingImage })
}
export const removeUploadingImage = (selected: IUploadingImage[]): void => {
	dispatch({ type: REMOVE_FROM_UPLOADING, selected })
}
export const updateUploadingImage = (image: IUploadingImage): void => {
	dispatch({ type: UPDATE_UPLOADING, image })
}

const fetchImages = async (page: number, perPage: number) => {
	const images = await getImages(page, perPage)
	if (images.isSuccessful) return images.data
	return []
}

export const getImagesActions = (): ImagesActions => ({
	fetchImages,
})
