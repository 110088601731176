import React, { ReactElement, Fragment } from 'react'

import { ICase } from 'lib/entities/Case'

import CaseStatusOpen from 'assets/images/case-status-open.png'
import CaseStatusClosed from 'assets/images/case-status-closed.png'
import CaseStatusWon from 'assets/images/case-status-won.png'

import useStyle from './style'

interface IProps {
	theCase: ICase
	className?: string
}

const statusMap = {
	won: CaseStatusWon,
	open: CaseStatusOpen,
	closed: CaseStatusClosed,
	created: CaseStatusOpen,
}

export default function CaseStatus({
	theCase,
	className,
}: IProps): ReactElement | null {
	const classes = useStyle()

	if (!theCase.status) return null

	return (
		<Fragment>
			{theCase.status === 'open' || theCase.status === 'created' ? (
				<div
					className={`${classes.root} ${className} ${classes.open}`}
					aria-label="case status open"
				></div>
			) : (
				<img
					alt={`case status ${statusMap[theCase.status]}`}
					width={24}
					src={statusMap[theCase.status]}
					className={`${classes.root} ${className}`}
				/>
			)}
		</Fragment>
	)
}
