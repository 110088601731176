import { EListType } from 'lib/entities/Case'
import { useSelector } from 'react-redux'
import { getCountCases } from 'routes/Private/InReview/api'
import getStore from 'store/store'
import { IRootState } from 'store'
import {
	ICaseCount,
	ICaseStatistics,
	SET_AWAITING_REVIEW_COUNT,
	SET_ON_HOLD_COUNT,
} from 'store/caseStatistics'

const { store } = getStore()
const { dispatch } = store

export function useCaseStatistics(): ICaseStatistics {
	return useSelector((state: IRootState) => state.CaseStatisticsState)
}

export function setAwaitingReviewCount(data: ICaseCount): void {
	if (data.count && typeof data.count !== 'number')
		data.count = parseInt(data.count, 10)
	dispatch({ type: SET_AWAITING_REVIEW_COUNT, awaitingReview: data })
}

export function setOnHoldCount(data: ICaseCount): void {
	if (data.count && typeof data.count !== 'number')
		data.count = parseInt(data.count, 10)
	dispatch({ type: SET_ON_HOLD_COUNT, onHold: data })
}

interface ICaseCountResponse {
	data: {
		count: number
		status: string
	}
	isSuccessful: boolean
}

export async function getAwaitingReviewCount(): Promise<void> {
	setAwaitingReviewCount({ loaded: false })
	const { data, isSuccessful }: ICaseCountResponse = await getCountCases({
		listType: EListType.awaitingReview,
	})
	if (!isSuccessful || data.status === 'Not authenticated.') return
	setAwaitingReviewCount({
		loaded: true,
		count: data.count,
	})
}

export async function getOnHoldCount(): Promise<void> {
	setOnHoldCount({ loaded: false })

	const { data, isSuccessful }: ICaseCountResponse = await getCountCases({
		listType: EListType.onHold,
	})
	if (!isSuccessful || data.status === 'Not authenticated.') return
	setOnHoldCount({
		loaded: true,
		count: data.count,
	})
}
