import { makeStyles, Theme } from '@material-ui/core'
import { dark10, dark5, dark50, dark75, dark25 } from 'lib/Colors'

export default makeStyles<Theme>(
	() => ({
		row: {
			flex: 1,
			height: '100%',
		},
		absoluteCenter: {
			position: 'absolute',
			top: 'calc(50% - 20px)',
			left: 'calc(50% - 20px)',
		},
		arrowIcon: {
			width: 16,
			height: 16,
			color: dark75,
		},
		whitePaper: {
			backgroundColor: 'white',
			height: 'calc(100vh - 120px)',
			boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
			borderRadius: '6px',
			padding: '35px 8px 24px 24px',
			flex: 1,
		},
		whitePaperHeader: {
			marginRight: 16,
		},
		menuItem: {
			width: 'calc(100% - 10px)',
			height: 60,
			minHeight: 60,
			justifyContent: 'space-between',
			backgroundColor: dark5,
			border: `1px solid ${dark10}`,
			borderRadius: 6,
			marginBottom: 20,
			'&:last-child': {
				marginBottom: 0,
			},
		},
		selectedMenuItem: {
			backgroundColor: `${dark25} !important`,
		},
		documentItemList: {
			width: '100%',
			overflow: 'overlay',
			paddingRight: 6,
			justifyContent: 'flex-start !important',
		},
		documentItem: {
			cursor: 'pointer',
			width: 'calc(100% - 10px)',
			height: 60,
			minHeight: 60,
			backgroundColor: dark5,
			border: `1px solid ${dark10}`,
			borderRadius: 6,
			marginBottom: 20,
			padding: 14,
			'&:last-child': {
				marginBottom: 0,
			},
		},
		columnSpace: {
			marginRight: 20,
		},
		greyText: {
			color: dark50,
		},
		flex: {
			display: 'flex',
			padding: 0,
		},
		margin20: {
			margin: 20,
		},
		'marginTop-50': {
			marginTop: 50,
		},
		'marginTop-30': {
			marginTop: 30,
		},
		'marginTop-10': {
			marginTop: 10,
		},
	}),
	{ index: 1 },
)
