import { createTheme } from '@material-ui/core/styles'
import Color from 'color'
import {
	green,
	dark75,
	yellow,
	red,
	dangerColor,
	dark50,
	dark5,
	dark10,
	dark25,
	dark,
} from './Colors'

const theme = createTheme({
	palette: {
		primary: {
			light: Color(green).lighten(0.5).string(),
			main: green,
			dark: Color(green).darken(0.1).string(),
			contrastText: '#fff',
		},
		secondary: {
			light: Color(yellow).lighten(0.5).string(),
			main: yellow,
			dark: Color(yellow).darken(0.5).string(),
			contrastText: dark75,
		},
		text: {
			primary: dark,
			secondary: '#fff',
		},
		grey: {
			300: '#F4F5F5',
			400: '#B4B4B4',
		},
		error: {
			light: red,
			main: red,
			dark: dangerColor,
		},
	},
	typography: {
		fontFamily: ['Open Sans'].join(','),
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1024,
			lg: 1440,
			xl: 1920,
		},
	},
	overrides: {
		MuiLink: {
			root: {
				fontFamily: 'Open Sans',
				fontSize: 14,
				'&:hover': {
					cursor: 'pointer',
				},
			},
			underlineHover: {
				color: dark50,
			},
		},
		MuiTypography: {
			root: {
				color: dark,
			},
			h1: {
				fontSize: 60,
				lineHeight: '82px',
			},
			h2: {
				fontSize: 44,
				lineHeight: '60px',
			},
			h3: {
				fontSize: 40,
				lineHeight: '54px',
			},
			h4: {
				fontSize: 24,
				lineHeight: '33.6px',
				fontWeight: 700,
				padding: 0,
			},
			h5: {
				fontSize: 24,
				lineHeight: '33px',
			},
			subtitle1: {
				fontSize: 20,
				lineHeight: '27px',
			},
			body1: {
				fontSize: 16,
				lineHeight: '22px',
			},
			body2: {
				fontSize: 14,
				lineHeight: '20px',
				fontWeight: 600,
			},
			caption: {
				fontSize: 10,
				lineHeight: '14px',
				fontWeight: 600,
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: Color(dark75).alpha(0.25).string(),
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 6,
				backgroundColor: dark5,
			},
			input: {
				fontSize: 14,
				fontWeight: 600,
				borderRadius: 6,
				'&::placeholder': {
					textOverflow: 'ellipsis !important',
					color: dark50,
					opacity: 1,
				},
			},
			notchedOutline: {
				borderColor: dark10,
			},
		},
		MuiButton: {
			root: {
				borderRadius: 6,
				padding: '10px 12px',
				textTransform: 'none',
				fontSize: 14,
				lineHeight: '20px',
				height: 40,
				minWidth: 100,
				width: 'auto',
				boxShadow: 'none',
				whiteSpace: 'nowrap',
				'&:hover, &:active, &:focus': {
					boxShadow: 'none',
				},
				'&:disabled': {
					backgroundColor: dark5,
					color: dark25,
					border: `1px solid ${dark25}`,
				},
			},
			containedPrimary: {
				'&:hover': {
					boxShadow: 'none',
				},
				'&:focus': {
					boxShadow: 'inset 0 0 2px #44BF93',
				},
			},
			contained: {
				boxShadow: 'none',
				color: dark75,
				backgroundColor: dark10,
				'&:hover': {
					backgroundColor: dark25,
					boxShadow: 'none',
				},
				'&:focus': {
					boxShadow: `inset 0 0 2px ${dark25}`,
				},
				'&:disabled': {
					backgroundColor: dark5,
				},
			},
			label: {
				fontWeight: 600,
			},
			text: {
				padding: '10px 12px',
			},
		},
		MuiFormGroup: {
			row: {
				alignItems: 'center',
				justifyContent: 'space-between',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: 10,
				marginLeft: 10,
				marginRight: 10,
				marginBottom: 10,
			},
		},
		MuiFormControlLabel: {
			labelPlacementStart: {
				marginLeft: 0,
			},
		},
		MuiInputBase: {
			root: {
				maxHeight: 50,
			},
		},
		MuiFilledInput: {
			input: {
				padding: '22px 12px 10px 12px',
			},
		},
		MuiSvgIcon: {
			root: {
				color: '#B4B4B4',
			},
		},
		MuiPaper: {
			elevation1: {
				boxShadow: '0 10px 20px 0 rgba(0,0,0,0.10)',
			},
			elevation8: {
				boxShadow: 'box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1)',
			},
		},
		MuiListItem: {
			root: {
				paddingTop: 16,
				paddingBottom: 16,
			},
		},
		MuiDialog: {
			paper: {
				padding: 32,
				width: 600,
			},
		},
		MuiDialogTitle: {
			root: {
				padding: 0,
			},
		},
		MuiDialogContent: {
			root: {
				padding: '10px 0 35px 0',
				overflowX: 'hidden',
				'@media (max-width: 1440px)': {
					padding: '10px 0 20px 0',
				},
			},
		},
		MuiDialogActions: {
			root: {
				padding: 0,
				justifyContent: 'space-between',
			},
		},
		MuiSnackbarContent: {
			root: {
				height: '50px',
				padding: '15px 25px',
				borderRadius: '6px',
				'@media (min-width: 600px)': {
					minWidth: 'auto',
				},
			},
			message: {
				padding: 0,
				fontSize: 14,
				display: 'flex',
				alignItems: 'center',
				fontWeight: 'normal',
				lineHeight: '20px',
			},
		},
	},
	transitions: {
		create: () => 'all ease-out .2s',
	},
})

export default theme
