import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/styles'
import { blue, blue10 } from 'lib/Colors'

const useStyles = makeStyles(
	{
		infoBubble: {
			color: blue,
			backgroundColor: blue10,
			fontSize: 14,
			paddingTop: 5,
			paddingLeft: 12,
			paddingBottom: 5,
			paddingRight: 12,
			borderRadius: 68,
			fontWeight: 600,
		},
	},
	{ index: 1 },
)

interface IBubbleProps {
	text: string
	className?: string
}

const InfoBubble = (props: IBubbleProps): ReactElement => {
	const classes = useStyles()
	const { text, className } = props

	return <div className={`${classes.infoBubble} ${className}`}>{text}</div>
}

export default InfoBubble
