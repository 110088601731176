import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
	useRef,
	ReactElement,
} from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Row, Col, Typography } from '@copyrightagent/basic-components'
import CaseType from 'components/CaseTypeLabel'
import CaseStatus from 'components/CaseStatus'
import { useRouter } from 'routes/CustomBrowserRouter'
import ArrowIcon from 'assets/images/arrow-right-small.png'
import { Link, useMediaQuery } from '@material-ui/core'
import createCase, { ICase, EListType } from 'lib/entities/Case'
import { dark50 } from 'lib/Colors'
import { getListUrlBasedOnListType } from 'routes/RouteUrls'
import { getCasesForList } from 'routes/Private/Cases/api'
import useStyle from './style'

interface Props {
	theCase: ICase
}

function OtherCasesByDomain({ theCase }: Props): ReactElement {
	const mediaQueryMatchesLargeHight = useMediaQuery('(min-height:2000px)')
	const perPage = mediaQueryMatchesLargeHight ? 50 : 25
	const router = useRouter()
	const [cases, setCases] = useState<ICase[]>([])
	const [domain, setDomain] = useState('')
	const [loaded, setLoaded] = useState(false)
	const [currentCase, setCurrentCase] = useState<ICase>(createCase())

	const classes = useStyle()
	const { t } = useTranslation()

	const init = useRef(false)
	useEffect(() => {
		if (init.current && currentCase.violation_id === theCase.violation_id)
			return
		const currentDomain = theCase.domain
		setCurrentCase(theCase)
		setDomain(currentDomain)
		getCasesForList(0, 4, {
			filters: { domain: currentDomain },
			listType: EListType.reviewed,
		}).then((response) => {
			if (response.isSuccessful) {
				const filteredCases = response.data.cases.filter(
					(c) => c.violation_id !== theCase.violation_id,
				)
				setCases(filteredCases)
			}
			setLoaded(true)
		})
		init.current = true
	}, [getCasesForList, setCases, theCase, currentCase, perPage])

	const handleGoToDomainCases = useCallback(() => {
		const pathname = getListUrlBasedOnListType({
			listType: EListType.all,
			filters: { domain },
			resetFilter: true,
		})

		router.history.push(pathname)
	}, [router.history, domain])

	const renderDomainCases = useMemo<any>(() => {
		if (!cases.length && !loaded) {
			return (
				<Col>
					<Typography className={clsx(classes.textItem, classes.loading)} />
					<Typography className={clsx(classes.textItem, classes.loading)} />
					<Typography className={clsx(classes.textItem, classes.loading)} />
				</Col>
			)
		}

		if (!cases.length && loaded) {
			return (
				<Col>
					<Typography
						variant="caption"
						fontStyle="italic"
						fontColor={dark50}
						className={classes.textItem}
					>
						{t('caseList/byDomain/noCases')}
					</Typography>
				</Col>
			)
		}

		return cases.map((item, index) => {
			if (index > 2) return null
			return (
				<Row
					key={item.violation_id}
					justify="space-between"
					className={clsx(classes.fullWidth, classes.marginBottom10)}
				>
					<Row>
						<Typography className={classes.byDomainCaseId}>
							{item.case_id}
						</Typography>
					</Row>
					<Row>
						<CaseType theCase={item} />
						<CaseStatus className={classes.caseStatusStyle} theCase={item} />
					</Row>
				</Row>
			)
		})
	}, [cases, classes, loaded, t])

	return (
		<Col className={clsx(classes.infoCol, classes.byDomainPaper)}>
			<Row justify="space-between" className={classes.fullWidth}>
				<Typography className={clsx(classes.textItem, classes.semiBold)}>
					{t('inReview/casesByDomain/title')}
				</Typography>
				<Link onClick={handleGoToDomainCases} className={classes.link}>
					<Typography className={clsx(classes.viewAllText, classes.semiBold)}>
						{t('inReview/viewAll')}
						<img src={ArrowIcon} alt="arrow" className={classes.arrowImage} />
					</Typography>
				</Link>
			</Row>
			{renderDomainCases}
		</Col>
	)
}
export default OtherCasesByDomain
