import React, { Fragment, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
	Row,
	Col,
	Typography,
	CopyrightAgentLoader,
} from '@copyrightagent/basic-components'
import { CreatedCaseRow } from 'routes/Private/Cases/components/CreatedCaseRow'
import useStyle from './style'
import NoCreatedCases from './NoCreatedCases'

interface NewCase {
	case_id: string
	status: string
	image_id: string
	creation_date: Date
}
interface CreatedCaseListProps {
	cases: NewCase[]
	hasMore: boolean
	loadNextCases(): void
	createCase(): void
	loaded: boolean
}

const CreatedCaseList = (props: CreatedCaseListProps): ReactElement => {
	const classes = useStyle()
	const { t } = useTranslation()
	const { cases, hasMore, loadNextCases, createCase, loaded } = props

	return (
		<Fragment>
			{!loaded ? (
				<Row className={classes.spinner}>
					<CopyrightAgentLoader />
				</Row>
			) : (
				<></>
			)}
			{loaded && cases.length === 0 ? (
				<NoCreatedCases createCase={createCase} />
			) : (
				<></>
			)}
			{loaded && cases.length ? (
				<Row className={classes.tableHeadRow}>
					<Col className={classes.tableHeadCellFirst}>
						<Typography className={classes.tableHeadText}>
							{t('createCase/data')}
						</Typography>
					</Col>
				</Row>
			) : (
				<></>
			)}
			{loaded && cases.length ? (
				<InfiniteScroll
					className={classes.infiniteScrollWrap}
					dataLength={cases.length}
					next={() => loadNextCases()}
					hasMore={hasMore}
					loader={
						<div key={0} className={classes.loadMoreWrap}>
							<CopyrightAgentLoader />
						</div>
					}
				>
					{cases.map((theCase: any) => (
						<CreatedCaseRow key={theCase.submitted_case_id} theCase={theCase} />
					))}
				</InfiniteScroll>
			) : (
				<></>
			)}
		</Fragment>
	)
}
export default CreatedCaseList
