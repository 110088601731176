import React, { ReactElement, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ICase, isCasePreapproved } from 'lib/entities/Case'
import { TextLabel } from '@copyrightagent/basic-components'

interface IProps {
	theCase: ICase
	className?: string | undefined
}

export default function CaseLocation({ theCase }: IProps): ReactElement | null {
	const { t } = useTranslation()
	if (!theCase.violation_id || !theCase.status || theCase.customer_approve_time)
		return null
	function displayLabel(): ReactElement {
		if (theCase.on_hold)
			return (
				<TextLabel
					uppercase
					color="blue"
					labelText={t('caseRow/labels/onHold')}
				/>
			)
		if (isCasePreapproved(theCase))
			return (
				<TextLabel
					uppercase
					color="grey"
					labelText={t('caseRow/labels/preapproved')}
				/>
			)
		return (
			<TextLabel
				uppercase
				color="grey"
				labelText={t('caseRow/labels/inReview')}
			/>
		)
	}

	return <Fragment>{displayLabel()}</Fragment>
}
