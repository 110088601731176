import {
	BulkReviewCaseType,
	getOriginalImageUrl,
	ICase,
} from 'lib/entities/Case'

const { REACT_APP_SITE_URL } = process.env

const trackEvent = (
	eventName: string,
	body: Record<string, unknown> = {},
): void => {
	const location = `${REACT_APP_SITE_URL}${window.location.pathname}`
	window.analytics.track(eventName, { ...body, location })
}

const trackSubmitCaseEvent = (theCase: ICase): void => {
	trackEvent('Submit a case in Manual Review', {
		caseId: theCase.case_id,
		violation: theCase.is_violating,
		violationLink: theCase.url_raw,
		caseType: theCase.is_violating
			? theCase.violation_type
			: theCase.non_violating_reason,
		softClaim: theCase.soft_claim,
		originalImage: getOriginalImageUrl(theCase),
		comment: theCase.comment,
		dateSubmitted: new Date(),
	})
}

const trackCaseReviewEvent = (eventName: string, theCase?: ICase): void => {
	// if data about the case is not provided, we don't want to track it
	if (!theCase) return trackEvent(eventName)

	trackEvent(eventName, {
		caseId: theCase.case_id,
		originalImage: getOriginalImageUrl(theCase),
		violationLink: theCase.url_raw,
	})
}

type BulkSubmitEventArgs = {
	caseIds: string[]
	violation: boolean
	caseType: BulkReviewCaseType
	softClaim: boolean
	casesAmount: number
	comment?: string
}

const trackBulkReviewSubmission = (args: BulkSubmitEventArgs): void => {
	trackEvent('Submit a case in Bulk Review', args)
}

export interface UserIdenfitication {
	email: string
	name: string
	id: string
	regDate: Date
}

const identifyUser = (user: UserIdenfitication): void => {
	window.analytics.identify(`${user.email}`, user)
}

export {
	identifyUser,
	trackCaseReviewEvent,
	trackSubmitCaseEvent,
	trackBulkReviewSubmission,
}
export default trackEvent
