import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PageContainer } from '@copyrightagent/basic-components'
import CaseListView from 'components/CaseListView'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { EListType } from 'lib/entities/Case'
import useStyle from './style'
import Navigation from '../HomeLayout/Navigation'

interface IProps {
	listType: EListType
}

export default function CasesPage({ listType }: IProps): ReactElement {
	const classes = useStyle()
	const { t } = useTranslation()

	useSetPageTitle(t(`caseList/${EListType[listType]}/pageTitle`))

	const caseListView = useMemo(
		() => <CaseListView listType={listType} />,
		[listType],
	)

	return (
		<>
			<Navigation />
			<PageContainer className={classes.container}>
				{caseListView}
			</PageContainer>
		</>
	)
}
