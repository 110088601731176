import React, { ReactElement, useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	Icons,
	Checkbox,
	Typography,
	Input,
	PageContainer,
	DropdownSelect,
} from '@copyrightagent/basic-components'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import clsx from 'clsx'

import { SWEDISH_ORGANIZATION_NUMBER } from 'lib/utils'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import trackEvent from 'hooks/useAnalytics'
import InfoBubble from 'components/InfoBubble'
import Footer from 'components/Footer'
import { toast } from 'store/utils'
import getStore from 'store/store'
import { createUser, getUnions } from './api'
import useStyle from './style'

const CreateAccount = (): ReactElement => {
	const router = useRouter()
	const classes = useStyle()
	const { store } = getStore()
	const { t } = useTranslation()

	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [union, setUnion] = useState('')
	const [unionId, setUnionId] = useState('')
	const [companyId, setCompanyId] = useState('')
	const [idNoError, setIdNoError] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [phoneNo, setPhoneNo] = useState('')
	const [countryUnions, setCountryUnions] = useState([])
	const [newsLetterSelected, setNewsLetterSelected] = useState<boolean>(false)
	const { country, termsAccepted } = store.getState().AuthState
	const countries = ['se']

	const unionList = [
		{
			label: 'None',
			value: '',
		},
	]
	const [unions, setUnions] = useState(unionList)

	const setupUnions = async () => {
		const unionsResponse = await getUnions(country)
		if (unionsResponse.isSuccessful) setCountryUnions(unionsResponse.data)
	}

	useEffect(() => {
		setupUnions()
	}, [])

	useEffect(() => {
		if (countryUnions) {
			countryUnions.map((newUnion: any) => {
				unionList.push({
					label: newUnion.union_name,
					value: newUnion.union_short_name,
				})
				return setUnions(unionList)
			})
		}
	}, [countryUnions])

	const onCreateUser = async () => {
		if (!SWEDISH_ORGANIZATION_NUMBER.test(companyId)) {
			setIdNoError(true)
			return toast(t('accountCreation/invalidID'), 'warning')
		}
		setLoading(true)

		if (!termsAccepted)
			return toast(t('accountCreation/invalidTerms'), 'warning')

		const result = await createUser({
			firstname,
			lastname,
			country,
			phoneNo,
			companyId,
			union,
			unionId,
			newsLetterSelected,
			termsAccepted,
		})

		if (!result.isSuccessful) {
			if (result.data.code)
				return toast(t(`accountCreation/${result.data.code}`), 'warning')
			await toast(t('accountCreation/accountCreationFailed'), 'warning')
			setLoading(false)
		}

		trackEvent('Account created', {
			date: new Date(),
			first_name: firstname,
			last_name: lastname,
			company_registration_number: companyId,
			phone: phoneNo,
			union,
			union_id: unionId,
			newsletter: newsLetterSelected,
		})

		router.history.push(`${RouteUrls.loggingIn}?newAccount=true`)
	}

	const onClickBack = () => {
		trackEvent('Back', {
			date: new Date(),
			page: router.history.location.pathname,
		})
		router.history.push(RouteUrls.terms)
	}

	const toggleNewsLetter = () => setNewsLetterSelected(!newsLetterSelected)

	const canSubmit = () => {
		if (union)
			return (
				!!firstname && !!lastname && !!companyId && !idNoError && !!termsAccepted && !!unionId
			)
		return !!firstname && !!lastname && !!companyId && !idNoError && !!termsAccepted
	}

	const formatIdNumber = (id: string) => {
		var len = id.length;
		if ((len === 6) && len >= companyId.length) {
			id = id + "-"
		}

		setCompanyId(id)

		if (!SWEDISH_ORGANIZATION_NUMBER.test(id)) {
			setIdNoError(true)
			return toast(t('accountCreation/invalidID'), 'warning')
		}
		if (idNoError) setIdNoError(false)
	}

	return (
		<PageContainer className={classes.page}>
			<Icons.Logo className={classes.logo} />
			<InfoBubble
				text={t('loginPage/earlyAccess')}
				className={classes.infoBubble}
			/>
			<Grid className={classes.title}>
				<Typography className={classes.titleText} variant="h5">
					{t('createAccount/title')}
				</Typography>
			</Grid>
			<Grid container className={classes.userInfoSection} spacing={3}>
				<Grid item xs={12}>
					<Typography className={classes.subtitleText} variant="h6">
						{t('createAccount/subTitle')}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Input
						value={firstname}
						onChange={(event) => setFirstname(event.target.value)}
						type="name"
						variant="filled"
						name="firstname"
						placeholder={t('user/firstName')}
						label={t('user/firstNameLabel')}
					/>
				</Grid>
				<Grid item xs={6}>
					<Input
						value={lastname}
						onChange={(event) => setLastname(event.target.value)}
						type="name"
						variant="filled"
						name="lastname"
						placeholder={t('user/lastName')}
						label={t('user/lastNameLabel')}
					/>
				</Grid>
				<Grid item xs={6} className={classes.select}>
					<Input
						error={idNoError}
						value={companyId}
						onChange={(event) => formatIdNumber(event.target.value)}
						type="name"
						variant="filled"
						name="companyId"
						label={t(`user/companyId/${country}`)}
						placeholder="000000-0000"
					/>
				</Grid>
				<Grid item className={classes.phone} xs={6}>
					<Typography className={classes.formTitle} variant="body2">
						{t('user/phoneNo')}
					</Typography>
					<PhoneInput
						country={country}
						value={phoneNo}
						onlyCountries={countries}
						onChange={(phone: string) => setPhoneNo(phone)}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				className={clsx(classes.userInfoSection, classes.unionSection)}
				spacing={3}
			>
				<Grid item xs={12}>
					<Typography className={classes.subtitleText} variant="h6">
						{t('createAccount/unionTitle')}
					</Typography>
				</Grid>
				<Grid item xs={6} className={classes.unionSelect}>
					<Typography className={classes.formTitle} variant="body2">
						{t('union/title')}
					</Typography>
					<DropdownSelect
						placeholder={t('createAccount/unionSelect')}
						options={unions}
						onSelect={setUnion}
					/>
				</Grid>
				{union && (
					<Grid item xs={6}>
						<Input
							value={unionId}
							onChange={(event) => setUnionId(event.target.value)}
							type="name"
							variant="filled"
							name="unionId"
							placeholder={t('union/unionPlaceholder')}
							label={t('union/unionId')}
						/>
					</Grid>
				)}
			</Grid>
			<Grid className={classes.newsLetter}>
				<Checkbox checked={newsLetterSelected} onChange={toggleNewsLetter} />
				<Typography variant="body2">{t('createAccount/newsletter')}</Typography>
			</Grid>
			<Footer
				nextLabel={t('createAccount/create')}
				handleNext={onCreateUser}
				nextDisabled={!canSubmit()}
				previousLabel={t('createAccount/back')}
				handlePrevious={onClickBack}
				loading={loading}
			/>
		</PageContainer>
	)
}

export default CreateAccount
