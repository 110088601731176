import { makeStyles } from '@material-ui/styles'

import { dark, dark5, dark10, dark75 } from 'lib/Colors'

export default makeStyles(
	() => ({
		page: {
			display: 'flex',
			overflow: 'auto',
			height: '100vh',
			alignItems: 'center',
			justifyContent: 'flex-start',
			marginBottom: 80,
		},
		paper: {
			padding: 48,
			marginBottom: 200,
			maxWidth: 750,
			backgroundColor: dark5,
			boxShadow: 'none !important',
			border: `1px solid ${dark10}`,
		},
		title: {
			width: '100%',
			zIndex: 1,
		},
		unstickyTitle: {
			backgroundColor: 'white',
		},
		titleSection: {
			width: 750,
			margin: 'auto',
		},
		stickyTitle: {
			top: 0,
			paddingTop: 24,
			marginRight: 15,
			position: 'fixed',
			backgroundColor: 'white',
			borderBottom: `1px solid ${dark10}`,
		},
		header: {
			paddingLeft: 40,
			paddingRight: 40,
			width: '100%',
			paddingBottom: 148,
			paddingTop: 56,
		},
		logo: {
			marginRight: 200,
		},
		titleText: {
			color: dark,
			fontWeight: 600,
		},
		subTitle: {
			paddingTop: 12,
			paddingBottom: 24,
			color: dark75,
		},
	}),
	{ index: 1 },
)
