import {
	Col,
	Icons,
	InvisibleButton,
	Row,
} from '@copyrightagent/basic-components'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { dark, dark75, red } from 'lib/Colors'
import { buildCollectionFullsizeImageURL } from 'lib/http/ApiProvider'
import { copyToClipboard, formatBytes, getDateAndTime } from 'lib/utils'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getImageByName } from 'routes/Private/Cases/api'
import { RouteUrls } from 'routes/RouteUrls'
import { IRootState } from 'store'
import { ImageProps } from '..'
import { useStyle } from './style'

interface ImageDetailsProps {
	closeDetails?(): void
	image?: ImageProps
	match?: {
		params: {
			imageName: string
		}
	}
}

const ImageDetails = (props: ImageDetailsProps): ReactElement => {
	const { closeDetails, image, match } = props
	const { REACT_APP_SITE_URL: SITE_URL } = process.env
	const [imageName, setImageName] = useState<string>('')
	const [imageSrc, setImageSrc] = useState<string | undefined>()
	const [imageSize, setImageSize] = useState<number | undefined>(0)
	const [imageUploadDate, setImageUploadDate] = useState<string>('')
	const [error, setError] = useState<boolean>(false)
	const [imageLoaded, setImageLoaded] = useState<boolean>(false)

	const classes = useStyle()
	const currentUser = useSelector(
		(store: IRootState) => store.AuthState.currentUser,
	)
	const router = useRouter()
	const { t } = useTranslation()

	const fetchLargeImageData = async (id: string) => {
		const imageUrl = buildCollectionFullsizeImageURL(id)
		const img = await fetch(imageUrl)
		const file = await img.blob()

		setImageSrc(imageUrl)
		setImageSize(file.size)
	}

	const setImageSrcAndSize = (imageData: ImageProps) => {
		if (imageData.id.length !== 0)
			return fetchLargeImageData(imageData.id)

		setImageSrc(imageData.src)
		setImageSize(imageData.size)
	}

	const getImageInfoByName = async (name: string) => {
		const imageInfo = await getImageByName(name)
		if (!imageInfo) setError(true)
		setImageUploadDate(imageInfo.data.uploaded)
		fetchLargeImageData(imageInfo.data.id)
	}

	useEffect(() => {
		if (!image) return
		setImageName(image.name)
		setImageUploadDate(image.uploaded)
		setImageSrcAndSize(image)
	}, [image])

	useEffect(() => {
		if (!match) return
		if (!match.params.imageName) return
		const name = match.params.imageName
		setImageName(name)
		getImageInfoByName(name)
	}, [match])

	const renderedImage = useMemo(() => {
		if (imageSrc) {
			return (
				<img
					src={imageSrc}
					alt={imageName}
					onLoad={() => {
						setImageLoaded(true)
					}}
					className={imageLoaded ? classes.showImage : classes.hidden}
				/>
			)
		}
		if (!error) return <Icons.SpinnerIcon className={classes.loading} />
		return <Icons.CloseIcon color={red} />
	}, [imageSrc, error, imageLoaded])

	const closeDetailsView = () => {
		if (closeDetails) return closeDetails()
		router.history.push(RouteUrls.images)
	}
	return (
		<Row
			className={clsx(
				classes.imageDetailsContainer,
				match ? classes.fixedView : '',
			)}
			justify="space-between"
		>
			<Col className={classes.image} align="center">
				{renderedImage}
			</Col>
			<Col className={classes.imageDetailsPanel} justify="flex-start">
				<Row className={classes.imageDetailsClose} justify="flex-end">
					<InvisibleButton
						className={classes.copyLink}
						text={t('imageDetails/copyImageLink')}
						onClick={(e) =>
							copyToClipboard(
								e,
								`${SITE_URL}${RouteUrls.imageDetails}/${imageName}`,
							)
						}
					>
						<Icons.CopyIcon style={{ marginLeft: 9 }} color={dark} />
					</InvisibleButton>
					<InvisibleButton
						onClick={closeDetailsView}
						className={classes.closeButton}
					>
						<Icons.CloseIcon color={dark} />
					</InvisibleButton>
				</Row>
				<Col className={classes.imageTitle}>
					<Typography variant="h5">{imageName}</Typography>
				</Col>
				<Col className={classes.imageDetails}>
					<Typography variant="body2" className={classes.imageDetailsTitle}>
						{t('imageDetails/imageDetails')}
					</Typography>
					<Row>
						<Icons.FileIcon className={classes.icon} color={dark75} />
						<Typography variant="body2">
							{imageSize && imageSize > 0
								? formatBytes(imageSize)
								: `${t('imageDetails/loading')}...`}
						</Typography>
					</Row>
				</Col>
				<Col>
					<Typography variant="body2" className={classes.imageDetailsTitle}>
						{t('imageDetails/uploadDetails')}
					</Typography>
					<Row>
						<Icons.UploadIcon className={classes.icon} color={dark75} />
						<Typography variant="body2">
							{getDateAndTime(imageUploadDate)}
						</Typography>
					</Row>
				</Col>
			</Col>
		</Row>
	)
}

export default ImageDetails
