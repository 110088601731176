import React, { Fragment, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NonViolatingReason, nonViolatingReasons } from 'lib/entities/Case'
import RadioButton from './RadioButton'

type SingleSaleProps = {
	onUpdateNonViolationReason: (value: NonViolatingReason) => void
}

export default function SingleSale({
	onUpdateNonViolationReason,
}: SingleSaleProps): ReactElement {
	const { t } = useTranslation()

	return (
		<Fragment>
			<RadioButton
				id="radio-single-sale"
				value={nonViolatingReasons.singleSale}
				label={t('nonInfringement/singleSale')}
				toolTipText={t('nonInfringement/singleSale/tooltip')}
				onChange={() =>
					onUpdateNonViolationReason(nonViolatingReasons.singleSale)
				}
			/>
		</Fragment>
	)
}
