import { AnyAction } from 'redux'

export interface ICaseCount {
	count?: number
	loaded: boolean
}

export interface ICaseStatistics {
	awaitingReview: ICaseCount
	onHold: ICaseCount
}

export const SET_AWAITING_REVIEW_COUNT =
	'case-statistics/set-awaiting-review-count'
export const SET_ON_HOLD_COUNT = 'case-statistics/set-on-hold-count'

const initialState: ICaseStatistics = {
	awaitingReview: {
		loaded: false,
	},
	onHold: {
		loaded: false,
	},
}

export default function dashboardReducer(
	state = initialState,
	action: AnyAction,
): ICaseStatistics {
	switch (action.type) {
		case SET_AWAITING_REVIEW_COUNT:
			return {
				...state,
				awaitingReview: { ...state.awaitingReview, ...action.awaitingReview },
			}
		case SET_ON_HOLD_COUNT:
			return {
				...state,
				onHold: { ...state.onHold, ...action.onHold },
			}

		default:
			return state
	}
}
