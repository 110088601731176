import { ICaseType } from './Case'

export enum NON_VIOLATING_REASONS {
	'Whitelisted',
	'SingleSale',
	'OtherReason',
	'Handout',
	'NotViolating',
}

export enum UNCLEAR_COPYRIGHT_REASONS {
	'UnknownPhotographer',
	'UnknownDate',
	'OutOfCopyright',
	'CounterpartyOnPicture',
	'OtherUnclearReason',
}

export enum APPROVAL_EVENTS {
	'ApprovedCaseByCase',
	'ApprovedMoreCases',
	'ApprovedBulk',
	'Violating',
}

export enum OTHER_ACTIVITY_EVENTS {
	'PutOnHold',
	'PutOffHold',
	'MarkAsSoftClaim',
	'UnmarkAsSoftClaim',
	'MarkAsCommercial',
	'MarkAsEditorial',
}

export const rejectionReasons = [
	...Object.keys(NON_VIOLATING_REASONS),
	...Object.keys(UNCLEAR_COPYRIGHT_REASONS),
]
export const putHoldEvents = [
	OTHER_ACTIVITY_EVENTS[OTHER_ACTIVITY_EVENTS.PutOnHold],
	OTHER_ACTIVITY_EVENTS[OTHER_ACTIVITY_EVENTS.PutOffHold],
]
export const violationTypeEvents = [
	OTHER_ACTIVITY_EVENTS[OTHER_ACTIVITY_EVENTS.MarkAsCommercial],
	OTHER_ACTIVITY_EVENTS[OTHER_ACTIVITY_EVENTS.MarkAsEditorial],
]
export const softClaimEvents = [
	OTHER_ACTIVITY_EVENTS[OTHER_ACTIVITY_EVENTS.MarkAsSoftClaim],
	OTHER_ACTIVITY_EVENTS[OTHER_ACTIVITY_EVENTS.UnmarkAsSoftClaim],
]
export const violatingEvents = [
	APPROVAL_EVENTS[APPROVAL_EVENTS.Violating],
	NON_VIOLATING_REASONS[NON_VIOLATING_REASONS.NotViolating],
]

interface ActivityContext {
	related_case_id?: string
	soft_claim?: boolean
	comment?: string
}

export interface Activity {
	id: number
	violation_id: string
	occurred_at: string
	user_id: string
	is_approved: boolean
	violation_type: ICaseType
	event_type: string
	context: ActivityContext | null
	firstname: string
	lastname: string
}

export interface Note {
	id: string
	firstname: string
	lastname: string
	note: string
	occurred_at: string
}

export const isNote = (item: Activity | Note): item is Note =>
	'note' in item && typeof item.note === 'string'
