import { Col, Icons, Row, Typography } from '@copyrightagent/basic-components'
import { ButtonBase, LinearProgress, Paper } from '@material-ui/core'
import trackEvent from 'hooks/useAnalytics'
import {
	addUploadingImage,
	resetUploadQueue,
	updateUploadingImage,
	useWorkspace,
} from 'hooks/useWorkspace'
import { red } from 'lib/Colors'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uploadImageToFolder } from 'routes/Private/Images/api'
import { IUploadingImage } from 'store/workspace'
import { useStyle } from './style'

interface UploaderProps {
	closeUploader: () => void
}

const Uploader = ({ closeUploader }: UploaderProps): ReactElement => {
	const classes = useStyle()
	const perBatch = 10
	const [iteration, setIteration] = useState<number>(0)
	const [uploadedCount, setUploadedCount] = useState<number>(0)
	const { uploadQueue } = useWorkspace()
	const { t } = useTranslation()
	const maxIterations = Math.ceil(uploadQueue.length / perBatch)

	const uploadBatch = () => {
		for (let j = 0; j < perBatch; j++) {
			const currentIndex = j + perBatch * iteration
			const image = uploadQueue[currentIndex]
			if (!image) return
			const uploadingImage: IUploadingImage = {
				id: '',
				name: image.name,
				loaded: false,
				size: image.size,
				uploaded: '',
			}
			const data = new FormData()
			data.append('file', image)
			addUploadingImage(uploadingImage)
			uploadImageToFolder(data)
				.then((res) => {
					uploadingImage.loaded = true
					if (!res.isSuccessful) {
						updateUploadingImage({
							...uploadingImage,
							errorMessage: res.data.message,
						})
						return
					}
					updateUploadingImage({
						...uploadingImage,
						src: URL.createObjectURL(image),
					})
				})
				.catch(() => {
					updateUploadingImage({
						...uploadingImage,
						loaded: true,
						errorMessage: 'error',
					})
				})
				.finally(() => {
					setUploadedCount(currentIndex + 1)
					if (
						(j === perBatch - 1 && currentIndex < uploadQueue.length) ||
						currentIndex === uploadQueue.length - 1
					)
						setIteration((prev) => prev + 1)
				})
		}
	}

	const handleClose = () => {
		closeUploader()
	}
	const terminateUpload = () => {
		resetUploadQueue()
		handleClose()
	}

	useEffect(() => {
		if (iteration < maxIterations) uploadBatch()
		if (iteration === maxIterations) terminateUpload()
	}, [iteration])

	return (
		<Paper className={classes.uploader}>
			<Row justify="space-between">
				<Col>
					<Typography className={classes.title} variant="body2">
						{t('images/uploading')}
					</Typography>
					<Typography className={classes.progress} variant="body2">
						{uploadedCount}/{uploadQueue.length} {t('images/uploadingImages')} -
						{t('images/uploadingTime/about')}{' '}
						{Math.ceil((3 * (uploadQueue.length - uploadedCount)) / 60)}{' '}
						{t('images/uploadingTime')}
					</Typography>
				</Col>
				<Row>
					<ButtonBase
						className={classes.closeButton}
						disableRipple
						onClick={() => {
							trackEvent('Cancelled upload', {
								date: new Date(),
								amount_canceled: uploadQueue.length - uploadedCount,
								amount_uploaded: uploadedCount,
								remaining_time: Math.ceil(
									(3 * (uploadQueue.length - uploadedCount)) / 60,
								),
							})
							terminateUpload()
						}}
					>
						<Icons.CloseIcon color={red} />
					</ButtonBase>
				</Row>
			</Row>
			<Row className={classes.barWrapper}>
				<LinearProgress
					classes={{
						root: classes.bar,
					}}
					variant="determinate"
					value={Math.floor((uploadedCount * 100) / uploadQueue.length)}
				/>
			</Row>
		</Paper>
	)
}
export default Uploader
