import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import {
	Icons,
	Typography,
	PageContainer,
	Row,
} from '@copyrightagent/basic-components'

import { useRouter } from 'routes/CustomBrowserRouter'
import { SET_TERMS_ACCEPTED } from 'store/auth'
import { RouteUrls } from 'routes/RouteUrls'
import trackEvent from 'hooks/useAnalytics'
import InfoBubble from 'components/InfoBubble'
import Footer from 'components/Footer'
import useStyle from './style'
import termsWrapper from './TermsWrapper'
import './TermsWrapper/termsStyles.css'

const TermsPage = (): ReactElement => {
	const router = useRouter()
	const classes = useStyle()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [nextDisabled, setNextDisabled] = useState(true)
	const [isSticky, setSticky] = useState(false)
	const updatedDate = new Date().toLocaleDateString()

	let stickyTitle: HTMLElement | null
	let stickyPaper: HTMLElement | null

	useEffect(() => {
		dispatch({ type: SET_TERMS_ACCEPTED, termsAccepted: false })
		stickyTitle = document.getElementById('terms-title')
		stickyPaper = document.getElementById('terms-paper')
	}, [isSticky, nextDisabled])

	const onClickNext = async () => {
		trackEvent('Accepted terms', { date: new Date() })
		dispatch({ type: SET_TERMS_ACCEPTED, termsAccepted: true })
		router.history.push(RouteUrls.createAccount)
	}

	const onClickBack = () => {
		trackEvent('Rejected terms', { date: new Date() })
		router.history.push(RouteUrls.countrySelect)
	}

	const handleScroll = (event: any) => {
		const element = event.target
		if (element.scrollHeight - element.scrollTop - 120 <= element.clientHeight)
			setNextDisabled(false)
		if (stickyPaper && stickyPaper.getBoundingClientRect().top >= 111)
			setSticky(false)
		if (stickyTitle && stickyTitle.getBoundingClientRect().top !== 0)
			setSticky(stickyTitle.getBoundingClientRect().top <= 80) // eslint-disable-line max-len
	}

	return (
		<Fragment>
			<PageContainer
				onScroll={(event) => handleScroll(event)}
				className={classes.page}
			>
				<Row justify="space-between" className={classes.header}>
					<Icons.Logo className={classes.logo} />
					<InfoBubble text={t('loginPage/earlyAccess')} />
				</Row>
				<Grid
					id="terms-title"
					className={clsx(
						isSticky ? classes.stickyTitle : classes.unstickyTitle,
						classes.title,
					)}
				>
					<div className={classes.titleSection}>
						<Typography variant="h5" className={classes.titleText}>
							{t('terms/title')}
						</Typography>
						<Typography variant="subtitle2" className={classes.subTitle}>
							{`${t('terms/updated')} ${updatedDate}`}
						</Typography>
					</div>
				</Grid>
				<Paper className={classes.paper} elevation={2} id="terms-paper">
					<div dangerouslySetInnerHTML={termsWrapper()} />
				</Paper>
				<Footer
					tooltipText={t('terms/tooltip')}
					nextLabel={t('terms/accept')}
					handleNext={onClickNext}
					nextDisabled={nextDisabled}
					previousLabel={t('terms/reject')}
					handlePrevious={onClickBack}
				></Footer>
			</PageContainer>
		</Fragment>
	)
}

export default TermsPage
