import React, { Fragment, ReactElement, useState, useEffect } from 'react'
import { Divider, Typography } from '@material-ui/core'
import {
	Col,
	DangerButton,
	Row,
	SubmitButton,
	Icons,
} from '@copyrightagent/basic-components'
import { useTranslation } from 'react-i18next'
import {
	getNextCaseAndReplace,
	rejectCase,
	replaceCase,
	setCaseAsSoftClaim,
	useCase,
} from 'hooks/useCase'
import { trackCaseReviewEvent, trackSubmitCaseEvent } from 'hooks/useAnalytics'
import {
	EListType,
	ICase,
	NonViolatingReason,
	updateCase,
} from 'lib/entities/Case'
import RejectCaseModal from 'components/RejectCaseModal'
import { useRouter } from 'routes/CustomBrowserRouter'
import {
	getCaseUrlBasedOnListType,
	getListUrlBasedOnListType,
} from 'routes/RouteUrls'
import OnHoldButton from 'components/PutOnHoldModal/button'
import CaseActivity from 'routes/Private/Case/CaseActivity'
import PutOnHoldModal from 'components/PutOnHoldModal'
import { red } from 'lib/Colors'
import PanelSwitchButton, {
	PanelSwitchButtonType,
} from 'components/PanelSwitchButton'
import { getCurrentListTypeInUrl } from 'lib/utils'
import MoreCasesModal from 'components/MoreCasesModal'
import SoftClaim from '../Infringement/SoftClaim'
import SelectableButton from '../SelectableButton'
import { useStyle } from '../style'
import { handleChangeCase } from '../changeHandlers'
import CaseComment from '../CaseComment'
import { approveCases } from '../../api'
import { getSelectedClient, getUserClaims } from 'store/utils'

interface IProps {
	submitReviewCase(theCase: ICase): void
	submitLoading: boolean
	tooltipText: string
}

export default function ReviewUI(props: IProps): ReactElement {
	const { submitLoading, submitReviewCase, tooltipText } = props

	const classes = useStyle()
	const router = useRouter()
	const { t } = useTranslation()
	const { theCase, loaded } = useCase()
	const [showRejectModal, setShowRejectModal] = useState(false)
	const [showOnHoldModal, setShowOnHoldModal] = useState(false)
	const [rightPanelMode, setRightPanelMode] = useState<'review' | 'activity'>(
		'review',
	)
	const [shouldCheckMoreCases, setShouldCheckMoreCases] =
		useState<boolean>(false)
	const [showSoftClaimOption, setShowSoftClaimOption] = useState(false)

	const listType = getCurrentListTypeInUrl()
	const isReview = listType === EListType.inReview

	const openRejectModal = () => setShowRejectModal(true)
	const closeRejectModal = () => setShowRejectModal(false)

	const handleClick = (value: string) => {
		handleChangeCase(theCase, 'violation_type', value)
		trackCaseReviewEvent(`Clicked on ${value}`, theCase)
	}

	const goToNextCase = async (currentCaseId: string) => {
		const nextCase = await getNextCaseAndReplace(currentCaseId)
		if (nextCase)
			return router.history.push(
				await getCaseUrlBasedOnListType({ caseId: nextCase.case_id }),
			)
		router.history.replace(getListUrlBasedOnListType())
	}

	const onReject = async (
		nonViolatingReason: NonViolatingReason,
		comment: string,
	) => {
		const newCase = updateCase(theCase, {
			non_violating_reason: nonViolatingReason,
			is_violating: false,
			comment,
		})
		replaceCase(newCase)
		await rejectCase(newCase)
		trackSubmitCaseEvent(newCase)

		if (isReview) return goToNextCase(theCase.case_id)
		router.history.push(
			await getListUrlBasedOnListType({ caseId: theCase.case_id }),
		)
	}

	const redirectToNextCase = async () => {
		if (isReview) return goToNextCase(theCase.case_id)
		router.history.push(
			await getListUrlBasedOnListType({ caseId: theCase.case_id }),
		)
	}

	const onValidateMissingCases = async (caseIds: string[]) => {
		await approveCases({
			case_ids: caseIds,
			violation_type: theCase.violation_type,
			is_soft_claim: theCase.soft_claim,
			related_case_id: theCase.case_id,
		})

		redirectToNextCase()
	}

	const handleSubmit = async () => {
		trackSubmitCaseEvent(theCase)
		submitReviewCase(theCase)
	}

	const panelSwitchButtons: Array<PanelSwitchButtonType> = [
		{
			text: t('inReview/review'),
			selected: rightPanelMode === 'review',
			onClick: () => setRightPanelMode('review'),
		},
		{
			text: t('inReview/activity'),
			selected: rightPanelMode === 'activity',
			onClick: () => setRightPanelMode('activity'),
		},
	]

	const onRejectSoftCase = () => {
		setCaseAsSoftClaim(theCase)
		trackCaseReviewEvent('Mark as soft claim module', theCase) // should trigger the same event as <SoftClaim />
	}

	const onPutOnHold = () => {
		setShowOnHoldModal(true)
		trackCaseReviewEvent('Put case on hold', theCase)
	}

	const onCloseOnHoldModalAndGetNextCase = () => {
		goToNextCase(theCase.case_id)
		trackCaseReviewEvent('Closed OnHold Modal and got next case', theCase)
	}

	const onCloseOnholdModal = () =>
		trackCaseReviewEvent('Closed OnHold Modal', theCase)

	const onCloseOnHoldModalAndGoToOnHoldCases = () =>
		trackCaseReviewEvent('Closed OnHold Modal and go to cases on hold', theCase)

	const client = getSelectedClient()
	const claims = getUserClaims()
	useEffect(() => {
		setShowSoftClaimOption(claims.soft_claim ? claims.soft_claim[client.client_id] : false)
	}, [client, claims])

	const { violation_id, violation_type, url_raw, domain } = theCase

	return (
		<Fragment>
			<PanelSwitchButton buttons={panelSwitchButtons} />
			<div className={classes.rightPanelContent}>
				{rightPanelMode === 'review' ? (
					<Fragment>
						<RejectCaseModal
							open={showRejectModal}
							onClose={closeRejectModal}
							onReject={onReject}
							onSoftCase={onRejectSoftCase}
						/>
						<PutOnHoldModal
							showOnHoldModal={showOnHoldModal}
							onCloseAndDoNothing={onCloseOnholdModal}
							onCloseAndGoToNextCase={onCloseOnHoldModalAndGetNextCase}
							onCloseAndGoToOnHoldCases={onCloseOnHoldModalAndGoToOnHoldCases}
							setShowOnHoldModal={setShowOnHoldModal}
						/>
						<div>
							<Typography
								variant="body2"
								align="center"
								className={classes.title}
							>
								{t('caseActions/whatTypeOfViolation')}
							</Typography>
							<Col align={'stretch'} className={classes.infringementType}>
								<SelectableButton
									label="Commercial"
									className={
										theCase.violation_type === 'Commercial'
											? classes.fullWidthButtonSeleted
											: classes.fullWidthButton
									}
									selected={theCase.violation_type === 'Commercial'}
									disabled={!loaded}
									onClick={() => handleClick('Commercial')}
								/>
								<SelectableButton
									label="Editorial"
									className={
										theCase.violation_type === 'Editorial'
											? classes.fullWidthButtonSeleted
											: classes.fullWidthButton
									}
									selected={theCase.violation_type === 'Editorial'}
									disabled={!loaded}
									onClick={() => handleClick('Editorial')}
								/>
							</Col>
							<Divider />
							{showSoftClaimOption && <SoftClaim />}
						</div>
						<div>
							<CaseComment className={classes.rightSideComment} />
							<OnHoldButton theCase={theCase} onPutOnHold={onPutOnHold} />
							<Row>
								<DangerButton
									className={classes.rejectButton}
									onClick={openRejectModal}
								>
									<Icons.TrashIcon color={red} />
								</DangerButton>
								<SubmitButton
									loading={submitLoading}
									tooltipText={tooltipText}
									onClick={() => {
										setShouldCheckMoreCases(true)
										handleSubmit()
									}}
									text={t('validateCase')}
									loadingText={t('inReview/processing')}
									buttonStyle={{
										defaultCSS: {
											flexGrow: 1,
										},
									}}
								/>
							</Row>
						</div>

						<MoreCasesModal
							shouldCheckCases={shouldCheckMoreCases}
							casesToCheck={[{ violation_id, url_raw, violation_type, domain }]}
							onAfterCheckCases={(casesAreEmpty) => {
								if (casesAreEmpty) return redirectToNextCase()
							}}
							onCancel={() => redirectToNextCase()}
							onValidate={onValidateMissingCases}
						/>
					</Fragment>
				) : (
					<CaseActivity theCase={theCase} />
				)}
			</div>
		</Fragment>
	)
}
