import { Col, Icons, Row } from '@copyrightagent/basic-components'
import { Typography, TypographyProps } from '@material-ui/core'
import { dark } from 'lib/Colors'
import {
	Activity,
	Note,
	isNote,
	rejectionReasons,
	putHoldEvents,
	violationTypeEvents,
	violatingEvents,
	softClaimEvents,
	APPROVAL_EVENTS,
} from 'lib/entities/Activity'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'

const Text = ({ children, ...props }: TypographyProps) => (
	<Typography variant="body2" {...props}>
		{children}
	</Typography>
)

interface ActivityItemProps {
	item: Activity | Note
}

export const ActivityItem = ({ item }: ActivityItemProps): ReactElement => {
	const classes = useStyles()
	const { t } = useTranslation()

	const renderAdditionalContent = (): JSX.Element | null => {
		if (isNote(item)) {
			return (
				<>
					<Text className={classes.activityItemText}>
						{t('caseActivity/standaloneComment')}
					</Text>
					<Text className={classes.activityItemNote}>{item.note}</Text>
				</>
			)
		}

		if (rejectionReasons.includes(item.event_type)) {
			return (
				<>
					<Text className={classes.activityItemText}>
						{t('caseActivity/rejected')}
						<span className={classes.black}>
							{t(`caseActivity/reject/${item.event_type}`)}
						</span>
						{t(`caseActivity/${item.context?.comment ? 'note' : 'noNote'}`)}
					</Text>
					{item.context?.comment && (
						<Text className={classes.activityItemNote}>
							{item.context.comment}
						</Text>
					)}
				</>
			)
		}

		if (
			item.event_type === APPROVAL_EVENTS[APPROVAL_EVENTS.ApprovedMoreCases] &&
			item.is_approved
		) {
			return (
				<Text className={classes.activityItemText}>
					{t('caseActivity/accepted')}
					<span className={classes.black}>
						{item.violation_type.toLowerCase()}
					</span>
					{t('caseActivity/relatedCase')}
					<span className={classes.black}>{item.context?.related_case_id}</span>
					.
				</Text>
			)
		}

		if (
			item.event_type === APPROVAL_EVENTS[APPROVAL_EVENTS.ApprovedCaseByCase] &&
			item.is_approved
		) {
			return (
				<>
					<Text className={classes.activityItemText}>
						{t('caseActivity/accepted')}
						<span className={classes.black}>
							{item.violation_type.toLowerCase()}
						</span>
						{t(`caseActivity/${item.context?.comment ? 'note' : 'noNote'}`)}
					</Text>
					{item.context?.comment && (
						<Text className={classes.activityItemNote}>
							{item.context.comment}
						</Text>
					)}
				</>
			)
		}

		if (putHoldEvents.includes(item.event_type)) {
			return (
				<Text className={classes.activityItemText}>
					{t(`caseActivity/${item.event_type}`)}
				</Text>
			)
		}

		// backwards compatibility with legacy activity events
		if (violatingEvents.includes(item.event_type)) {
			return (
				<Text className={classes.activityItemText}>
					{t('caseActivity/markedAs')}
					<span className={classes.black}>
						{t(`caseActivity/${item.event_type}`)}
					</span>
					.
				</Text>
			)
		}

		if (violationTypeEvents.includes(item.event_type)) {
			return (
				<Text className={classes.activityItemText}>
					{t('caseActivity/markedAs')}
					<span className={classes.black}>
						{item.violation_type.toLowerCase()}
					</span>
					{item.context?.soft_claim ? ` ${t('SoftClaim')}` : ''}.
				</Text>
			)
		}

		if (softClaimEvents.includes(item.event_type)) {
			return (
				<Text className={classes.activityItemText}>
					{t(
						`caseActivity/${
							item.event_type === 'MarkAsSoftClaim' ? 'markedAs' : 'unmarkedAs'
						}`,
					)}
					<span className={classes.black}>{t('SoftClaim')}</span>.
				</Text>
			)
		}

		return null
	}

	return (
		<Col className={classes.activityItemWrap}>
			<Row justify="space-between" className={classes.activityItemTitle}>
				<Icons.ClientManagementIcon color={dark} />
				<Text variant="body2" className={classes.activityItemName}>
					{item.firstname} {item.lastname}
				</Text>
				<Text className={classes.activityItemDate} variant="caption">
					{moment(item.occurred_at).format('MMM D, YYYY')}
				</Text>
			</Row>
			{renderAdditionalContent()}
		</Col>
	)
}
