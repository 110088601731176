import {
	Col,
	PrimaryButton,
	Typography,
} from '@copyrightagent/basic-components'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useStyle from './style'

const NoCreatedCases = ({
	createCase,
}: {
	createCase: () => void
}): ReactElement => {
	const classes = useStyle()
	const { t } = useTranslation()
	return (
		<Col justify="center" align="center" className={classes.noCasesUploaded}>
			<Typography variant="h5">{t('createCase/noCreatedCases')}</Typography>
			<Typography variant="body2">
				{t('createCase/noCreatedCases/desc')}
			</Typography>
			<PrimaryButton
				type="button"
				text={t('createCase/submit/title')}
				onClick={createCase}
			/>
		</Col>
	)
}
export default NoCreatedCases
