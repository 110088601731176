import { DownloadCsv as BCDownloadCsv } from '@copyrightagent/basic-components'
import { Filters, getDefaultFilters } from 'components/CaseListView'
import trackEvent from 'hooks/useAnalytics'
import { parseQueryParams } from 'lib/utils'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { getSelectedClient } from 'store/utils'
import { getCsvOfCases } from '../../routes/Private/Cases/api'

export interface Options {
	[key: string]: string
}
type DownloadCsv = { caseCount: number }
export default function DownloadCsv({ caseCount }: DownloadCsv): ReactElement {
	const { t } = useTranslation()
	const location = useLocation()

	const options: Options = {
		address: t('downloadCsv/address'),
		zip: t('downloadCsv/zip'),
		city: t('downloadCsv/city'),
		phone: t('downloadCsv/phone'),
		supplier: t('downloadCsv/supplier'),
		screenshotTimestamp: t('downloadCsv/screenshotTimestamp'),
		comment: t('downloadCsv/comment'),
		originalImageUrl: t('downloadCsv/originalImageUrl'),
		caseState: t('downloadCsv/caseState'),
	}

	const handlePopoverOpen = () => {
		const filters: Filters = parseQueryParams(
			getDefaultFilters(),
			location.search,
		)
		const searchTags: string[] = Object.entries(filters).reduce(
			(prev: string[], [key, value]: string[]): string[] => {
				if (key !== 'domain' && key !== 'searchTerm' && value.length > 0)
					prev.push(value)
				return prev
			},
			[],
		)
		trackEvent('Click export as CSV', {
			query: filters.searchTerm,
			searchTags,
		})
	}

	const downloadCsv = async (selectedOptions: Options) => {
		const filters: Filters = parseQueryParams(
			getDefaultFilters(),
			location.search,
		)

		trackEvent('Download CSV', {
			CSVOptions: Object.keys(selectedOptions),
			caseCount,
		})

		const csvStream = await getCsvOfCases(selectedOptions, filters)
		const client = getSelectedClient()
		const url = window.URL.createObjectURL(new Blob([csvStream.data]))
		const link = document.createElement('a')
		link.href = url
		link.setAttribute(
			'download',
			`${client.name}-${new Date().toISOString()}.csv`,
		)

		document.body.appendChild(link)
		link.click()
		if (!link.parentNode) return
		link.parentNode.removeChild(link)
	}

	return (
		<BCDownloadCsv
			options={options}
			handlePopoverOpen={handlePopoverOpen}
			onDownloadCsv={downloadCsv}
			locales={{
				export: t('downloadCsv/export'),
				defaultOptions: t('downloadCsv/defaultOptions'),
				viewMore: t('downloadCsv/viewMore'),
				download: t('downloadCsv/download'),
			}}
		/>
	)
}
