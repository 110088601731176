import React, { useState, useEffect, ReactElement, useCallback } from 'react'
import createCase, {
	EListType,
	getActionPromptPath,
	ICase,
} from 'lib/entities/Case'
import { CopyrightAgentLoader } from '@copyrightagent/basic-components'
import { toast } from 'store/utils'
import { getCaseActions, useCase } from 'hooks/useCase'
import { useRouter } from 'routes/CustomBrowserRouter'
import { getListUrlBasedOnListType } from 'routes/RouteUrls'
import { useTranslation } from 'react-i18next'
import { useCaseStatistics } from 'hooks/useCaseStatistics'
import { useStyle } from './style'
import ReviewUI from './UI/ReviewUI'
import { approveCase, getNextCase } from '../api'
import resolveSnackMessage from '../resolveSnackMessage'

export default function CaseActions(): ReactElement | null {
	const classes = useStyle()
	const { t } = useTranslation()
	const { theCase, loaded } = useCase()
	const { replaceCase } = getCaseActions()
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)
	const router = useRouter()
	const { awaitingReview, onHold } = useCaseStatistics()
	const [actionText, setActionText] = useState<string>(
		t('caseActions/pleaseChoose'),
	)

	const submitReviewCase = useCallback(
		async (readyCase: ICase) => {
			setSubmitLoading(true)
			await approveCase(readyCase.violation_id)
			toast(resolveSnackMessage(readyCase), 'info')

			const response = await getNextCase(readyCase.case_id)
			setSubmitLoading(false)

			if (!response.isSuccessful) {
				replaceCase(createCase())
				document.title = 'Copyright Agent'
				if (onHold.count) {
					return router.history.push(
						getListUrlBasedOnListType({
							listType: EListType.onHold,
							resetFilter: true,
						}),
					)
				}
				return toast(response.data.message, 'warning')
			}
		},
		[theCase.non_violating_reason, replaceCase, onHold.count],
	)

	useEffect(() => {
		setActionText(t(getActionPromptPath(theCase)))
	}, [theCase])

	if (!awaitingReview.count && !onHold.count) return null
	if (loaded && !theCase.violation_id) return null

	return (
		<div className={classes.container}>
			{!loaded ? (
				<CopyrightAgentLoader className={classes.loader} />
			) : (
				<ReviewUI
					submitLoading={submitLoading}
					submitReviewCase={submitReviewCase}
					tooltipText={actionText}
				/>
			)}
		</div>
	)
}
