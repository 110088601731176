import { makeStyles } from '@material-ui/core'
import Color from 'color'
import { blue, dark10, dark50, dark75, green } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		dropzone: {
			backgroundColor: Color(blue).alpha(0.1).toString(),
			border: `1px dashed ${blue}`,
			borderRadius: 6,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: 150,
			cursor: 'pointer',
			position: 'relative',
			'&:focus': {
				outline: 'none',
			},
			'& p': {
				paddingTop: 12,
				fontWeight: 600,
				color: blue,
			},
			'& path': {
				fill: blue,
			},
		},
		discardChoice: {
			position: 'absolute',
			top: 12,
			right: 12,
			zIndex: 2000,
		},
		routeChoice: {
			border: `1px solid ${dark10}`,
			padding: 24,
			borderRadius: 6,
			cursor: 'pointer',
			'&:last-child': {
				marginTop: 12,
			},
		},
		routeChoiceSelected: {
			backgroundColor: Color(green).alpha(0.1).toString(),
			border: `1px solid ${green}`,
		},
		routeIcon: {
			borderRadius: 6,
			width: 36,
			height: 36,
			padding: 10,
			marginRight: 12,
			backgroundColor: Color(green).alpha(0.25).toString(),
		},
		description: {
			marginTop: 12,
			'& p': {
				color: dark50,
			},
		},
		label: {
			padding: 5,
			borderRadius: 6,
			backgroundColor: Color(blue).alpha(0.1).toString(),
			color: blue,
			margin: '0px 12px',
		},
		invisibleButton: {
			marginRight: 8,
			'& p': {
				color: dark50,
			},
			'&:hover': {
				backgroundColor: Color(dark75).alpha(0.1).toString(),
				boxShadow: 'none',
				'& p': {
					color: dark75,
				},
			},
			'&:active': {
				backgroundColor: Color(dark75).alpha(0.25).toString(),
				boxShadow: 'none',
				'& p': {
					color: dark75,
				},
			},
		},
	},
	{ index: 1 },
)
