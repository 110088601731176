import React, { ReactElement, useEffect, useState } from 'react'
import {
	Row,
	Typography,
	Col,
	ImageComponent,
} from '@copyrightagent/basic-components'
import { Paper } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getAuth } from 'firebase/auth'
import { dark50 } from 'lib/Colors'
import { useTranslation } from 'react-i18next'
import uploadImagesImage from 'assets/images/upload-image.svg'
import createCaseImage from 'assets/images/create-case.svg'
import { Link } from 'react-router-dom'
import trackEvent from 'hooks/useAnalytics'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import { SET_CLAIMS } from 'store/auth'
import { IRootState } from 'store'
import { useStyle } from './style'
import PageWithNavigation from '../HomeLayout/PageWithNavigation'

const GetStarted = (): ReactElement => {
	const auth = getAuth()
	const classes = useStyle()
	const router = useRouter()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const claims = useSelector((state: IRootState) => state.AuthState.claims)
	const [claimsRefreshed, setClaimsRefreshed] = useState<boolean>(false)
	const user = auth.currentUser

	useEffect(() => {
		if (claims?.image_upload || claims?.submit_case) return
		if (user) {
			user.getIdTokenResult(true).then((idToken) => {
				const newClaims = {
					image_upload: idToken.claims.image_upload,
					submit_case: idToken.claims.submit_case,
				}

				dispatch({ type: SET_CLAIMS, claims: newClaims })
				setClaimsRefreshed(!claimsRefreshed)
			})
		}
	}, [user])

	useEffect(() => {
		// eslint-disable-next-line max-len
		if (
			claimsRefreshed === true &&
			(!claims?.image_upload || !claims?.submit_case)
		)
			return router.history.replace(RouteUrls.dashboard)
	}, [claimsRefreshed])

	return (
		<PageWithNavigation title={t('getStarted/pageTitle')}>
			<Paper
				classes={{
					root: classes.paper,
				}}
			>
				<Row justify="flex-start">
					<Typography variant="h5" fontWeight="bold">
						{t('getStarted/thankYou')}
					</Typography>
				</Row>
				<Row justify="flex-start">
					<Typography variant="body2" fontColor={dark50}>
						{t('getStarted/tellUs')}
					</Typography>
				</Row>
				<Row justify="flex-start" className={classes.actions}>
					<Col className={classes.action}>
						<ImageComponent
							alt={t('getStarted/createCase/title')}
							src={createCaseImage}
							className={classes.actionImage}
						/>
						<Typography className={classes.actionTitle}>
							{t('getStarted/createCase/title')}
						</Typography>
						<Typography variant="body2" fontColor={dark50}>
							{t('getStarted/createCase/desc')}
						</Typography>
						<Link
							className={classes.actionLink}
							to={{
								pathname: RouteUrls.submitCase,
								state: {
									getStarted: true,
								},
							}}
							onClick={() =>
								trackEvent('Get started cases', {
									date: new Date(),
								})
							}
						>
							<Row>
								<Typography>{t('getStarted/createCase/link')}</Typography>
							</Row>
						</Link>
					</Col>
					<Col className={classes.action}>
						<ImageComponent
							alt={t('getStarted/uploadImages/title')}
							src={uploadImagesImage}
							className={classes.actionImage}
						/>
						<Typography className={classes.actionTitle}>
							{t('getStarted/uploadImages/title')}
						</Typography>
						<Typography variant="body2" fontColor={dark50}>
							{t('getStarted/uploadImages/desc')}
						</Typography>
						<Link
							className={classes.actionLink}
							to={{ pathname: RouteUrls.images, state: { getStarted: true } }}
							onClick={() =>
								trackEvent('Get started images', {
									date: new Date(),
								})
							}
						>
							<Row>
								<Typography>{t('getStarted/uploadImages/link')}</Typography>
							</Row>
						</Link>
					</Col>
				</Row>
			</Paper>
		</PageWithNavigation>
	)
}
export default GetStarted
