import React, { ReactElement } from 'react'
import ImageComponent from 'material-ui-image'
import { useTranslation } from 'react-i18next'
import { Col, Logo, Typography } from '@copyrightagent/basic-components'
import { SystemImages } from 'assets'
import { dark50, dark75 } from 'lib/Colors'
import Svgicon from 'assets/images/ScreenNotSupportedIcon.svg'
import useStyle from './style'

const IconProps = {
	style: {
		paddingTop: 0,
		width: 200,
		height: 200,
		marginBottom: 50,
	},
	disableSpinner: true,
}

export default function ScreenSizeNotSupported(): ReactElement {
	const classes = useStyle()
	const { t } = useTranslation()

	return (
		<Col align="center" className={classes.root}>
			<Logo src={SystemImages.logoWithText} className={classes.logo} />
			<ImageComponent {...IconProps} src={Svgicon} />
			<Typography
				className={classes.title}
				align="center"
				fontWeight={600}
				fontColor={dark75}
				variant="h5"
			>
				{t('screenNotSupported/title')}
			</Typography>
			<Typography
				className={classes.body}
				fontWeight={600}
				align="center"
				fontColor={dark50}
				variant="body1"
			>
				{t('screenNotSupported/body')}
			</Typography>
		</Col>
	)
}
