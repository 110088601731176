import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { IRootState } from 'store'
import {
	ICaseState,
	REPLACE_CASE,
	SET_LOADED,
	SET_ERROR_MESSAGE,
	SET_SUBMITTED_CASES,
} from 'store/case'
import createCase, { ICase, updateCase } from 'lib/entities/Case'
import {
	rejectCase as rejectCaseAPI,
	rejectCases as rejectCasesAPI,
	approveCases as approveCasesAPI,
	getFirstCase,
	getNextCase,
	rejectCasesProps,
	approveCasesProps,
} from 'routes/Private/InReview/api'
import {
	getCase,
	getSubmittedCases,
	markCaseSoftClaim,
	unmarkCaseSoftClaim,
} from 'routes/Private/Cases/api'
import { toast } from 'store/utils'
import resolveSnackMessage from 'routes/Private/InReview/resolveSnackMessage'
import getStore from 'store/store'

const { store } = getStore()
const { dispatch } = store

interface IActions {
	setLoaded(newLoaded: boolean): void
	setErrorMessage(message: string): void
	replaceCase(theCase: ICase): void
	fetchCaseForInReview(id?: string): Promise<string>
	fetchSubmittedCases(page: number, perPage: number): Promise<any[]>
	setCaseAsSoftClaim(theCase: ICase): ICase
	unsetCaseAsSoftClaim(theCase: ICase): ICase
}

const setLoaded = (newLoaded: boolean) => {
	dispatch({ type: SET_LOADED, loaded: newLoaded })
}

const setErrorMessage = (message: string) => {
	dispatch({ type: SET_ERROR_MESSAGE, message })
}

export const useCase = (): ICaseState =>
	useSelector((state: IRootState) => state.CaseState)

export const setSubmittedCases = (submittedCases: any[]): void => {
	dispatch({ type: SET_SUBMITTED_CASES, submittedCases })
}

export const replaceCase = (theCase: ICase): void => {
	dispatch({ type: REPLACE_CASE, theCase })
}

const fetchCaseForInReview = async (case_id?: string): Promise<string> => {
	setLoaded(false)
	const { data, status } = case_id
		? await getCase(case_id!)
		: await getFirstCase()

	if (status >= 400) {
		if (status !== 404) setErrorMessage(data.message)

		setLoaded(true)
		return ''
	}

	setLoaded(true)
	const theCase = createCase(data)
	replaceCase(theCase)
	return theCase.case_id
}

const fetchSubmittedCases = async (page: number, perPage: number) => {
	const cases = await getSubmittedCases(page, perPage)
	setSubmittedCases(cases.data)
	return cases.data
}

export async function getNextCaseAndReplace(
	currentCaseId: string,
): Promise<ICase | undefined> {
	const response = await getNextCase(currentCaseId)
	if (!response.isSuccessful) {
		replaceCase(createCase())
		document.title = 'Copyright Agent'
		toast(response.data.message, 'warning')
		return
	}
	if (response.status === 204) return
	const newCase = createCase(response.data)
	replaceCase(newCase)
	return newCase
}

export async function rejectCase(theCase: ICase): Promise<void> {
	const { non_violating_reason, violation_id: case_id, comment } = theCase

	await rejectCaseAPI({ case_id, non_violating_reason, comment })
	toast(resolveSnackMessage(theCase), 'info')
}

export async function rejectCases(
	body: rejectCasesProps,
	reason: string,
): Promise<void> {
	await rejectCasesAPI(body)
	toast(
		i18n.t('bulkReview/snackMessages/rejected', { reason }),
		'info',
		undefined,
		{ bottom: 112 },
	)
}

export async function approveCases(body: approveCasesProps): Promise<void> {
	await approveCasesAPI(body)
	toast(i18n.t('bulkReview/snackMessages/validated'), 'info', undefined, {
		bottom: 112,
	})
}

export const setCaseAsSoftClaim = (theCase: ICase): ICase => {
	const newCase = updateCase(theCase, { soft_claim: true })
	markCaseSoftClaim(theCase.violation_id)
	replaceCase(newCase)
	return newCase
}

export const unsetCaseAsSoftClaim = (theCase: ICase): ICase => {
	const newCase = updateCase(theCase, { soft_claim: false })
	unmarkCaseSoftClaim(theCase.violation_id)
	replaceCase(newCase)
	return newCase
}

export const setCaseSoftClaim = (
	theCase: ICase,
	setAsSoftClaim: boolean,
): ICase =>
	setAsSoftClaim ? setCaseAsSoftClaim(theCase) : unsetCaseAsSoftClaim(theCase)

export const getCaseActions = (): IActions => ({
	fetchCaseForInReview,
	setErrorMessage,
	replaceCase,
	setLoaded,
	fetchSubmittedCases,
	setCaseAsSoftClaim,
	unsetCaseAsSoftClaim,
})
