import React, { useMemo, ReactElement, useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import {
	Row,
	Typography,
	ImageComponent,
	Icons,
} from '@copyrightagent/basic-components'
import CaseType from 'components/CaseTypeLabel'
import CaseStatus from 'components/CaseStatus'
import { dark50 } from 'lib/Colors'
import { copyToClipboard } from 'lib/utils'
import { buildCollectionImageURL } from 'lib/http/ApiProvider'
import { caseLocations } from 'lib/entities/Case'
import CaseLocation from '../CaseRow/CaseLocation'
import { useStyle } from '../CaseRow/style'
import { getImageById } from '../../api'

interface Props {
	theCase: any
}

export const CreatedCaseRow = (props: Props): ReactElement => {
	const classes = useStyle()
	const [imageSrc, setImageSrc] = useState('')
	const [imageName, setImageName] = useState('')

	const { theCase } = props
	const caseLocation = caseLocations.createdCase

	const getImageSrc = (id: string) => {
		setImageSrc(buildCollectionImageURL(id))
	}
	const getImageName = (id: string) => {
		getImageById(id).then((res) => setImageName(res.data.name))
	}

	useEffect(() => {
		if (theCase.id) {
			getImageSrc(theCase.id)
			getImageName(theCase.id)
		}
	}, [theCase.id])

	const caseRow = useMemo(
		() => (
			<Row className={classes.caseRows}>
				<ImageComponent className={classes.image} src={imageSrc} />
				<Row
					style={{ flex: 1 }}
					className={classes.caseRowColumn}
					align="start"
				>
					<Row>
						<Typography className={classes.caseID} fontColor={dark50}>
							{imageName}
						</Typography>
						<IconButton
							className={classes.iconButton}
							onClick={(e) => copyToClipboard(e, imageName)}
						>
							<Icons.CopyIcon color={dark50} />
						</IconButton>
						<CaseType theCase={theCase} />
					</Row>
					<Typography fontWeight={600} variant="body1">
						{theCase.url}
					</Typography>
				</Row>
				<Row
					style={{ flex: 1 }}
					className={classes.caseRow}
					justify="flex-start"
				>
					<CaseStatus theCase={theCase} />
					<Typography fontWeight={600} className={classes.caseStatusText}>
						{theCase.status}
					</Typography>
				</Row>
				<Row
					style={{ flex: 1 }}
					className={classes.caseRowLastColumn}
					align="flex-start"
				>
					<CaseLocation theCase={theCase} caseLocation={caseLocation} />
				</Row>
			</Row>
		),
		[classes, theCase, imageSrc],
	)

	return <Row className={classes.root}>{caseRow}</Row>
}
