import { Modal, ModalProps } from '@copyrightagent/basic-components'
import trackEvent from 'hooks/useAnalytics'
import { getCaseActions } from 'hooks/useCase'
import _ from 'lodash'
import React, {
	Dispatch,
	ReactElement,
	SetStateAction,
	useEffect,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { submitCase, getImageByName } from 'routes/Private/Cases/api'
import { uploadImageToFolder } from 'routes/Private/Images/api'
import { toast } from 'store/utils'
import CompareImages from './createCaseSteps/CompareImages'
import InsertInfoStep from './createCaseSteps/InsertInfoStep'
import RenamingFileStep from './createCaseSteps/RenamingFileStep'

interface CreateCaseModalProps {
	opened: boolean
	setDialogOpened: Dispatch<SetStateAction<boolean>>
}

const CreateCaseModal = ({
	opened,
	setDialogOpened,
}: CreateCaseModalProps): ReactElement => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const [file, setFile] = useState<File>()
	const [caseUrl, setCaseUrl] = useState<string>('')
	const [storedFile, setStoredFile] = useState<any>(null)
	const [renamingFile, setRenamingFile] = useState<boolean>(false)
	const [isPending, setIsPending] = useState<boolean>(false)
	const { fetchSubmittedCases } = getCaseActions()

	const handleClose = () => {
		setIsPending(false)
		setRenamingFile(false)
		setOpen(false)
		setCaseUrl('')
		setFile(undefined)
		setStoredFile(null)
		setDialogOpened(false)
	}

	const uploadFileAndCreateCase = async (fileName: string) => {
		setIsPending(true)
		const data = new FormData()
		if (!file) return
		data.append('file', file, fileName)

		uploadImageToFolder(data).then((res) => {
			if (!res.isSuccessful) {
				toast(t('createCase/fail'), 'error')
				handleClose()
				return
			}
			if (!res.data.imageId) return
			submitCase(caseUrl, res.data.imageId).then((result) => {
				if (result.isSuccessful) {
					toast(t('createCase/success'), 'info')
					handleClose()
					fetchSubmittedCases(0, 40)
				}
			})
			trackEvent('Submitted case', {
				date: new Date(),
				case_location: caseUrl,
				image_name: fileName,
			})
		})
	}

	const checkIfFileExists = async (imageName: string) => {
		const image = await getImageByName(imageName)
		if (!_.isEmpty(image.data)) setStoredFile(image.data)
		else uploadFileAndCreateCase(imageName)
	}

	useEffect(() => {
		setOpen(opened)
	}, [opened])

	useEffect(() => {
		if (file) checkIfFileExists(file.name)
	}, [file])

	const handleSubmit = async (url: string, submitFile: File) => {
		setCaseUrl(url)
		setFile(submitFile)
	}

	const handleFileUpdate = async (newName: string) => {
		if (file) await checkIfFileExists(newName)
	}

	const createCase = (id: string) => {
		submitCase(caseUrl, id).then((res) => {
			if (res.isSuccessful) {
				toast(t('createCase/success'), 'info')
				fetchSubmittedCases(0, 40)
				handleClose()
			}
		})
		trackEvent('Submitted case', {
			date: new Date(),
			case_location: caseUrl,
			image_name: file ? file.name : '',
		})
	}

	const defaultModalData: Pick<ModalProps, 'onClose' | 'open' | 'size'> = {
		open,
		onClose: () => setOpen(false),
		size: 'large',
	}

	const [modalData, _setModalData] = useState<ModalProps>({
		...defaultModalData,
		children: <></>,
		title: '',
		titleText: '',
	})

	const setModalData = (
		data: Pick<ModalProps, 'title' | 'titleText' | 'children'>,
	): void =>
		_setModalData({
			...defaultModalData,
			...data,
		})

	useEffect(() => {
		if (storedFile && !renamingFile) {
			setModalData({
				title: t('createCase/duplicate/title'),
				titleText: t('createCase/duplicate/description'),
				children: (
					<CompareImages
						newFile={file}
						storedFile={storedFile}
						handleClose={handleClose}
						chooseExistingFile={createCase}
						openRenameStep={() => setRenamingFile(true)}
						isPending={isPending}
					/>
				),
			})
		} else if (renamingFile) {
			setModalData({
				title: t('createCase/rename/title'),
				titleText: t('createCase/rename/description'),
				children: (
					<RenamingFileStep
						file={file}
						handleClose={handleClose}
						setUpdatedFileName={handleFileUpdate}
						isPending={isPending}
					/>
				),
			})
		} else {
			setModalData({
				title: t('createCase/submit/title'),
				titleText: t('createCase/submit/description'),
				children: (
					<InsertInfoStep
						handleClose={handleClose}
						handleSubmit={handleSubmit}
						isPending={isPending}
					/>
				),
			})
		}
	}, [storedFile, renamingFile, open])

	return <Modal {...modalData} />
}
export default CreateCaseModal
