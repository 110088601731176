import { makeStyles } from '@material-ui/core'

export default makeStyles(
	() => ({
		loaderwrapper: {
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	}),
	{ index: 1 },
)
