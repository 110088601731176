import { makeStyles } from '@material-ui/core'
import Color from 'color'
import { blue, dark50, dark75, green } from 'lib/Colors'

export const useStyle = makeStyles(
	{
		dropzone: {
			backgroundColor: Color(blue).alpha(0.1).toString(),
			border: `1px dashed ${blue}`,
			borderRadius: 6,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: 150,
			cursor: 'pointer',
			position: 'relative',
			'&:focus': {
				outline: 'none',
			},
			'& p': {
				paddingTop: 12,
				fontWeight: 600,
				color: blue,
			},
			'& path': {
				fill: blue,
			},
		},
		discardChoice: {
			position: 'absolute',
			top: 12,
			right: 12,
			zIndex: 2000,
		},
		actionLabel: {
			'&:not(:first-child)': {
				marginTop: 12,
			},
			marginBottom: 12,
		},
		imageCompare: {
			borderRadius: 6,
			width: 200,
			height: 'auto',
			marginTop: 12,
		},
		cancelButton: {
			marginRight: 8,
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: '#DFE1E3',
			},
		},
		invisibleButton: {
			marginRight: 8,
			'& p': {
				color: dark50,
			},
			'&:hover': {
				backgroundColor: Color(dark75).alpha(0.1).toString(),
				boxShadow: 'none',
				'& p': {
					color: dark75,
				},
			},
			'&:active': {
				backgroundColor: Color(dark75).alpha(0.25).toString(),
				boxShadow: 'none',
				'& p': {
					color: dark75,
				},
			},
		},
		input: {
			'& input': {
				padding: 12,
			},
		},
		loading: {
			backgroundColor: Color(green).alpha(0.25).toString(),
			height: 130,
			width: 200,
			borderRadius: 6,
			'& svg': {
				animation: '$rotating 2s linear infinite',
			},
		},
		'@keyframes rotating': {
			'0%': {
				transform: 'rotate(0deg)',
			},
			'100%': {
				transform: 'rotate(360deg)',
			},
		},
	},
	{ index: 1 },
)
