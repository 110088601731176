import { AnyAction } from 'redux'
import { createUser, IUser } from 'lib/entities/User'
import { createClient, IClient } from 'lib/entities/Client'
import { createClaims, IClaims } from 'lib/entities/Claims'

export const SET_TOKEN = 'AUTH/SET_TOKEN'
export const SET_CLAIMS = 'AUTH/SET_CLAIMS'
export const LOG_OUT = 'AUTH/LOG_OUT'
export const SET_PROFILE = 'AUTH/SET_PROFILE'
export const SET_SELECTED_CLIENT = 'AUTH/SET_SELECTED_CLIENT'
export const SET_ACCOUNT_COUNTRY = 'AUTH/SET_ACCOUNT_COUNTRY'
export const SET_TERMS_ACCEPTED = 'AUTH/SET_TERMS_ACCEPTED'

type IAccountCountry = 'dk' | 'se' | 'no' | 'fi' | 'de' | 'uk' | ''

export interface IState {
	currentUser: IUser
	token: string
	claims: IClaims
	selectedClient: IClient
	country: IAccountCountry
	termsAccepted: boolean
}

const initialState: IState = {
	currentUser: createUser(),
	selectedClient: createClient(),
	claims: createClaims(),
	token: '',
	country: '',
	termsAccepted: false,
}

export default function auth(state: IState = initialState, action: AnyAction): IState {
	switch (action.type) {
		case SET_TOKEN:
			return {
				...state,
				token: action.token,
			}

		case SET_CLAIMS:
			return {
				...state,
				claims: action.claims,
			}

		case SET_ACCOUNT_COUNTRY:
			return {
				...state,
				country: action.country,
			}

		case SET_TERMS_ACCEPTED:
			return {
				...state,
				termsAccepted: action.termsAccepted,
			}

		case LOG_OUT:
			return initialState

		case SET_PROFILE:
			return {
				...state,
				currentUser: createUser(action.data),
			}

		case SET_SELECTED_CLIENT:
			return {
				...state,
				selectedClient: action.client,
			}

		default:
			return state
	}
}
