import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Typography, Row } from '@copyrightagent/basic-components'
import { ICase, getFormatOccurredDate, ICaseLocation } from 'lib/entities/Case'
import { dark50 } from 'lib/Colors'

import { useStyle } from './style'

export interface Props {
	theCase: ICase
	caseLocation: ICaseLocation
}

const CaseLocation = ({ theCase, caseLocation }: Props): ReactElement => {
	const { t } = useTranslation()
	const classes = useStyle()

	const getStatusSubTitle = () => {
		const textWithDate = (text: string) => (
			<Row>
				<Typography>{text}</Typography>
				<Typography className={classes.caseStatusDate}>
					({getFormatOccurredDate(theCase.last_modified, 'DD. MMMM YYYY')})
				</Typography>
			</Row>
		)

		if (theCase.client_review_required === false)
			return <Typography>{t('caseRow/labels/waitingForNextStep')}</Typography>
		if (theCase.status === 'won') return textWithDate(t('caseRow/labels/won'))
		if (theCase.status === 'closed')
			return textWithDate(t('caseRow/labels/closed'))
		if (theCase.status === 'open') {
			if (theCase.customer_approve_time)
				return textWithDate(t('caseRow/labels/reviewed'))
			return <Typography>{t('caseRow/labels/waitingForReview')}</Typography>
		}
		return <></>
	}

	const CreatedCaseColumn = () => (
		<Col>
			<Typography fontColor={dark50}>
				{theCase.timestamp &&
					t('createCase/uploaded', {
						date: getFormatOccurredDate(theCase.timestamp),
					})}
			</Typography>
			<Typography className={classes.accentText} variant="body1">
				{t('createCase/awaitingReview')}
			</Typography>
		</Col>
	)

	const InReview = () => (
		<Col>
			<Typography fontColor={dark50}>
				{t('caseRow/defaultStatusTitle')}
			</Typography>
			{getStatusSubTitle()}
		</Col>
	)

	if (theCase.status === 'won' || theCase.status === 'closed')
		return <InReview />
	switch (caseLocation.type) {
		case 'createdCase':
			return <CreatedCaseColumn />
		default:
			return <InReview />
	}
}

export default CaseLocation
