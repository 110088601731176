import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(
	{
		root: {
			margin: '0 5px',
		},
		open: {
			width: '20px',
			height: '20px',
			background: '#f8a01c',
			borderRadius: '100%',
			border: '4px solid rgba(248, 160, 27, 0.25)',
			boxShadow: '0px 0px 10px 0px rgba(248, 160, 28, 0.75)',
			backgroundClip: 'padding-box',
			animation: '$activePulse 2.5s ease-in-out infinite',
		},
		'@keyframes activePulse': {
			'0%': {
				boxShadow: '0px 0px 1px 0px rgba(248, 160, 28, 0.25)',
			},
			'50%': {
				boxShadow: '0px 0px 15px 0px rgba(248, 160, 28, 0.75)',
			},
			'100%': {
				boxShadow: '0px 0px 1px 0px rgba(248, 160, 28, 0.25)',
			},
		},
	},
	{ index: 1 },
)

export default useStyle
