import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import {
	FormGroup,
	Link,
	List,
	ListItem,
	useMediaQuery,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	Row,
	Col,
	Typography,
	PrimaryButton,
	PageContainer,
	RightPlaceholder,
} from '@copyrightagent/basic-components'
import { getAuth, signOut } from 'firebase/auth'

import { RouteUrls } from 'routes/RouteUrls'
import { getCurrentUser } from 'store/utils'
import { SystemImages } from 'assets'
import { LOG_OUT, SET_SELECTED_CLIENT } from 'store/auth'
import { createClient, IClient } from 'lib/entities/Client'
import { useRouter } from 'routes/CustomBrowserRouter'
import { dark50 } from 'lib/Colors'
import useStyle from './style'

export default function ClientSelect(): ReactElement {
	const [selectedClient, setSelectedClient] = useState<IClient>(createClient())
	const currentUser = getCurrentUser()
	const isMobile = useMediaQuery('(max-width: 1200px)')
	const classes = useStyle()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const router = useRouter()
	const auth = getAuth()

	const handleSelectClient = useCallback(() => {
		dispatch({ type: SET_SELECTED_CLIENT, client: selectedClient })
		router.history.replace('/dashboard')
	}, [dispatch, router.history, selectedClient])

	const handleBack = useCallback(() => {
		dispatch({ type: LOG_OUT })
		signOut(auth)
		router.history.push(RouteUrls.login)
	}, [dispatch, router.history])

	const renderCopyright = useMemo(
		() => (
			<Row className={classes.bottom}>
				<Typography fontColor={dark50} fontWeight={600}>
					{t('loginPage/copyright') + new Date().getFullYear()}
				</Typography>
				<Typography fontColor={dark50} fontWeight={600}>
					&nbsp;|&nbsp;
				</Typography>
				<Link href="https://copyrightagent.com/privacy/" target="_blank">
					<Typography fontColor={dark50} fontWeight={600}>
						{t('loginPage/privacy')}
					</Typography>
				</Link>
				<Typography fontColor={dark50} fontWeight={600}>
					&nbsp;|&nbsp;
				</Typography>
				<Link href="https://copyrightagent.com/contact-us/" target="_blank">
					<Typography fontColor={dark50} fontWeight={600}>
						{t('loginPage/support')}
					</Typography>
				</Link>
			</Row>
		),
		[t, classes],
	)

	const renderClients = useMemo(() => {
		if (currentUser.hubs.length === 0) {
			return (
				<Typography fontWeight="normal" fontColor={dark50} fontStyle="italic">
					{t('selectClient/noClients')}
				</Typography>
			)
		}

		return currentUser.hubs.map((hub: IClient) => (
			<ListItem
				key={hub.client_id}
				className={classes.listItem}
				onClick={() => setSelectedClient(hub)}
				disableTouchRipple
				button
				selected={selectedClient.client_id === hub.client_id}
			>
				<Typography fontWeight={600}>{hub.name}</Typography>
			</ListItem>
		))
	}, [t, classes, currentUser.hubs, selectedClient.client_id])

	return (
		<PageContainer className={classes.page}>
			<Col align="center" justify="center" className={classes.leftCol}>
				<FormGroup>
					<Typography
						variant="h4"
						fontWeight="bold"
						className={clsx(classes.selfStart, classes.formTitle)}
					>
						{t('loginPage/loginTitle')}
					</Typography>
					<Typography className={classes.formSubtitle} fontColor={dark50}>
						{t('selectClient/formSubtitle')}
					</Typography>
					<List className={classes.listWrap} disablePadding>
						{renderClients}
					</List>
					<Row className={classes.bottomRow} justify="space-between">
						<Link onClick={handleBack}>
							<Typography fontColor={dark50} fontWeight={600}>
								{t('signout')}
							</Typography>
						</Link>
						<PrimaryButton
							type="button"
							disabled={!selectedClient.client_id}
							text={t('continue')}
							onClick={handleSelectClient}
						/>
					</Row>
				</FormGroup>
				{renderCopyright}
			</Col>
			<RightPlaceholder
				src={SystemImages.publicPlaceholder}
				logoSrc={SystemImages.logoWithText}
				isMobile={isMobile}
			/>
		</PageContainer>
	)
}
