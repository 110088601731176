import { makeStyles } from '@material-ui/styles'

export default makeStyles(
	{
		absoluteCenter: {
			position: 'absolute',
			top: 'calc(50% - 20px)',
			left: 'calc(50% - 20px)',
		},
		rightPanel: {
			width: 320,
			marginTop: 72,
			padding: 24,
			display: 'flex',
			position: 'sticky',
			top: 72,
			maxHeight: 'calc(100vh - 72px)',
			flexDirection: 'column',
		},
		title: {
			fontWeight: 600,
			paddingTop: 0,
			marginRight: 11,
		},
		dynamicContentWrap: {
			'& > * + *': {
				marginLeft: 16,
				marginRight: 0,
			},
		},
	},
	{ index: 1 },
)

export const useActivityStyle = makeStyles(
	{
		activityItemsWrap: {
			width: '100%',
			flex: 1,
			display: 'block',
			overflowY: 'auto',
			'&::-webkit-scrollbar': {
				// safari, chrome
				display: 'none',
			},
			scrollbarWidth: 'none', // firefox
		},
		form: {
			height: 40,
			marginTop: 24,
		},
		input: {
			marginBottom: 0,
			marginRight: 10,
			minWidth: 'unset',
			maxHeight: 40,
			'& label': {
				top: 3,
			},
			'& .MuiInputBase-root': {
				'& .MuiInputBase-input': {
					maxHeight: 17,
				},
			},
		},
	},
	{ index: 1 },
)
